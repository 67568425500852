import { detailedDiff } from 'deep-object-diff';
import { StateCreator, StoreMutatorIdentifier } from 'zustand';

type Logger = <
  T,
  Mps extends [StoreMutatorIdentifier, unknown][] = [],
  Mcs extends [StoreMutatorIdentifier, unknown][] = [],
>(
  f: StateCreator<T, Mps, Mcs>,
  name?: string,
) => StateCreator<T, Mps, Mcs>;

type LoggerImpl = <T>(f: StateCreator<T, [], []>, name?: string) => StateCreator<T, [], []>;

const loggerImpl: LoggerImpl = (f, name) => (set, get, store) => {
  const loggedSet: typeof set = (...a) => {
    const previousState = get?.() ?? {};
    set(...a);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data = detailedDiff((previousState as any) ?? {}, (get() as any) ?? {});

    const filteredData = Object.entries(data).filter(([, value]) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (Object.values(value as any).length > 0) {
        return true;
      } else {
        return false;
      }
    });
    if (Object.values(filteredData).length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      console.log(name ? `${name}:` : '', Object.fromEntries(filteredData as any));
    }
  };
  store.setState = loggedSet;

  return f(loggedSet, get, store);
};

export const logger = loggerImpl as unknown as Logger;
