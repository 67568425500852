/**
 * A wrapper used to stop multiple events from firing when clicking on a child element
 * @param children
 * @param rest
 * @constructor
 */
export function SectionChildrenWrapper({
  children,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div onClick={event => event.stopPropagation()} {...rest}>
      {children}
    </div>
  );
}
