import { useCallback } from 'react';

import { FreelyModalProps } from '@components/freelyModal/modal.component';
import { i18n } from '@i18n/i18n';
import { useModalStore } from '@store/modal';

export const useModal = () => {
  const openModal = useModalStore(state => state.openModal);

  const openFreelyModal = useCallback(
    (props: FreelyModalProps) => openModal('FreelyModal', props),
    [openModal],
  );

  const openMaintenanceModal = useCallback(
    () =>
      openFreelyModal({
        body: {
          type: 'Maintenance',
        },
        size: 'md',
        showCloseButton: true,
        variant: 'nusa',
      }),
    [openFreelyModal],
  );

  const openTimeoutModal = useCallback(
    ({ onOk, onClose }: { onOk: () => void; onClose: () => void }) =>
      openFreelyModal({
        body: {
          type: 'Timeout',
        },
        size: 'md',
        showCloseButton: true,
        variant: 'nusa',
        onClose,
        actions: [
          {
            variant: 'white',
            children: i18n.t('global.modal.timeout.button'),
            className: 'text-fuji-800 w-2/3 sm:w-1/2',
            onClick: onOk,
          },
        ],
      }),
    [openFreelyModal],
  );

  return { openFreelyModal, openMaintenanceModal, openTimeoutModal };
};
