import { useEffect, useRef } from 'react';

import { useSyncQuoteStoreWithForm } from '@hooks/useSyncQuoteStoreWithForm.ts';
import { useSectionStore } from '@store/section';
import { SectionType } from '@types';

export const useClickOutsideHandler = (section: SectionType) => {
  const syncQuoteStore = useSyncQuoteStoreWithForm();
  const ref = useRef<HTMLDivElement>(null);
  const closeSection = useSectionStore(state => state.closeSection);
  const openSection = useSectionStore(state => state.openSection);

  const isModalVisible = useSectionStore(state => {
    if (section === 'destinations') {
      return state.destinations.isOpen;
    }

    if (section === 'travelDates') {
      return state.travelDates.isOpen;
    }

    if (section === 'travellers') {
      return state.travellers.isOpen;
    }

    return false;
  });

  const setIsModalVisible = (visible: boolean) => {
    if (visible) {
      openSection(section);
      return;
    }

    return closeSection(section);
  };

  useEffect(() => {
    //handle click outside the ref
    const handleOutsideClick = (event: MouseEvent) => {
      if (ref.current && !ref.current?.contains(event.target as Node)) {
        syncQuoteStore();
        onClose();
      }
    };
    //bind the event listener

    document.addEventListener('click', handleOutsideClick);

    return () => {
      //unbind the event listener on clean up
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const onClose = () => {
    closeSection(section);
  };

  return { isModalVisible, setIsModalVisible, ref, onClose };
};
