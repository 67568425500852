import { CreateStore, createStore, useRegionStore } from 'freely-shared-stores';
import { parseISO } from 'freely-shared-utils';

import { quickSelectExactDatesIndex } from '@constants/common.ts';
import { SectionType } from '@types';

export type Section = {
  isOpen: boolean;
  isValidatingOnOpen: boolean;
};

export type SectionState = Record<SectionType, Section> & {
  isCalendarInputFocused: boolean;
  calendarActiveStartTimeStamp?: number;
  selectedQuickSelectIndex: number;
};

export interface SectionActions {
  openSection: (section: SectionType, isValidating?: boolean) => void;
  closeSection: (section: SectionType) => void;
  closeAllSections: () => void;
  setSectionIsValidating: (section: SectionType, isValidating: boolean) => void;
  setIsCalendarInputFocused: (isCalendarInputFocused: boolean) => void;
  setCalendarActiveStartTimeStamp: (calendarActiveStartTimeStamp: number | Date | string) => void;
  setSelectedQuickSelectIndex: (selectedQuickSelectIndex: number) => void;
}

export type SectionStore = SectionState & SectionActions;

const initialState: SectionState = {
  destinations: {
    isOpen: false,
    isValidatingOnOpen: false,
  },
  travelDates: { isOpen: false, isValidatingOnOpen: false },
  travellers: {
    isOpen: false,
    isValidatingOnOpen: false,
  },
  isCalendarInputFocused: false,
  calendarActiveStartTimeStamp: undefined,
  selectedQuickSelectIndex: quickSelectExactDatesIndex,
};

export const sectionKeys: SectionType[] = ['destinations', 'travelDates', 'travellers'];

export const sectionStore: CreateStore<SectionStore> = set => ({
  ...initialState,
  openSection(section, isValidatingAllOnOpen) {
    set(state => {
      if (section === 'destinations') {
        state.destinations.isOpen = true;
        state.travelDates.isOpen = false;
        state.travellers.isOpen = false;
      }

      if (section === 'travelDates') {
        state.travelDates.isOpen = true;
        state.destinations.isOpen = false;
        state.travellers.isOpen = false;
      }

      if (section === 'travellers') {
        state.travellers.isOpen = true;
        state.destinations.isOpen = false;
        state.travelDates.isOpen = false;
      }

      if (isValidatingAllOnOpen) {
        state.destinations.isValidatingOnOpen = true;
        state.travelDates.isValidatingOnOpen = true;
        state.travellers.isValidatingOnOpen = true;
      }
    });
  },
  closeSection(section) {
    set(state => {
      if (section === 'destinations') {
        state.destinations.isOpen = false;
      }

      if (section === 'travelDates') {
        state.travelDates.isOpen = false;
      }

      if (section === 'travellers') {
        state.travellers.isOpen = false;
      }
    });
  },
  closeAllSections() {
    set(state => {
      state.destinations.isOpen = false;

      state.travelDates.isOpen = false;

      state.travellers.isOpen = false;
    });
  },
  setSectionIsValidating(section, isValidatingOnOpen) {
    set(state => {
      state[section].isValidatingOnOpen = isValidatingOnOpen;
    });
  },
  setIsCalendarInputFocused(isCalendarInputFocused) {
    set(state => {
      state.isCalendarInputFocused = isCalendarInputFocused;
    });
  },
  setCalendarActiveStartTimeStamp(calendarActiveStartTimeStamp) {
    set(state => {
      if (calendarActiveStartTimeStamp instanceof Date) {
        state.calendarActiveStartTimeStamp = calendarActiveStartTimeStamp.getTime();
      }

      if (typeof calendarActiveStartTimeStamp === 'number') {
        state.calendarActiveStartTimeStamp = calendarActiveStartTimeStamp;
      }

      if (typeof calendarActiveStartTimeStamp === 'string') {
        const region = useRegionStore.getState().region;
        state.calendarActiveStartTimeStamp = parseISO(
          calendarActiveStartTimeStamp,
          region?.country,
        ).toMillis();
      }
    });
  },
  setSelectedQuickSelectIndex(selectedQuickSelectIndex) {
    set(state => {
      state.selectedQuickSelectIndex = selectedQuickSelectIndex;
    });
  },
});

export const useSectionStore = createStore(sectionStore, {
  name: 'sectionStore',
});
