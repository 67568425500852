import { ParticipantPayload } from 'freely-shared-types';

declare global {
  interface Window {
    growsurf?: {
      init: () => Promise<void>;
      getReferrerId: () => string;
      getParticipantById: (id: string) => Promise<ParticipantPayload>;
      getCampaign: () => Promise<{ id: string }>;
    };
  }
}

export const growsurfApi = {
  getReferrerId() {
    const growSurf = window?.growsurf;
    if (growSurf) {
      const referralCode = growSurf.getReferrerId();
      return referralCode?.includes('-') ? referralCode.slice(-6) : referralCode;
    }
  },
  async getReferralData() {
    const growSurf = window?.growsurf;
    if (growSurf) {
      const referralId = this.getReferrerId();
      let referralPayload: ParticipantPayload | null;
      if (referralId) {
        referralPayload = await growSurf.getParticipantById(referralId);
        return referralPayload;
      }
    }
  },
  async getCampaignId() {
    const growSurf = window?.growsurf;
    if (growSurf) {
      const { id } = await growSurf.getCampaign();
      return id;
    }
  },
};
