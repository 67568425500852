import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

import { useDestinationsStore } from 'freely-shared-stores';
import { DestinationsForm, TripDestination } from 'freely-shared-types';
import { getDestinationsByCategory } from 'freely-shared-utils';

import { Notification } from '@components/notification';
import { SectionChildrenWrapper } from '@components/sectionChildrenWrapper';
import { Swiper } from '@components/swiper';
import { Text } from '@components/text';
import { i18n } from '@i18n/i18n';
import { useSectionStore } from '@store/section';
import { QuickSelectOption } from '@types';

export interface DestinationsListProps {
  onClick: (destination: TripDestination) => void;
}

const allRegionSlides = Array(26).fill({});
const trendingSlides = Array(16).fill({});
const cruiseSlides = Array(3).fill({});

export const DestinationsList = ({ onClick }: DestinationsListProps) => {
  const {
    formState: { errors },
    watch,
  } = useFormContext<DestinationsForm>();
  const destinations = useDestinationsStore(state => state?.destinations ?? []);
  const isDestinationLoading = useDestinationsStore(state => state.pending);
  const trendingDestinations = useDestinationsStore(state => state.trendingDestinations ?? []);
  const { allRegionDestinations, allCruiseDestinations } = useMemo(
    () => getDestinationsByCategory(destinations),
    [isDestinationLoading],
  );
  const isValidatingFromGetQuote = useSectionStore(state => state?.destinations.isValidatingOnOpen);
  const isDestinationsEmpty = !watch('destinations')?.length;

  const { t } = useTranslation();
  const sectionTitles = t('where.section.titles', { returnObjects: true });

  const shouldDisplayEmptyError =
    errors?.destinations?.type === 'required' && isDestinationsEmpty && isValidatingFromGetQuote;

  return (
    <SectionChildrenWrapper className="lg:border lg:border-rain lg:shadow-lg rounded-2xl bg-white w-full lg:w-[63%] py-4 lg:px-4 lg:py-4 overflow-hidden">
      <div className={twMerge('border-t lg:border-t-0 overflow-hidden pt-4 lg:pt-0')}>
        {shouldDisplayEmptyError && (
          <Notification
            variant="alert"
            headingProps={{ children: i18n.t('where.errors.empty.title') }}
            descriptionProps={{
              children: i18n.t('where.errors.empty.description'),
            }}
            className="mb-5"
          />
        )}
        <div className={twMerge('mb-6')}>
          <Text variant="sub-heading-14/medium" className={twMerge(['mb-3'])}>
            {sectionTitles[0]}
          </Text>
          <Swiper
            swiperConfig={{
              slidesPerView: 'auto',
              slidesPerGroup: 1,
              slidesPerGroupAuto: true,
            }}
            isLoading={isDestinationLoading}
            buttonProps={{ fontVariant: 'sub-heading-14/medium' }}
            slides={isDestinationLoading ? allRegionSlides : allRegionDestinations}
            onClick={onClick as (data: TripDestination | QuickSelectOption) => void}
          />
        </div>
        <div className={twMerge('mb-6')}>
          <Text variant="sub-heading-14/medium" className={twMerge(['mb-3'])}>
            {sectionTitles[1]}
          </Text>
          <Swiper
            swiperConfig={{
              slidesPerView: 'auto',
              slidesPerGroup: 1,
              slidesPerGroupAuto: true,
            }}
            isLoading={isDestinationLoading}
            buttonProps={{ fontVariant: 'sub-heading-14/medium' }}
            slides={isDestinationLoading ? trendingSlides : trendingDestinations}
            onClick={onClick as (data: TripDestination | QuickSelectOption) => void}
          />
        </div>
        <div>
          <Text variant="sub-heading-14/medium" className={twMerge(['mb-3'])}>
            {sectionTitles[2]}
          </Text>
          <Swiper
            swiperConfig={{ slidesPerView: 'auto' }}
            buttonProps={{ fontVariant: 'sub-heading-14/medium' }}
            slides={isDestinationLoading ? cruiseSlides : allCruiseDestinations}
            isLoading={isDestinationLoading}
            onClick={onClick as (data: TripDestination | QuickSelectOption) => void}
          />
        </div>
      </div>
    </SectionChildrenWrapper>
  );
};
