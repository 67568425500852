import React, { useCallback, useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';

import { ButtonProps } from '@components/button';
import { ModalBody, ModalBodyProps, ModalComponentName } from '@components/modalBody';
import { useSyncQuoteStoreWithForm } from '@hooks/useSyncQuoteStoreWithForm.ts';
import { Modal } from '@restart/ui';
import { ModalHandle } from '@restart/ui/Modal';
import { useGlobalStore } from '@store/global';
import { useModalStore } from '@store/modal';
import { useSectionStore } from '@store/section';
import { getRootAttribute } from '@utils/rootAttributes';

import { ModalBackdrop } from './modalBackdrop/modalBackdrop.component';

export interface ModalProps {
  title?: string;
  isOpen?: boolean;
  body?: string | ModalBodyProps<ModalComponentName>;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  titleClassName?: string;
  onClose?: () => void;
  actions?: ButtonProps[];
  children?: React.ReactNode;
}

export const CustomModal: React.FC<ModalProps> = ({ isOpen, body }) => {
  const closeModal = useModalStore(state => state.closeModal);
  const syncQuoteStore = useSyncQuoteStoreWithForm();
  const closeAllSections = useSectionStore(state => state.closeAllSections);
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const setShouldDisplayHeaderShadow = useGlobalStore(state => state.setShouldDisplayHeaderShadow);

  const renderModalBody = useCallback(() => {
    if (React.isValidElement(body)) {
      return <>{body}</>;
    }

    if (typeof body === 'string') {
      return <div>{body}</div>;
    }

    if (typeof body === 'object') {
      return <ModalBody {...(body as ModalBodyProps<ModalComponentName>)} />;
    }
    return <>{null}</>;
  }, [body]);

  const closeWidgetModal = () => {
    syncQuoteStore();
    closeAllSections();
    closeModal();
  };

  const scroller = useRef<ModalHandle>(null);

  useEffect(() => {
    if (isDesktop) {
      setShouldDisplayHeaderShadow(false);
      return;
    }
    function updateScrollPosition(e: Event) {
      setShouldDisplayHeaderShadow(((e?.currentTarget as HTMLElement)?.scrollTop ?? 0) > 10);
    }

    if (scroller && scroller?.current) {
      scroller?.current.dialog?.addEventListener('scroll', updateScrollPosition, true);
    }

    return () => {
      if (scroller?.current?.dialog) {
        scroller?.current?.dialog.removeEventListener('scroll', updateScrollPosition, true);
      }
    };
  }, [isDesktop, setShouldDisplayHeaderShadow]);

  return (
    <Modal
      ref={scroller}
      style={{ top: isDesktop ? getRootAttribute('modal-top-offset') ?? '0' : '0' }}
      container={document.getElementById('freely-widget')}
      className={`z-[5001] top-0 w-full transform lg:left-0 bg-white shadow-lg md:w-3/5 lg:w-full fixed right-0 h-screen lg:h-auto overflow-y-auto lg:overflow-y-visible outline-none sm:mb-24`}
      show={isOpen}
      onBackdropClick={closeWidgetModal}
      onHide={closeWidgetModal}
      renderBackdrop={ModalBackdrop}
      aria-labelledby="modal-label">
      {renderModalBody()}
    </Modal>
  );
};
