import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { GetQuoteForm } from 'freely-shared-types';

import { useQuoteStore } from '@store/quote';

export function useSyncQuoteStoreWithForm() {
  const syncQuoteStoreWithForm = useQuoteStore(state => state.syncQuoteWithForm);
  const { getValues } = useFormContext<GetQuoteForm>();

  return useCallback(() => {
    const formValues = getValues();
    syncQuoteStoreWithForm(formValues);
  }, [getValues, syncQuoteStoreWithForm]);
}
