import { DateTime } from 'luxon';
import { FC, HTMLAttributes, forwardRef, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

import { colors } from 'freely-shared-design';
import { useDestinationsStore } from 'freely-shared-stores';
import { GetQuoteForm } from 'freely-shared-types';
import { createNewGetQuoteForm, wait } from 'freely-shared-utils';

import { Svg } from '@assets/svg';
import { Button } from '@components/button';
import { Text } from '@components/text';
import { useGetAQuote } from '@hooks/useGetAQuote.tsx';
import { useGetMinAvailableCalendarDate } from '@hooks/useGetMinAvailableCalendarDate.ts';
import { useHasDatesExpired } from '@hooks/useHasDatesExpired.ts';
import { useModal } from '@hooks/useModal/useModal.ts';
import { useSyncQuoteStoreWithForm } from '@hooks/useSyncQuoteStoreWithForm.ts';
import { useModalStore } from '@store/modal';
import { useQuoteStore } from '@store/quote';
import { useSectionStore } from '@store/section';
import { SectionType } from '@types';
import { testProps } from '@utils/testProps';
import { getNumberOfValidTravellers } from '@utils/travellers.ts';

type PlaceholderListItemProps = {
  text: string;
  Icon: FC<React.SVGProps<SVGElement>>;
} & HTMLAttributes<HTMLDivElement>;
const PlaceholderListItem = ({ Icon, text, className, ...rest }: PlaceholderListItemProps) => {
  return (
    <div
      {...rest}
      className={twMerge(
        'flex flex-1 justify-center items-end gap-1 sm:gap-2 py-1 px-1 cursor-pointer',
        className,
      )}>
      <Icon width="1.5rem" className="hidden sm:block" height="1.5rem" fill={colors.cabo[700]} />
      <Icon width="1.25rem" className="sm:hidden" height="1.5rem" fill={colors.cabo[700]} />
      <Text
        variant={{ sm: 'body-18/regular', md: 'sub-title-20/regular', lg: 'sub-title-20/regular' }}
        className="max-w-[5rem] md:max-w-[6rem] lg:max-w-[10rem]  text-ellipsis overflow-hidden whitespace-nowrap text-cabo-700 placeholder-text">
        {text}
      </Text>
    </div>
  );
};

export type PlaceholderProps = HTMLAttributes<HTMLDivElement> & {
  isInitialised?: boolean;
  isUnderMaintenance?: boolean;
};
export const Placeholder = forwardRef<HTMLDivElement, PlaceholderProps>(
  ({ isInitialised, className, isUnderMaintenance = false, ...rest }, ref) => {
    const { openMaintenanceModal } = useModal();
    const getQuoteDestinations = useDestinationsStore(state => state.getQuoteDestinations);
    const destinations = useDestinationsStore(state => state.destinations);
    const { checkHasDateExpired } = useHasDatesExpired();
    const formValue = useQuoteStore(state => state.quote);
    const firstCountrySelection = formValue?.destinations[0]?.countryName;
    const formValueStartDate = formValue?.startDate;
    const formValueEndDate = formValue?.endDate;
    const { reset } = useFormContext<GetQuoteForm>();
    const setCalendarActiveStartTimeStamp = useSectionStore(
      state => state.setCalendarActiveStartTimeStamp,
    );
    const { t } = useTranslation();

    const defaultDestinationText = t('where.lg.title');
    const defaultDateText = t('when.lg.title');
    const defaultTravellerText = t('who.lg.title');
    const setIsCalendarInputFocused = useSectionStore(state => state.setIsCalendarInputFocused);
    const minAvailableCalendarDate = useGetMinAvailableCalendarDate();
    const checkToFormatDates = () => {
      if (!formValueStartDate || !formValueEndDate) {
        return defaultDateText;
      }
      const startDate = DateTime.fromISO(formValueStartDate);
      const formattedStartDate = startDate.toFormat('dd MMM');

      const endDate = DateTime.fromISO(formValueEndDate);
      const formattedEndDate = endDate.toFormat('dd MMM');

      return `${formattedStartDate} - ${formattedEndDate}`;
    };

    const checkTraveller = () => {
      if (!formValue?.primaryTraveller?.dob && formValue?.secondaryTravellers.length === 0) {
        return defaultTravellerText;
      }
      const travellersNo = getNumberOfValidTravellers(
        formValue?.primaryTraveller?.dob ?? undefined,
        formValue?.secondaryTravellers,
      );
      if (travellersNo) {
        return t('who.additionTravellers.subTitle', { count: travellersNo });
      }
      return defaultTravellerText;
    };

    const checkDestinations = () => {
      if (!firstCountrySelection) {
        return defaultDestinationText;
      }
      return firstCountrySelection;
    };

    useEffect(() => {
      if (isInitialised && !destinations) {
        getQuoteDestinations();
      }
    }, [destinations, getQuoteDestinations, isInitialised]);
    const openModal = useModalStore(state => state.openModal);
    const openSection = useSectionStore(state => state.openSection);
    const syncQuoteStore = useSyncQuoteStoreWithForm();
    const clearQuote = useQuoteStore(state => state.clearQuote);
    const quoteDestinations = useQuoteStore(state => state?.quote?.destinations);
    const quoteStartDate = useQuoteStore(state => state?.quote?.startDate);
    const quoteEndDate = useQuoteStore(state => state?.quote?.endDate);
    const quotePrimaryTravellerDOB = useQuoteStore(state => state?.quote?.primaryTraveller?.dob);
    const quoteIsPrimaryTravellerResident = useQuoteStore(
      state => !!state?.quote?.primaryTraveller?.isResident,
    );
    const { onSubmit: submitQuote } = useGetAQuote();
    const {
      formState: { errors },
    } = useFormContext<GetQuoteForm>();

    useEffect(() => {
      syncQuoteStore();
    }, [syncQuoteStore]);

    const openQuoteModal = () => {
      if (isUnderMaintenance) {
        openMaintenanceModal();
        return;
      }

      openModal('Modal', {
        body: {
          type: 'GetQuoteContainer',
        },
      });
    };

    const onSubmit = async () => {
      if (isUnderMaintenance) {
        openMaintenanceModal();
        return;
      }

      const hasDestinationsError = !!errors?.destinations || !quoteDestinations?.length;
      const hasTripDatesError = !!errors?.tripDates || !quoteStartDate || !quoteEndDate;
      const hasTravellersError =
        !!errors?.primaryTravellerDOB ||
        !!errors?.primaryTravellerIsResident ||
        !!errors?.secondaryTravellers ||
        !quotePrimaryTravellerDOB ||
        !quoteIsPrimaryTravellerResident;
      const hasStartDateExpired = !!formValueStartDate && checkHasDateExpired(formValueStartDate);
      const endDateExpired = !!formValueEndDate && checkHasDateExpired(formValueEndDate);

      // Dates expired, clear quote state and local storage
      if (hasStartDateExpired || endDateExpired) {
        setIsCalendarInputFocused(true);
        clearQuote();
        reset(createNewGetQuoteForm());
        (useQuoteStore as any)?.persist?.clearStorage?.();
        openSection('travelDates', true);
        setCalendarActiveStartTimeStamp(minAvailableCalendarDate.toJSDate());
        openQuoteModal();
        await wait(0);
        setIsCalendarInputFocused(false);
        return;
      }

      if (hasDestinationsError) {
        openSection('destinations', true);
        openQuoteModal();
        return;
      }

      if (hasTripDatesError) {
        openSection('travelDates', true);
        openQuoteModal();
        return;
      }

      if (hasTravellersError) {
        openSection('travellers', true);
        openQuoteModal();
        return;
      }

      await submitQuote();
    };

    const onPlaceholderClick = (sectionType: SectionType) => {
      if (isUnderMaintenance) {
        openMaintenanceModal();
        return;
      }

      openSection(sectionType);
      openQuoteModal();
    };

    return (
      <div
        ref={ref}
        {...rest}
        className={twMerge(
          `widget-container relative bg-white w-full justify-between items-center text-fuji-800 flex shadow-xl border border-rain rounded-full py-4 divide-x-[1px] md:px-4 mb-2 sm:mb-1 `,
          className,
          'max-w-[57rem]',
          'lg:min-w-[48rem]',
        )}>
        <PlaceholderListItem
          {...testProps('destinations')}
          Icon={Svg.LocationPinOutline}
          text={checkDestinations()}
          onClick={event => {
            event.stopPropagation();
            onPlaceholderClick('destinations');
          }}
        />
        <PlaceholderListItem
          {...testProps('travelDates')}
          Icon={Svg.CalendarOutline}
          text={checkToFormatDates()}
          onClick={event => {
            event.stopPropagation();
            onPlaceholderClick('travelDates');
          }}
        />
        <PlaceholderListItem
          {...testProps('travellers')}
          Icon={Svg.ProfileOutline}
          text={checkTraveller()}
          onClick={event => {
            event.stopPropagation();
            onPlaceholderClick('travellers');
          }}
        />
        <div className="md:relative absolute top-20 mx-auto inset-x-0 md:top-0 flex justify-center items-center border-none md:w-auto ">
          <Button
            {...testProps('Get a quote')}
            fontVariant="body-18/regular"
            variant="fuji"
            textAlign="center"
            size="sm"
            IconRight={Svg.ChevronRight}
            IconRightProps={{
              className: 'max-w-[2rem]',
              style: { marginRight: -12, marginTop: 2, marginLeft: 4 },
            }}
            onClick={onSubmit}
            className="p-6">
            {t('global.container.actions.getQuote')}
          </Button>
        </div>
      </div>
    );
  },
);

Placeholder.displayName = 'Placeholder';
