import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { typography } from '@design/typography';
import { FontStyleVariants, FreelyTypographyBody, FreelyTypographyHeading } from '@types';

import { CustomMarkdown } from './markdown.comoonent';

type Size = 'lg' | 'md' | 'sm';
type StringWeight = keyof FontStyleVariants;
type NumberWeight = '400' | '500' | '600';
type Weight = StringWeight | NumberWeight;

export type FontVariant =
  | `${keyof FreelyTypographyBody}/${StringWeight | NumberWeight}`
  | keyof FreelyTypographyHeading;

export interface ResponsiveFontVariant {
  sm: FontVariant;
  md?: FontVariant;
  lg: FontVariant;
}

export interface TextProps extends React.HTMLAttributes<HTMLElement> {
  variant?: FontVariant | ResponsiveFontVariant;
  withMarkDown?: boolean;
  onClick?: (() => Promise<void>) | (() => void);
}

const getElementName = (variant?: FontVariant) => {
  if (variant?.startsWith('h0')) {
    return 'h1';
  } else if (variant?.startsWith('h7')) {
    return 'h6';
  } else if (variant?.startsWith('h')) {
    return variant.substring(0, 2);
  } else {
    return 'p';
  }
};

const getWeightName: (weight?: Weight) => StringWeight = weight => {
  if (!weight) {
    return 'semiBold';
  }
  switch (weight) {
    case '400':
      return 'regular';
    case '500':
      return 'medium';
    case '600':
      return 'semiBold';
    default:
      return weight;
  }
};

export const Text: FC<TextProps> = ({
  variant = 'body-16/regular',
  children,
  style,
  withMarkDown,
  className,
  ...rest
}) => {
  if (typeof variant !== 'object') {
    const fontType = variant.split('/')[0] as keyof FreelyTypographyBody;
    const weight = getWeightName(variant.split('/')[1] as Weight);

    const content = withMarkDown ? <CustomMarkdown>{children as string}</CustomMarkdown> : children;
    const styleObj = {
      ...(typography?.[fontType]?.[weight] ?? {}),
      ...style,
    };

    return React.createElement(
      getElementName(variant),
      {
        className: className,
        style: styleObj,
        ...rest,
      },
      content as never,
    );
  } else {
    const responsiveClasses = {
      sm: twMerge('md:hidden', className),
      md: twMerge('hidden md:block lg:hidden', className),
      lg: twMerge('hidden lg:block', className),
    };
    const fontType = {
      sm: variant.sm.split('/')[0] as keyof FreelyTypographyBody,
      md: (variant.md ?? variant.lg).split('/')[0] as keyof FreelyTypographyBody,
      lg: variant.lg.split('/')[0] as keyof FreelyTypographyBody,
    };

    const weight = {
      sm: getWeightName(variant.sm.split('/')[1] as Weight),
      md: getWeightName((variant.md ?? variant.lg).split('/')[1] as Weight),
      lg: getWeightName(variant.lg.split('/')[1] as Weight),
    };

    const content = withMarkDown ? <CustomMarkdown>{children as string}</CustomMarkdown> : children;

    const sizes: Size[] = ['sm', 'md', 'lg'];
    const Elements = sizes.map((size: Size) => {
      return React.createElement(
        getElementName(variant[size]),
        {
          key: size,
          className: responsiveClasses[size],
          style: {
            ...(typography?.[fontType?.[size]]?.[weight?.[size]] ?? {}),
            ...style,
          },
          ...rest,
        },
        content as never,
      );
    });

    return <>{Elements}</>;
  }
};
