import { CLAIM_SUB_CATEGORY, CLAIM_TYPE, Claim } from 'freely-shared-types';

const claim: Claim = {
  title: 'example title',
  tripId: '123432',
  tripTitle: 'example trip title',
  tripDuration: '2021-01-01T00:00:00.000Z/2021-01-02T00:00:00.000Z',
  tripExcess: 250,
  userId: null,
  claimId: null,
  currentScreen: null,
  claimTypes: ['example type'],
  status: 'DRAFT',
  submitDate: '2021-01-01T00:00:00.000Z',
  description: 'sample description',
  incidentDate: '2021-01-01T00:00:00.000Z',
  incidentPlace: { country: 'Australia', city: 'Sydney' },
  amount: 321,
  currency: 'AUD',
  bankAccountDetail: {
    accountName: 'example account name',
    accountNumber: '123456789',
    bsb: '123456',
  },
  contactDetail: {
    phone: '123456789',
    countryCallingCode: '+61',
  },
  evidences: null,
  noEvidenceDescription: null,
  isAmbulanceRequired: null,
  isClaimLessThan1000: null,
  isDelayed: null,
  isHospitalAdmission: null,
  isMoney: null,
  isMultiVehicleCollision: null,
  isPhone: null,
  isProviderResponsible: null,
  isLostOrStolen: null,
  subCategory: null,
};

// Different mock scenarios
// 1.1 LUGGAGE_DELAYED_TRANSIT
const luggageDelayedTransitData: Claim = {
  ...claim,
  claimTypes: [CLAIM_TYPE.MY_STUFF],
  description: {
    data: 'sample description',
    title: 'sample description title',
    subTitle: 'GotDelayed',
  },
  isClaimLessThan1000: true,
  isDelayed: true,
};

// 1.2 ITEM_DAMAGED_NOT_BY_TRANSPORT_PROVIDER
const itemDamagedNotByTransportProviderData: Claim = {
  ...claim,
  claimTypes: [CLAIM_TYPE.MY_STUFF],
  description: {
    data: 'sample description',
    title: 'sample description title',
    subTitle: 'Damaged',
  },
  isClaimLessThan1000: true,
  isDelayed: false,
  isProviderResponsible: false,
  isLostOrStolen: { id: 3, value: false },
};

// 1.2A ITEM_DAMAGED_BY_TRANSPORT_PROVIDER
const itemDamagedByTransportProviderData: Claim = {
  ...claim,
  claimTypes: [CLAIM_TYPE.MY_STUFF],
  description: {
    data: 'sample description',
    title: 'sample description title',
    subTitle: 'Damaged',
  },
  isClaimLessThan1000: true,
  isDelayed: false,
  isProviderResponsible: true,
  isLostOrStolen: { id: 3, value: false },
};

// 1.3: LOST_OR_STOLEN_PHONE_NOT_BY_TRANSPORT_PROVIDER
const lostOrStolenPhoneNotByTransportProvider: Claim = {
  ...claim,
  claimTypes: [CLAIM_TYPE.MY_STUFF],
  description: {
    title: 'Iphone 14 Pro Max',
    data: 'Got lost in a Harry Styles concert',
    subTitle: 'Phone',
  },
  isDelayed: false,
  isMoney: false,
  isPhone: true,
  isProviderResponsible: false,
  // isLostOrStolen id 2 will show the same scenario
  isLostOrStolen: { id: 1, value: true },
};

// 1.3A: LOST_OR_STOLEN_PHONE_BY_TRANSPORT_PROVIDER
const lostOrStolenPhoneByTransportProvider: Claim = {
  ...claim,
  claimTypes: [CLAIM_TYPE.MY_STUFF],
  description: {
    title: 'Iphone 14 Pro Max',
    data: 'Got lost in a Harry Styles concert when I was driven by Harry',
    subTitle: 'Phone',
  },
  isDelayed: false,
  isMoney: false,
  isPhone: true,
  isProviderResponsible: true,
  // isLostOrStolen id 2 will show the same scenario
  isLostOrStolen: { id: 1, value: true },
};

// 1.4 LOST_OR_STOLEN_MONEY_NOT_BY_TRANSPORT_PROVIDER
const lostOrStolenMoneyNotByTransportProvider: Claim = {
  ...claim,
  claimTypes: [CLAIM_TYPE.MY_STUFF],
  description: {
    title: '200',
    data: 'My bank got robbed by Kim K',
    subTitle: 'Money',
  },
  isDelayed: false,
  isMoney: true,
  isPhone: null,
  isProviderResponsible: false,
  // isLostOrStolen id 2 will show the same scenario
  isLostOrStolen: { id: 1, value: true },
  subCategory: null,
};

// 1.4A LOST_OR_STOLEN_MONEY_BY_TRANSPORT_PROVIDER
const lostOrStolenMoneyByTransportProvider: Claim = {
  ...claim,
  claimTypes: [CLAIM_TYPE.MY_STUFF],
  description: {
    title: '200',
    data: 'My bank got robbed by Kim K',
    subTitle: 'Money',
  },
  isDelayed: false,
  isMoney: true,
  isProviderResponsible: true,
  // isLostOrStolen id 2 will show the same scenario
  isLostOrStolen: { id: 1, value: true },
};

// 1.5 LOST_OR_STOLEN_ITEM_NOT_BY_TRANSPORT_PROVIDER
const lostOrStolenItemNotByTransportProvider: Claim = {
  ...claim,
  claimTypes: [CLAIM_TYPE.MY_STUFF],
  description: {
    title: '200',
    data: 'I lost my job because of chat gpt',
    subTitle: 'Lost or stolen',
  },
  isDelayed: false,
  isMoney: false,
  isPhone: false,
  isProviderResponsible: false,
  // isLostOrStolen id 2 will show the same scenario
  isLostOrStolen: { id: 1, value: true },
};

// 1.5A LOST_OR_STOLEN_ITEM_BY_TRANSPORT_PROVIDER
const lostOrStolenItemByTransportProvider: Claim = {
  ...claim,
  claimTypes: [CLAIM_TYPE.MY_STUFF],
  description: {
    title: '200',
    data: 'I lost my job because of chat gpt',
    subTitle: 'Lost or stolen',
  },
  isDelayed: false,
  isMoney: false,
  isPhone: false,
  isProviderResponsible: true,
  // isLostOrStolen id 2 will show the same scenario
  isLostOrStolen: { id: 1, value: true },
};

// 2.1 REQUIRE_HOSPITAL_ADMISSION_NO_AMBULANCE_NO_EMC
const requireHospitalAdmissionNoAmbulanceNoEmc: Claim = {
  ...claim,
  claimTypes: [CLAIM_TYPE.MY_HEALTH],
  bankAccountDetail: {
    accountName: 'example account name',
    accountNumber: '123456789',
    bsb: '123456',
  },
  isAmbulanceRequired: false,
  isHospitalAdmission: true,
  subCategory: { value: CLAIM_SUB_CATEGORY.COVID_19_OR_INJURY_FOOD_POISONING },
};

// 2.2 REQUIRE_HOSPITAL_ADMISSION_AND_AMBULANCE_NO_EMC
const requireHospitalAdmissionAndAmbulanceNoEmc: Claim = {
  ...claim,
  claimTypes: [CLAIM_TYPE.MY_HEALTH],
  isAmbulanceRequired: true,
  isHospitalAdmission: true,
  subCategory: { value: CLAIM_SUB_CATEGORY.COVID_19_OR_INJURY_FOOD_POISONING },
};

// 2.4 NO_HOSPITAL_ADMISSION_NO_AMBULANCE_NO_EMC
const noHospitalAdmissionNoAmbulanceNoEmc: Claim = {
  ...claim,
  claimTypes: [CLAIM_TYPE.MY_HEALTH],
  isAmbulanceRequired: false,
  isHospitalAdmission: false,
  subCategory: { value: CLAIM_SUB_CATEGORY.COVID_19_OR_INJURY_FOOD_POISONING },
};

// 2.5 NO_HOSPITAL_ADMISSION_REQUIRE_AMBULANCE_NO_EMC
const noHospitalAdmissionRequireAmbulanceNoEmc: Claim = {
  ...claim,
  claimTypes: [CLAIM_TYPE.MY_HEALTH],
  description: 'I need to go to hospital to do a check up',
  isAmbulanceRequired: true,
  isHospitalAdmission: false,
  // id 1,2 and 3 provide the same results
  subCategory: { id: 1, value: CLAIM_SUB_CATEGORY.COVID_19_OR_INJURY_FOOD_POISONING },
};

// 2.6 MY_HEALTH_OTHER
const myHealthOther: Claim = {
  ...claim,
  claimTypes: [CLAIM_TYPE.MY_HEALTH],
  subCategory: { id: 4, value: CLAIM_SUB_CATEGORY.OTHER },
};

// 3.1 RENTAL_CAR_ACCIDENT_NO_VEHICLES_INVOLVED
const rentalCarAccidentNoVehiclesInvolved = {
  ...claim,
  claimTypes: [CLAIM_TYPE.SOMETHING_ELSE],
  isMultiVehicleCollision: false,
  subCategory: { value: CLAIM_SUB_CATEGORY.IN_CAR_ACCIDENT },
};

// 3.2 RENTAL_CAR_ACCIDENT_VEHICLES_INVOLVED
const rentalCarAccidentVehiclesInvolved = {
  ...claim,
  claimTypes: [CLAIM_TYPE.SOMETHING_ELSE],
  isMultiVehicleCollision: true,
  subCategory: { value: CLAIM_SUB_CATEGORY.IN_CAR_ACCIDENT },
};

// 3.3  SOMETHING_ELSE_OTHER
const somethingElseOther = {
  ...claim,
  claimTypes: [CLAIM_TYPE.SOMETHING_ELSE],
  subCategory: { value: CLAIM_SUB_CATEGORY.SOMETHING_ELSE },
};

// MY TRIP
// UNHAPPY PATH
const myTripOther: Claim = {
  ...claim,
  claimTypes: [CLAIM_TYPE.MY_TRIP],
  description: 'sample description',
};

export const claimData = {
  claim,
  luggageDelayedTransitData,
  itemDamagedNotByTransportProviderData,
  itemDamagedByTransportProviderData,
  lostOrStolenPhoneNotByTransportProvider,
  lostOrStolenPhoneByTransportProvider,
  lostOrStolenMoneyNotByTransportProvider,
  lostOrStolenMoneyByTransportProvider,
  lostOrStolenItemNotByTransportProvider,
  lostOrStolenItemByTransportProvider,
  requireHospitalAdmissionNoAmbulanceNoEmc,
  requireHospitalAdmissionAndAmbulanceNoEmc,
  noHospitalAdmissionNoAmbulanceNoEmc,
  noHospitalAdmissionRequireAmbulanceNoEmc,
  myHealthOther,
  rentalCarAccidentNoVehiclesInvolved,
  rentalCarAccidentVehiclesInvolved,
  somethingElseOther,
  myTripOther,
};
