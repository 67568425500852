import { useCallback, useEffect, useRef } from 'react';

import { logToSentry } from 'freely-shared-utils';

type Props = {
  url: string;
  host: string;
  onConfigChange: () => void;
};

export const useConfigSubscription = async ({ url, host, onConfigChange }: Props) => {
  const conn = useRef<WebSocket | null>(null);
  const subscriptionId = useRef<string | null>(
    window.crypto.getRandomValues(new Uint32Array(1))[0].toString(),
  );

  const connect = useCallback(async () => {
    if (conn.current && conn.current.readyState === 1) {
      return;
    }

    const queryString = `?header=${btoa(
      JSON.stringify({
        Authorization: 'none',
        host,
      }),
    )}&payload=${btoa('{}')}`;

    try {
      conn.current = new WebSocket(`${url}/${queryString}`, 'graphql-ws');

      conn.current.onmessage = event => {
        if (!conn.current) {
          return;
        }
        const data = JSON.parse(event.data ?? '{}');

        if (data.type === 'connection_ack') {
          conn.current.send(
            JSON.stringify({
              id: subscriptionId.current,
              payload: {
                data: '{"query":"subscription configChangeSubscription {\\n  configChangeSubscription {\\n    eventId\\n    id\\n    version\\n  }\\n}","variables":null}',
                extensions: {
                  authorization: {
                    Authorization: 'none',
                    host,
                  },
                },
              },
              type: 'start',
            }),
          );
        } else if (data.type === 'data') {
          if (data.payload?.data?.configChangeSubscription) {
            onConfigChange();
          }
        }
      };
      conn.current.onerror = event => {
        const errorMsg =
          (event as WebSocketErrorEvent).message ??
          (event as ErrorEvent).error?.message ??
          (event as ErrorEvent).error?.toString() ??
          (event as any).data?.toString() ??
          event?.toString() ??
          'Unknown WebSocket error';

        logToSentry(new Error(`Websocket error: ${errorMsg}`));
      };

      return new Promise(resolve => {
        const timer = setInterval(() => {
          if (conn.current?.readyState === 1) {
            clearInterval(timer);
            resolve(conn);
          }
        }, 50);
      });
    } catch (err) {
      logToSentry(err as Error);
    }
  }, [url, host, onConfigChange]);

  const disconnect = useCallback(() => {
    if (conn.current && conn.current.readyState === 1) {
      conn.current.send(JSON.stringify({ type: 'stop', id: subscriptionId.current }));
      conn.current.close();
    }
  }, []);

  useEffect(() => {
    if (!conn.current) {
      connect().then(() => {
        conn.current?.send(JSON.stringify({ type: 'connection_init' }));
      });
    }

    return () => {
      disconnect();
    };
  }, [connect, disconnect]);
};
