import { useConfigStore } from 'freely-shared-stores';
import { CreateQuoteRequest, GetQuoteForm, PrimaryTraveller } from 'freely-shared-types';

import { useQuoteStore } from '@store/quote';

export function constructQuoteRequest(getQuoteForm: GetQuoteForm): CreateQuoteRequest {
  const promotionCode = useQuoteStore.getState()?.quote?.promotionCode ?? undefined;
  const startDate = getQuoteForm?.tripDates?.startDate ?? '';
  const endDate = getQuoteForm?.tripDates?.endDate ?? '';
  const secondaryTravellers = getQuoteForm?.secondaryTravellers
    ? getQuoteForm.secondaryTravellers.map(traveller => ({ ...traveller }))
    : [];
  const destinations =
    getQuoteForm?.destinations?.map(it => ({
      ...it,
      countryId: it.countryId.toString(),
    })) ?? [];
  const primaryTraveller: PrimaryTraveller = {
    dob: getQuoteForm?.primaryTravellerDOB ?? '',
    isResident: !!getQuoteForm?.primaryTravellerIsResident,
    firstName: '',
    lastName: '',
  };

  const productId = useConfigStore?.getState()?.regionSpecificConfig?.DEFAULT_PRODUCT_ID ?? '';
  return {
    startDate,
    endDate,
    secondaryTravellers,
    destinations,
    primaryTraveller,
    productId,
    promotionCode,
  };
}

export function initialiseQuoteFormWithStore() {
  const primaryTravellerDOB = useQuoteStore.getState()?.quote?.primaryTraveller?.dob ?? '';
  const secondaryTravellers = useQuoteStore.getState()?.quote?.secondaryTravellers ?? [];
  const primaryTravellerIsResident =
    !!useQuoteStore.getState()?.quote?.primaryTraveller?.isResident;
  const residentCheckBoxStatus =
    useConfigStore.getState()?.regionSpecificConfig?.RULES?.RESIDENT_CHECKBOX;
  const tripStoreDestinations = useQuoteStore.getState()?.quote?.destinations ?? [];
  const startDate = useQuoteStore.getState()?.quote?.startDate ?? '';
  const endDate = useQuoteStore.getState()?.quote?.endDate ?? '';
  const tripDates = { startDate, endDate };

  return {
    primaryTravellerDOB,
    secondaryTravellers,
    ...(residentCheckBoxStatus && { primaryTravellerIsResident }),
    destinations: tripStoreDestinations,
    tripDates,
  };
}
