import React from 'react';

import { Svg } from '@assets/svg';
import { Button } from '@components/button';
import { Text } from '@components/text';
import { i18n } from '@i18n/i18n';

const OutageMessage: React.FC = () => (
  <div className="rounded-xl bg-cherry-10 p-4 mb-4">
    <div className="flex items-center lg:justify-center">
      <div className="flex-shrink-0 self-start lg:self-center">
        <Svg.Alert className="fill-cherry-100 w-4" aria-hidden="true" />
      </div>
      <div className="ml-3 items-center lg:flex lg:justify-center">
        <Text variant="sub-heading-14/medium">{i18n.t('global.errors.outage.title')}</Text>
        <div className="mt-2 lg:mt-0 lg:ml-1">
          <Text variant="sub-heading-14/regular">{i18n.t('global.errors.outage.description')}</Text>
        </div>
        <p className="mt-3 text-sm lg:ml-6 lg:mt-0">
          <Button
            variant="charcoal"
            fontVariant="caption-12/500"
            size="xs"
            IconRight={Svg.Refresh}
            IconRightProps={{ className: 'text-white w-4' }}
            onClick={() => window.location.reload()}>
            {i18n.t('global.errors.updated.actions.reload')}
          </Button>
        </p>
      </div>
    </div>
  </div>
);

export default React.memo(OutageMessage);
