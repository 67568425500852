import { ChatMessage, MessageType, RiskAlertMessage, RiskLevelValue } from 'freely-shared-types';

const message: RiskAlertMessage = {
  riskAlertMessageId: 'string',
  messageId: 'string',
  ticketId: 'ticket id',
  chatId: 'string',
  author: { avatarUrl: '', firstName: 'string', lastName: 'string', id: 'string' },
  createdAt: new Date().valueOf(),
  hyperMessageType: 'RISK_ALERT_MESSAGE',
  header: 'string',
  messageType: MessageType.RISK_MESSAGE,
  backgroundUrl:
    'https://images.unsplash.com/photo-1631631480669-535cc43f2327?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1374&q=80',
  startDateUTC: 1676527387689,
  endDateUTC: 1676527387689,
  title: 'Casualties reported following IED explosion in Laag, Bari',
  text: 'Updated reports indicated that at least 11 soldiers were killed after al-Shabaab militants attacked a military base in Hawadley, Middle Shabelle region, during morning hours local time. No further details were immediately available. ',
  countryName: 'Somalia',
  countryCode: 'test',
  isActive: true,
  advice:
    'For business-critical travel in the country, vary routines, avoid travel after dark and only venture beyond the perimeter of a secured compound with an embassy-approved escort.',
  takesPlace: 'test',
  category: 'POLITICAL_DEVELOPMENTS',
  riskLevel: RiskLevelValue.EXTREME,
};

/**
 * Mock data for mobile contains author coming from freely
 * and time labels.
 */
export const appRiskAlertData: ChatMessage & {
  messageType: MessageType.RISK_MESSAGE;
} = {
  ...message,
  author: {
    avatarUrl: '',
    id: 'team',
    lastName: 'Team',
    firstName: 'Freely',
  },
  dateLabel: 'THURSDAY',
  timeLabel: '5:03 PM',
};

export const riskAlertData = { message, appRiskAlertData };
