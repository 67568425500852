import React, { ChangeEvent, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useConfigStore } from 'freely-shared-stores';
import { DestinationsForm, TripDestination } from 'freely-shared-types';

import { DestinationsList } from '@components/destinations/destinationsList.component';
import { DestinationsSection } from '@components/destinations/destinationsSection.component';
import { DestinationsSelected } from '@components/destinations/destinationsSelected.component';
import { DestinationSuggestion } from '@components/destinations/destinationsSuggestion.component';
import { PortalWrapper } from '@components/portalWrapper';
import { SectionContainer } from '@components/sectionContainer';
import { useSelectDestination } from '@hooks/useSelectDestination';
import { useSectionStore } from '@store/section';

export interface DestinationsProps {
  destinationPortalRef: React.RefObject<HTMLDivElement>;
}

export const Destinations: React.FC<DestinationsProps> = ({ destinationPortalRef }) => {
  const isValidatingOnMount = useSectionStore(state => state?.destinations.isValidatingOnOpen);
  const regionSpecificConfig = useConfigStore(state => state?.regionSpecificConfig);
  const maxDestinations = regionSpecificConfig?.RULES.MAX_DESTINATIONS ?? 10;

  const { watch, control, trigger } = useFormContext<DestinationsForm>();
  const formTripDestinations = watch('destinations');
  const hasReachedMaxDestinations = formTripDestinations.length >= maxDestinations;
  const { handleToggleDestination, searchValue, setSearchValue, destinationRef } =
    useSelectDestination();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    const validateFromGetQuote = async () => {
      if (isValidatingOnMount) {
        await trigger('destinations');
      }
    };
    validateFromGetQuote();
  }, [isValidatingOnMount, trigger]);

  return (
    <SectionContainer sectionType="destinations">
      {({ isFocused, onFocus, onClose }) => (
        <Controller
          rules={{ required: true }}
          control={control}
          render={({ field: { onChange } }) => {
            const renderDestinations =
              formTripDestinations.length > 0 ? (
                <DestinationsSelected
                  selectedDestinations={formTripDestinations}
                  onClick={handleToggleDestination(onChange)}
                  hasReachedMaxDestinations={hasReachedMaxDestinations}
                />
              ) : (
                <DestinationsList onClick={handleToggleDestination(onChange)} />
              );

            return (
              <>
                <DestinationsSection
                  isFocused={isFocused}
                  onFocus={onFocus}
                  onChange={handleChange}
                  ref={destinationRef}
                  searchValue={searchValue}
                  selectedDestinations={formTripDestinations}
                  hasReachedMaxDestinations={hasReachedMaxDestinations}
                />
                <PortalWrapper portalRef={destinationPortalRef} isFocused={isFocused}>
                  {searchValue.length > 0 ? (
                    <DestinationSuggestion
                      searchValue={searchValue}
                      selectedDestinations={formTripDestinations}
                      onRemoveSelectedDestination={handleToggleDestination(onChange)}
                      onClick={(destination: TripDestination) => {
                        handleToggleDestination(onChange)(destination);
                        onClose?.();
                        setTimeout(() => {
                          onFocus?.();
                        }, 100);
                      }}
                    />
                  ) : (
                    renderDestinations
                  )}
                </PortalWrapper>
              </>
            );
          }}
          name="destinations"
        />
      )}
    </SectionContainer>
  );
};
