import { QuickSelectOption } from '@types';

export const quickSelect: Omit<QuickSelectOption, 'index'>[] = [
  { amount: null, type: 'title', label: 'Exact Dates' },
  { amount: 14, type: 'days', label: 'days' },
  { amount: 1, type: 'months', label: 'month' },
  { amount: 3, type: 'months', label: 'months' },
  { amount: 6, type: 'months', label: 'months' },
  { amount: 9, type: 'months', label: 'months' },
  { amount: 1, type: 'years', label: 'year' },
];
export const quickSelectOptions: QuickSelectOption[] = quickSelect.map((item, index) => ({
  ...item,
  index,
}));

export const quickSelectExactDatesIndex = quickSelectOptions.findIndex(
  it => it.type === 'title' && it.label === 'Exact Dates',
);

export const dobFormat = '##/##/####';

export const DATES_EXPIRED_MESSAGE = 'dates_expired';
