import React from 'react';

import { useModalStore } from '@store/modal';

import { FreelyModal } from '../freelyModal/modal.component';
import { CustomModal } from '../modal';

export const ModalNavigator: React.FC = () => {
  const modals = useModalStore(state => state.modals);

  if (modals.length === 0) {
    return null;
  }

  return (
    <>
      {modals.map((modal, index) => {
        switch (modal.modalName) {
          case 'Modal':
            return (
              <CustomModal
                key={`${modal.modalName}_${index}`}
                isOpen
                {...modal.params}></CustomModal>
            );
          case 'FreelyModal':
            return (
              <FreelyModal
                key={`${modal.modalName}_${index}`}
                isOpen
                {...modal.params}></FreelyModal>
            );
        }
        return null;
      })}
    </>
  );
};
