import React, { LegacyRef, forwardRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { CheckboxSVGIcon, CheckboxSVGIconProps } from '@assets/svg/checkboxSVG';
import { testProps } from '@utils/testProps';

import { Text, TextProps } from '../text';

interface CheckboxProps extends CheckboxSVGIconProps, React.InputHTMLAttributes<HTMLInputElement> {
  labelProps?: TextProps;
  onClick?: () => void;
  hasError?: boolean;
  errorMessage?: string;
}
export const Checkbox: React.FC<CheckboxProps> = forwardRef(
  (
    {
      isChecked,
      onClick,
      labelProps,
      className,
      hasError,
      errorMessage,
      name,
      ...rest
    }: CheckboxProps,
    ref?: LegacyRef<HTMLInputElement>,
  ) => {
    const [isSelected, setIsSelected] = useState(isChecked);

    const handleClick = () => {
      setIsSelected(prevSelected => !prevSelected);
      onClick?.();
    };

    return (
      <div {...testProps('checkbox')} className={twMerge('py-2', className)}>
        <input ref={ref} type="checkbox" id={name} className="hidden" {...rest} />
        <label
          htmlFor={name}
          className="flex cursor-pointer flex-row items-center"
          onClick={handleClick}
          defaultChecked={isChecked}>
          <div className="w-12 border-cherry-100">
            <CheckboxSVGIcon
              hasError={hasError}
              isChecked={isSelected}
              className="mr-3 w-12"
              variant={{ shape: 'square' }}
              {...rest}
            />
          </div>
          {labelProps && <Text {...labelProps} />}
        </label>
        {hasError && errorMessage && (
          <Text className="text-cherry-100 ml-3 mt-2" variant="sub-heading-14/400">
            {errorMessage}
          </Text>
        )}
      </div>
    );
  },
);

Checkbox.displayName = 'Checkbox';
