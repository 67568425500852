import { AxiosError } from 'axios';
import { FieldErrors, useFormContext } from 'react-hook-form';

import { useConfigStore } from 'freely-shared-stores';
import { GetQuoteForm } from 'freely-shared-types';
import { createNewGetQuoteForm, logToSentry, wait } from 'freely-shared-utils';
import { getCookie } from 'freely-shared-utils';

import { DATES_EXPIRED_MESSAGE } from '@constants/common.ts';
import { useGetMinAvailableCalendarDate } from '@hooks/useGetMinAvailableCalendarDate.ts';
import { useModal } from '@hooks/useModal/useModal.ts';
import { useSyncQuoteStoreWithForm } from '@hooks/useSyncQuoteStoreWithForm.ts';
import { useQuoteStore } from '@store/quote';
import { useSectionStore } from '@store/section';
import { sendAnalyticsEvent } from '@utils/analytics';

export function useGetAQuote() {
  const closeAllSections = useSectionStore(state => state.closeAllSections);
  const createQuote = useQuoteStore(state => state.createQuote);
  const config = useConfigStore(state => state.config);
  const openSection = useSectionStore(state => state.openSection);
  const { reset } = useFormContext<GetQuoteForm>();
  const clearQuote = useQuoteStore(state => state.clearQuote);
  const setIsCalendarInputFocused = useSectionStore(state => state.setIsCalendarInputFocused);
  const setCalendarActiveStartTimeStamp = useSectionStore(
    state => state.setCalendarActiveStartTimeStamp,
  );
  const minAvailableCalendarDate = useGetMinAvailableCalendarDate();
  const syncQuoteStore = useSyncQuoteStoreWithForm();
  const { openTimeoutModal } = useModal();
  const irclickid = getCookie('irclickid') ?? '';
  const anonymousId = localStorage.getItem('ajs_anonymous_id') ?? '';
  const marketinganonymousid = anonymousId.replace(/"/g, '');

  const onSubmit = async (data?: GetQuoteForm) => {
    syncQuoteStore();
    try {
      closeAllSections();
      const {
        data: { quote },
      } = await createQuote(data);

      sendAnalyticsEvent('Widget Quote Submitted');

      const redirectSite = config?.ECOMM_URL;
      let url = `${redirectSite}/quote/${quote.market.toLowerCase()}/load?quoteId=${quote.quoteId}`;
      if (irclickid && marketinganonymousid) {
        url += `&irclickid=${irclickid}&marketinganonymousid=${marketinganonymousid}`;
      }
      clearQuote();
      (useQuoteStore as any)?.persist?.clearStorage?.(); // Clearing localStorage after widget creates quote until BE integration.
      window.location.href = url;
    } catch (e) {
      const errType =
        (e as AxiosError)?.response?.data?.message === 'Timeout error' ? 'Timeout' : 'Internal';

      openTimeoutModal({
        onOk: () =>
          sendAnalyticsEvent('Quote API Error Modal Closed', {
            type: errType,
          }),
        onClose: () => sendAnalyticsEvent('Quote API Error Modal Closed', { type: errType }),
      });

      sendAnalyticsEvent('Quote API Error Modal Display', {
        type: errType,
      });

      logToSentry(e as Error);
    }
  };

  const onError = async (errors: FieldErrors<GetQuoteForm>) => {
    closeAllSections();
    const hasDestinationsError = !!errors?.destinations;
    const hasTripDatesError = !!errors?.tripDates;
    const hasTravellersError =
      !!errors?.primaryTravellerDOB ||
      !!errors?.primaryTravellerIsResident ||
      !!errors?.secondaryTravellers;

    if (errors?.tripDates?.message === DATES_EXPIRED_MESSAGE) {
      setIsCalendarInputFocused(true);
      reset(createNewGetQuoteForm());
      clearQuote();
      (useQuoteStore as any)?.persist?.clearStorage?.();
      openSection('travelDates', true);
      setCalendarActiveStartTimeStamp(minAvailableCalendarDate.toJSDate());
      await wait(0);
      setIsCalendarInputFocused(false);
      return;
    }

    if (hasDestinationsError) {
      openSection('destinations', true);
      return;
    }

    if (hasTripDatesError) {
      openSection('travelDates', true);
      return;
    }

    if (hasTravellersError) {
      openSection('travellers', true);
      return;
    }
  };

  return { onSubmit, onError };
}
