export const colors = {
  mint: {
    10: '#E9FFF7',
    40: '#CBFFE9',
    70: '#CBFFE9', // TODO: Check this as it is the same as 40
    100: '#AAFFDE',
    200: '#5BFAC6',
  },
  snow: '#F6F8F9',
  snow_60: '#EFF2F699',
  rain: '#D3DCE4',
  flint: '#BBBEC3',
  disabled: '#D6DBE2',
  steal: '#667382',
  // This is just steal 50% opacity or Fuji/300 at 50% opacity
  gull_gray_50: '#D3DCE4',
  'light-charcoal': '#0F1014',
  charcoal: '#131E29',
  charcoal_60: '#131E2999',
  charcoal_70: '#131E29B2',
  white: '#FFFFFF',
  lime: '#E3FC5E',
  cabo: {
    50: '#F6F8F9',
    100: '#EBEFF3',
    200: '#D3DCE4',
    600: '#506D85',
    700: '#3E5366',
  },
  cherry: {
    10: '#FFEAF0',
    40: '#FFC1D4',
    70: '#FF99B7',
    100: '#FF326E',
  },
  sunset: {
    10: '#FFEFE5',
    40: '#FFD7B3',
    70: '#FE9D66',
    100: '#FE5B00',
  },
  lawn: {
    10: '#EAFAEC',
    40: '#BFEFC6',
    70: '#95E49F',
    100: '#2AC940',
  },
  gray: {
    100: '#F5F7FA',
    300: '#F7F8FA',
  },
  grass: {
    100: '#96C92A',
  },
  orange: {
    70: '#FBB500',
    100: '#FF7E32',
  },
  fuji: {
    50: '#BBBEC3',
    100: '#9FA6AE',
    200: '#838C97',
    300: '#667382',
    400: '#526274',
    500: '#3C4F63',
    600: '#2F4153',
    700: '#212F3E',
    800: '#131E29',
    900: '#000814',
  },
  nusa: {
    50: '#E9FFF7',
    100: '#CBFFE9',
    200: '#AAFFDE',
    300: '#5BFAC6',
    400: '#1BECAF',
    500: '#00D499',
    600: '#00AD7F',
    700: '#008A69',
    800: '#006D54',
    900: '#005946',
    950: '#003329',
  },
  apple_wallet_grey_outline: '#A6A6A6',
  black: '#131E29',
  green: '#008000',
  light_gray: '#DCDBDB',
  light_orange: '#DEBBA6',
  mystic: '#ECF0F4',
  persian_blue: '#2028BC',
  red: '#FF0000',
  strong_blue: '#0075CF',
  // Similar to Charcoal
  very_dark_blue: '#1A232B',
} as const;
