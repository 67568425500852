import { useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';

import {
  getRegionSelector,
  getRegionSpecificTripDurationRule,
  useConfigStore,
  useRegionStore,
} from 'freely-shared-stores';
import { CalendarData, FormOnChange, TripDatesForm } from 'freely-shared-types';
import { getRegionDateTime, hasTripDatesExceededLimit, wait } from 'freely-shared-utils';

import { quickSelectExactDatesIndex } from '@constants/common.ts';
import { useGetMinAvailableCalendarDate } from '@hooks/useGetMinAvailableCalendarDate.ts';
import { useHasDatesExpired } from '@hooks/useHasDatesExpired.ts';
import { useSectionStore } from '@store/section';
import { QuickSelectOption, TRIP_DATES_ERROR_TYPE } from '@types';
import { sendAnalyticsEvent } from '@utils/analytics.ts';
import {
  constructDatesFromOptions,
  getActiveCalendarStartDate,
  isStartDateWithinLimit,
} from '@utils/calendar.ts';

/**
 * The calendar will update the form state directly, as the business rules are checked on the calendar component.
 * When updating the state via the calendar, we must also update the ref. This will mean that the input will always have the latest data.
 */
export function useHandleCalendar() {
  const { getStartingQuickSelectDates } = useHasDatesExpired();
  const isCalendarDoubleView = useMediaQuery({ minWidth: 1024 });
  const minDateAvailable = useGetMinAvailableCalendarDate();
  const region = useRegionStore(getRegionSelector);
  const isCalendarInputFocused = useSectionStore(state => state.isCalendarInputFocused);
  const setIsCalendarInputFocused = useSectionStore(state => state.setIsCalendarInputFocused);
  const setSelectedQuickSelectIndex = useSectionStore(state => state.setSelectedQuickSelectIndex);
  const selectedQuickSelectIndex = useSectionStore(state => state.selectedQuickSelectIndex);

  const currentDate = getRegionDateTime(region?.country);
  const maxStartDateOffset = useConfigStore(
    state => state?.regionSpecificConfig?.RULES?.MAX_START_DATE_OFFSET,
  );

  const { setError, watch } = useFormContext<TripDatesForm>();
  const startDateFormValue = watch('tripDates.startDate');
  const endDateFormValue = watch('tripDates.endDate');
  const [defaultStartDateBeforeQuickSelect, defaultEndDateBeforeQuickSelect] =
    getStartingQuickSelectDates({ startDate: startDateFormValue, endDate: endDateFormValue });
  const startDateBeforeQuickSelect = useRef(defaultStartDateBeforeQuickSelect);
  const endDateBeforeQuickSelect = useRef(defaultEndDateBeforeQuickSelect);
  const [isTripDurationError, setIsTripDurationError] = useState(false);
  const setIsValidatingFromGetQuote = useSectionStore(state => state.setSectionIsValidating);
  const setActiveStartDate = useSectionStore(state => state.setCalendarActiveStartTimeStamp);
  const activeStartDate = useSectionStore(state =>
    state.calendarActiveStartTimeStamp ? new Date(state.calendarActiveStartTimeStamp) : new Date(),
  );

  const handleQuickSelect =
    (onFormChange: FormOnChange, previousValues: { startDate: string; endDate: string }) =>
    async (option: QuickSelectOption) => {
      const isAlreadySelected = selectedQuickSelectIndex === option.index;
      const isPlaceholder = option.type === 'title' || option.type === 'exact dates';
      setSelectedQuickSelectIndex(option.index);
      if (isAlreadySelected || isPlaceholder) {
        return;
      }

      setIsCalendarInputFocused(true);
      const { startDate, endDate, error } = constructDatesFromOptions({
        option,
        startDateValue: startDateBeforeQuickSelect.current,
        endDateValue: endDateBeforeQuickSelect.current,
        minDate: minDateAvailable,
        region,
      });

      if (error) {
        setIsTripDurationError(true);
        return;
      }

      setIsTripDurationError(false);
      sendAnalyticsEvent('Trip Dates Added', {
        startDate,
        endDate,
      });
      onFormChange({ startDate, endDate });
      setIsValidatingFromGetQuote('travelDates', false);
      const endingActiveStart = getActiveCalendarStartDate({
        startDate,
        endDate,
        isCalendarDoubleView,
        region,
      });

      const hasDatesChanged =
        startDate !== previousValues.startDate || endDate !== previousValues.endDate;

      if (hasDatesChanged) {
        setActiveStartDate(endingActiveStart);
      }
      // We need to wait for the callstack to clear before we can set the input to be unfocused
      await wait(0);
      setIsCalendarInputFocused(false);
    };

  const handleSelectDateDuration =
    (onFormChange: FormOnChange) => (startDate: string, endDate: string) => {
      setIsTripDurationError(false);
      onFormChange({ startDate, endDate });
      setIsValidatingFromGetQuote('travelDates', false);
      setSelectedQuickSelectIndex(quickSelectExactDatesIndex);

      startDateBeforeQuickSelect.current = startDate;
      endDateBeforeQuickSelect.current = endDate;

      if (startDate && endDate) {
        sendAnalyticsEvent('Trip Dates Added', {
          startDate,
          endDate,
        });
      }
    };

  const validateCalendarTripDuration = ({ durationInDays }: CalendarData) => {
    const maxTripDays = getRegionSpecificTripDurationRule();

    if (hasTripDatesExceededLimit({ durationInDays, maxTripDays })) {
      setIsTripDurationError(true);
      return false;
    }
    setIsTripDurationError(false);
    return true;
  };

  const validateCalendarStartDate = (startDate: string) => {
    if (
      !isStartDateWithinLimit({
        startDate,
        region,
        minAvailableDate: currentDate,
        maxDaysFromAvailableDate: maxStartDateOffset,
      })
    ) {
      setIsValidatingFromGetQuote('travelDates', false);
      setError('tripDates.startDate', {
        type: TRIP_DATES_ERROR_TYPE.START_DATE_EXCEEDS_OFFSET,
        message: `You can only select start date within the  next ${maxStartDateOffset} days.`,
      });
      return false;
    }
    return true;
  };

  return {
    validateCalendarStartDate,
    isCalendarInputFocused,
    setIsCalendarInputFocused,
    activeStartDate,
    setActiveStartDate,
    handleQuickSelect,
    handleSelectDateDuration,
    selectedQuickSelectIndex,
    setSelectedQuickSelectIndex,
    validateCalendarTripDuration,
    isTripDurationError,
  };
}
