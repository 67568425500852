import { BOOST_CATEGORY, Boost } from 'freely-shared-types';

export const tripWideBoost: Boost = {
  hasError: false,
  toggleStatus: null,
  tripId: 'trip_1664326810939_d9e8d1be-70a4-430b-81f5-c29e81e050ed',
  boostId: 'boost_1664326813479_7edced85-0003-4952-a1b5-ca64a1094d53',
  userId: '583bc760-2cad-41b2-aa6b-26638417fb7d',
  code: 'LBCP',
  name: 'Luggage',
  cmName: 'Luggage Basic Cover Pack (Freely)',
  benefits: [
    {
      name: 'Luggage',
      description:
        'Protect your luggage against theft or damage up to a $10,000 limit. Each item has a $750 limit.',
    },
  ],
  price: '1000',
  currency: 'AUD',
  version: '2022-08-08',
  startEndDates: [{ endDate: '2022-10-01', startDate: '2022-09-30' }],
  duration: 2,
  boostProperty: {},
  isMandatory: false,
  template: undefined,
  isAdded: false,
  category: BOOST_CATEGORY.MY_STUFF,
  description:
    'Protect your luggage against theft or damage up to a $10,000 limit. Each item has a $750 limit.',
  toUpdate: null,
  travellers: null,
  imageURL:
    'https://freely-s3-assets-dev.s3.ap-southeast-2.amazonaws.com/public/au/boost-images/luggage.png',
};

export const payPerDayBoost: Boost = {
  hasError: false,
  toggleStatus: null,
  tripId: 'trip_1664326810939_d9e8d1be-70a4-430b-81f5-c29e81e050ed',
  boostId: 'boost_1664326813479_4c21ea78-d3ba-4f2a-b5a3-2810af99d8d8',
  userId: '583bc760-2cad-41b2-aa6b-26638417fb7d',
  code: 'MTCL3',
  name: 'Motorcycle',
  cmName: 'Motorcycle (Freely)',
  benefits: [
    {
      name: 'Motorcycle',
      description: 'Born to Ride? Are you planning to ride a motorcycle or moped?',
    },
  ],
  price: '0',
  currency: 'AUD',
  version: '2022-08-08',
  startEndDates: [],
  duration: 0,
  boostProperty: { payPerDayRate: 700.0 },
  isMandatory: false,
  template: null,
  isAdded: false,
  category: BOOST_CATEGORY.MY_ACTIVITIES,
  description: 'Born to Ride? Are you planning to ride a motorcycle or moped?',
  toUpdate: null,
  travellers: null,
  imageURL:
    'https://freely-s3-assets-dev.s3.ap-southeast-2.amazonaws.com/public/au/boost-images/motobike_cover.png',
};

export const ineligibleBoost: Boost = {
  hasError: false,
  isEligible: false,
  toggleStatus: null,
  tripId: 'trip_1664326810939_d9e8d1be-70a4-430b-81f5-c29e81e050ed',
  boostId: 'boost_1664326813479_7edced85-0003-4952-a1b5-ca64a1094d53',
  userId: '583bc760-2cad-41b2-aa6b-26638417fb7d',
  code: '',
  name: 'Luggage',
  cmName: 'Luggage Basic Cover Pack (Freely)',
  benefits: [
    {
      name: 'Luggage',
      description:
        'Protect your luggage against theft or damage up to a $10,000 limit. Each item has a $750 limit.',
    },
  ],
  price: '1000',
  currency: 'USD',
  version: '2022-08-08',
  startEndDates: [{ endDate: '2022-10-01', startDate: '2022-09-30' }],
  duration: 2,
  boostProperty: {},
  isMandatory: false,
  template: undefined,
  isAdded: false,
  category: BOOST_CATEGORY.MY_STUFF,
  description:
    'Protect your luggage against theft or damage up to a $10,000 limit. Each item has a $750 limit.',
  toUpdate: null,
  travellers: null,
  imageURL:
    'https://freely-s3-assets-dev.s3.ap-southeast-2.amazonaws.com/public/au/boost-images/luggage.png',
};
