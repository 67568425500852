import { twMerge } from 'tailwind-merge';
import { shallow } from 'zustand/shallow';

import { colors } from 'freely-shared-design';
import { TripDestination } from 'freely-shared-types';

import { Svg } from '@assets/svg';
import { SectionChildrenWrapper } from '@components/sectionChildrenWrapper';
import { TextProps } from '@components/text';
import { i18n } from '@i18n/i18n';
import { useFeatureFlagsStore } from '@store/featureFlags';

import { Button } from '../button';
import { Notification, NotificationVariantTypes } from '../notification';

export interface DestinationsSelectedProps {
  selectedDestinations: TripDestination[];
  onClick?: (destination: TripDestination) => void;
  hasReachedMaxDestinations: boolean;
}

export const DestinationsSelected = ({
  selectedDestinations,
  onClick,
  hasReachedMaxDestinations,
}: DestinationsSelectedProps) => {
  const { headingProps, descriptionProps, variant } = useGetDestinationNotificationProps({
    selectedDestinations,
  });
  return (
    <SectionChildrenWrapper className="lg:border lg:border-rain lg:shadow-lg rounded-2xl bg-white w-full lg:w-[43%] pt-4 lg:px-4 lg:py-4 overflow-hidden">
      <div className={twMerge('border-t lg:border-t-0 pt-4 lg:pt-0')}>
        {hasReachedMaxDestinations && (
          <Notification
            variant="warning"
            headingProps={{
              children: i18n.t('where.warnings.maxDestination.title'),
            }}
            descriptionProps={{
              children: i18n.t('where.warnings.maxDestination.description'),
            }}
            className="mb-5"
          />
        )}
        <div className={twMerge('flex flex-row flex-wrap')}>
          {selectedDestinations.map(destination => (
            <Button
              key={destination.longName}
              onClick={() => onClick?.(destination)}
              title={destination.longName}
              IconRight={Svg.CloseCircleLight}
              IconRightProps={{ fill: colors.white }}
              size="md"
              textAlign="left"
              fontVariant="sub-heading-14/medium"
              variant="fuji"
              className="w-auto text-left mb-2 mr-2 space-x-4 max-h-[36px] lg:max-h-[40px]">
              {destination.countryName}
            </Button>
          ))}
        </div>
        {!hasReachedMaxDestinations && (
          <Notification
            variant={variant}
            headingProps={headingProps}
            descriptionProps={descriptionProps}
            className="mt-4"
          />
        )}
      </div>
    </SectionChildrenWrapper>
  );
};

function useGetDestinationNotificationProps({
  selectedDestinations,
}: {
  selectedDestinations: TripDestination[];
}) {
  const props: {
    headingProps: TextProps;
    descriptionProps: TextProps;
    variant: NotificationVariantTypes;
  } = {
    headingProps: {
      children: i18n.t('where.info.title'),
    },
    descriptionProps: { children: i18n.t('where.info.description') },
    variant: 'info',
  };

  const [isFeatureEnabled, featurePayload] = useFeatureFlagsStore(
    state => [
      !!state?.featureFlags?.['cruise-cover-notification']?.isEnabled,
      state?.featureFlags?.['cruise-cover-notification']?.payload,
    ],
    shallow,
  );

  if (!isFeatureEnabled || !featurePayload) {
    return props;
  }

  const hasCruiseNotification = selectedDestinations.some(it =>
    featurePayload?.cruiseCountryCodes.includes(it.countryCode),
  );

  if (hasCruiseNotification) {
    props.headingProps = {
      children: featurePayload.title,
    };
    props.descriptionProps = {
      children: featurePayload.subtitle,
    };
    props.variant = 'warning';
    return props;
  }

  return props;
}
