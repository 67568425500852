import { cva } from 'class-variance-authority';
import { SyntheticEvent, forwardRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { ButtonVariant } from 'freely-shared-types';

import { ButtonSize } from '@types';

import { Loader } from '../loader';
import { Text, TextProps } from '../text';

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  fontVariant?: TextProps['variant'];
  withFixedWith?: boolean;
  withEllipsis?: boolean;
  withWordWrap?: boolean;
  isJumboSize?: boolean;
  variant?: ButtonVariant;
  size?: ButtonSize;
  IconLeft?: React.FC<React.SVGProps<SVGElement>>;
  IconRight?: React.FC<React.SVGProps<SVGElement>>;
  IconRightProps?: React.SVGProps<SVGElement>;
  IconLeftProps?: React.SVGProps<SVGElement>;
  disabled?: boolean;
  isLoading?: boolean;
  textAlign?: 'left' | 'right' | 'center';
  onClick?: () => Promise<unknown> | void;
  type?: 'button' | 'submit' | 'reset';
  shouldShowLoader?: boolean;
  isStoppingPropagation?: boolean;
}

export const buttonVariants = cva(
  'duration-400 relative rounded-full transition active:scale-105',
  {
    variants: {
      variant: {
        mint: 'bg-mint-100 disabled:text-white disabled:bg-disabled hover:bg-mint-70',
        charcoal:
          'bg-charcoal [&>p]:text-white disabled:text-white disabled:bg-charcoal/60 hover:bg-charcoal/80',
        snow: 'bg-snow disabled:text-charcoal/50 disabled:bg-snow/60 hover:bg-snow/80',
        white: 'bg-white disabled:text-charcoal/50 disabled:bg-white/60 hover:bg-white/80 ',
        cherry:
          'bg-cherry-100 text-white disabled:text-white disabled:bg-cherry-100 focus:bg-cherry-100',
        sunset: 'bg-sunset-10 text-charcoal',
        cabo: 'bg-cabo-50',
        fuji: 'bg-fuji-800  [&>p]:text-white ',
        outline:
          'bg-white ring-1 ring-inset ring-charcoal disabled:text-charcoal/50 disabled:bg-white/60 hover:bg-white/80',
      },
      size: {
        xl: 'px-8 py-1 h-16',
        lg: 'px-8 py-1 h-16',
        md: 'px-5 py-1 h-12',
        sm: 'px-3 py-1 h-8',
        xs: 'px-3 h-6',
      },
    },
  },
);

const getFontVariant: (size?: ButtonSize) => TextProps['variant'] = size => {
  switch (size) {
    case 'xl':
      return 'title-24/600';
    case 'lg':
      return 'sub-title-20/600';
    case 'md':
      return 'body-16/600';
    case 'sm':
      return 'sub-heading-14/regular';
    case 'xs':
      return 'caption-12/regular';
  }
};

export const iconVariants = cva('', {
  variants: {
    size: {
      xl: 'max-w-[2rem]',
      lg: 'max-w-[2rem]',
      md: 'max-w-[1.25rem]',
      sm: 'max-w-[1rem]',
      xs: 'max-w-[1rem]',
    },
  },
});

export const textAlignVariant = cva('', {
  variants: {
    textAlign: {
      left: 'text-left',
      right: 'text-right',
      center: 'text-center',
    },
  },
});

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      textAlign = 'center',
      size = 'md',
      fontVariant,
      variant,
      IconLeft,
      IconRight,
      IconLeftProps,
      IconRightProps,
      disabled = false,
      isLoading = false,
      withFixedWith,
      withWordWrap = false,
      isJumboSize,
      withEllipsis,
      onClick,
      type = 'button',
      shouldShowLoader = true,
      isStoppingPropagation = true,
      ...rest
    },
    ref,
  ) => {
    const [isPending, setIsPending] = useState<boolean>(false);
    const isLoadingSpinnerDisplayed = isPending || isLoading;

    const onButtonClick = async (e: SyntheticEvent) => {
      type === 'submit' && e.preventDefault();
      isStoppingPropagation && e.stopPropagation();
      try {
        shouldShowLoader && setIsPending(true);
        await onClick?.();
        shouldShowLoader && setIsPending(false);
      } catch (e) {
        // sometimes callbacks can throw errors, we don't want to show the loader forever
      } finally {
        shouldShowLoader && setIsPending(false);
      }
    };

    return (
      <button
        type={type}
        ref={ref}
        onClick={onButtonClick}
        disabled={disabled || isLoadingSpinnerDisplayed}
        className={twMerge(
          buttonVariants({ variant, size }),
          withFixedWith && 'w-11/12 lg:w-52',
          isJumboSize && 'h-20 w-full rounded-[1.5rem]',
          IconRight && 'flex justify-between items-center space-x-0.5',
          className,
        )}
        {...rest}>
        {IconLeft && (
          <IconLeft
            {...IconLeftProps}
            fill={getIconFill(isLoadingSpinnerDisplayed, IconLeftProps)}
            className={twMerge(
              iconVariants({ size }),
              'absolute top-1/2 left-3.5 translate-y-[-50%]',
              IconLeftProps?.className,
            )}
          />
        )}
        {isLoadingSpinnerDisplayed && (
          <div
            className={twMerge(
              'absolute top-1/2 left-1/2 z-10 translate-y-[-50%] translate-x-[-50%]',
              iconVariants({ size }),
            )}>
            <Loader color={getLoaderColor(variant)} />
          </div>
        )}
        <Text
          className={twMerge(
            !withWordWrap && 'whitespace-nowrap',
            isLoadingSpinnerDisplayed && '!text-transparent',
            textAlignVariant({ textAlign }),
            IconLeft && 'ml-5 md:ml-2.5',
            IconRight && (String(IconRightProps?.style) ?? 'mr-2.5'),
            withEllipsis && 'overflow-hidden text-ellipsis',
          )}
          variant={fontVariant ?? getFontVariant(size)}>
          {children}
        </Text>
        {IconRight && (
          <IconRight
            {...IconRightProps}
            fill={getIconFill(isLoadingSpinnerDisplayed, IconRightProps)}
            className={twMerge(iconVariants({ size }), IconRightProps?.className)}
          />
        )}
      </button>
    );
  },
);

const getLoaderColor = (variant: ButtonVariant | undefined) => {
  return variant === 'charcoal' || variant === 'fuji' ? 'white' : 'charcoal';
};
const getIconFill = (
  isLoadingSpinnerDisplayed: boolean,
  iconProps: React.SVGProps<SVGElement> | undefined,
) => {
  return isLoadingSpinnerDisplayed ? 'transparent' : iconProps?.fill;
};

Button.displayName = 'Button';
