import { cva } from 'class-variance-authority';
import { FC, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

import { colors } from 'freely-shared-design';

export interface StepperSVGIconProps extends React.HTMLAttributes<SVGElement> {
  variant?: 'snow';
  type?: 'add' | 'remove';
  disabled?: boolean;
  className?: string;
  hasError?: boolean;
}

export const stepperVariants = cva('', {
  variants: {
    variant: {
      snow: [colors.snow, colors.charcoal],
    },
    disabled: {
      true: [colors.white, colors.snow, colors.flint],
    },
    hasError: {
      true: [colors.white, colors.red, colors.flint],
    },
  },
});

export const StepperSVGIcon: FC<StepperSVGIconProps> = ({
  variant = 'snow',
  className,
  type = 'add',
  disabled = false,
  hasError = false,
  ...rest
}) => {
  const { backgroundColor, strokeColor, iconColor } = useMemo(
    () => getColors(variant, disabled, hasError),
    [variant, disabled, hasError],
  );
  const renderIcon = useMemo(() => getIcon(type, iconColor), [type, iconColor]);
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={twMerge('h-10 w-10', className)}
      {...rest}>
      <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill={backgroundColor} />
      {renderIcon}
      <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke={strokeColor} />
    </svg>
  );
};

const getIcon = (type: string, iconColor: string) => {
  switch (type) {
    case 'add':
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.5 19.5H25V20.5H20.5V25H19.5V20.5H15V19.5H19.5V15H20.5V19.5Z"
          fill={iconColor}
        />
      );
    case 'remove':
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25 19.5V20.5H15V19.5H25Z"
          fill={iconColor}
        />
      );
    default:
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.5 19.5H25V20.5H20.5V25H19.5V20.5H15V19.5H19.5V15H20.5V19.5Z"
          fill={iconColor}
        />
      );
  }
};

const getColors = (variant: 'snow', disabled: boolean, hasError: boolean) => {
  if (disabled) {
    return {
      backgroundColor: stepperVariants({ disabled }).split(' ')[0],
      strokeColor: stepperVariants({ disabled }).split(' ')[1],
      iconColor: stepperVariants({ disabled }).split(' ')[2],
    };
  }
  if (hasError) {
    return {
      backgroundColor: stepperVariants({ hasError }).split(' ')[0],
      strokeColor: stepperVariants({ hasError }).split(' ')[1],
      iconColor: stepperVariants({ hasError }).split(' ')[2],
    };
  }
  return {
    backgroundColor: stepperVariants({ variant }).split(' ')[0],
    strokeColor: stepperVariants({ variant }).split(' ')[0],
    iconColor: stepperVariants({ variant }).split(' ')[1],
  };
};
