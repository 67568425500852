import type {
  FontStyle,
  FontStyleVariants,
  FreelyTypographyBody,
  FreelyTypographyHeading,
} from './common';

const defaultFont: FontStyle = {
  fontFamily: 'Golos Text',
  fontWeight: 600,
};

const generateTextFontStyleVariants = (fontStyle: FontStyle): FontStyleVariants => ({
  regular: {
    ...fontStyle,
    fontWeight: 400,
    fontFamily: 'Golos Text',
  },
  medium: {
    ...fontStyle,
    fontWeight: 500,
    fontFamily: 'Golos Text',
  },
  bold: {
    ...fontStyle,
    fontWeight: 600,
    fontFamily: 'Golos Text',
  },
});

const generateHeadingItem = (fontStyle: FontStyle): FontStyleVariants => ({
  bold: { ...defaultFont, ...fontStyle },
});

const headingItems: FreelyTypographyHeading = {
  'h0-80': generateHeadingItem({
    fontSize: 80,
    lineHeight: '84px',
    letterSpacing: '-0.53px',
  }),
  'h1-64': generateHeadingItem({
    fontSize: 64,
    lineHeight: '66px',
    letterSpacing: '-1.43px',
  }),
  'h2-52': generateHeadingItem({
    fontSize: 52,
    lineHeight: '55px',
    letterSpacing: '-1.43px',
  }),
  'h3-48': generateHeadingItem({
    fontSize: 48,
    lineHeight: '52px',
    letterSpacing: '-1.43px',
  }),
  'h4-40': generateHeadingItem({
    fontSize: 40,
    lineHeight: '42px',
    letterSpacing: '-1.14px',
  }),
  'h5-32': generateHeadingItem({
    fontSize: 32,
    lineHeight: '36px',
    letterSpacing: '-0.55px',
  }),
  'h5-34': generateHeadingItem({
    fontSize: 34,
    lineHeight: '38px',
    letterSpacing: '-0.55px',
  }),
  'h6-28': generateHeadingItem({
    fontSize: 28,
    lineHeight: '36px',
    letterSpacing: '-0.33px',
  }),
  'h7-26': generateHeadingItem({
    fontSize: 26,
    lineHeight: '31px',
    letterSpacing: '-0.33px',
  }),
};

const bodyItems: FreelyTypographyBody = {
  'title-24': generateTextFontStyleVariants({
    letterSpacing: '-0.04px',
    lineHeight: '30px',
    fontSize: 24,
  }),
  'title-28': generateTextFontStyleVariants({
    letterSpacing: '-0.04px',
    lineHeight: '34px',
    fontSize: 28,
  }),
  'sub-title-20': generateTextFontStyleVariants({
    letterSpacing: '-0.03px',
    lineHeight: '25px',
    fontSize: 20,
  }),
  'body-18': generateTextFontStyleVariants({
    letterSpacing: '-0.03px',
    lineHeight: '24px',
    fontSize: 18,
  }),
  'body-16': generateTextFontStyleVariants({
    letterSpacing: '0px',
    lineHeight: '24px',
    fontSize: 16,
  }),
  'callout-16': generateTextFontStyleVariants({
    letterSpacing: '-0.03px',
    lineHeight: '20px',
    fontSize: 16,
  }),
  'sub-heading-14': generateTextFontStyleVariants({
    letterSpacing: '0.09px',
    lineHeight: '20px',
    fontSize: 14,
  }),
  'footnote-13': generateTextFontStyleVariants({
    letterSpacing: '0.06px',
    lineHeight: '18px',
    fontSize: 13,
  }),
  'caption-12': generateTextFontStyleVariants({ lineHeight: '18px', fontSize: 12 }),
  'reference-10': generateTextFontStyleVariants({
    letterSpacing: '0.05px',
    lineHeight: '13px',
    fontSize: 10,
  }),
};

interface FreelyTypography extends FreelyTypographyHeading, FreelyTypographyBody {}

export const typography: FreelyTypography = {
  ...headingItems,
  ...bodyItems,
};
