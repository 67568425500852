type ValidationAUProps = {
  maxLimit: string;
  widget: {
    invalidDate: string;
    invalidPrimaryAge: string;
    invalidSecondaryAge: string;
  };
};

export const validationAU: ValidationAUProps = {
  maxLimit: 'Max Travellers reach.',
  widget: {
    invalidDate: 'Required: Please enter a valid date of birth',
    invalidPrimaryAge: 'Please enter an age between {{min}} and {{max}}.',
    invalidSecondaryAge: 'Please enter an age between {{min}} and {{max}}.',
  },
};
