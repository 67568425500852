import { useEffect, useMemo } from 'react';

import { initSentry } from 'freely-shared-utils';

// Commenting out this language switcher - this will be used for demo purposes
// import LanguageSwitcher from '@components/languageSwitcher/languageSwitcher.component';
import { ModalNavigator } from '@components/modalNavigator';
import { Placeholder } from '@components/placeholder/placeholder.component';
import { useConfigSubscription } from '@hooks/useConfigSubscription';
import { useWidgetInit } from '@hooks/useWidgetInit';
import { useGlobalStore } from '@store/global';
import { useQuoteStore } from '@store/quote';
import { useSectionStore } from '@store/section';
import { getAnonymousId, sendAnalyticsEvent } from '@utils/analytics';

(async () => {
  initSentry({
    env: import.meta.env.VITE_ENV,
    dsn: import.meta.env.VITE_SENTRY_DSN_ENDPOINT,
    anonymousId: (await getAnonymousId()) ?? '',
  });
})();

export const App = () => {
  const calendarActiveStartTimeStamp = useSectionStore(state => state.calendarActiveStartTimeStamp);
  const setCalendarActiveStartTimeStamp = useSectionStore(
    state => state.setCalendarActiveStartTimeStamp,
  );
  const quoteStartDate = useQuoteStore(state => state?.quote?.startDate);
  const { isInitialised, isUnderMaintenance } = useWidgetInit();
  const setConfigChanged = useGlobalStore(state => state.setConfigChanged);

  const configSubscriptionOptions = useMemo(
    () => ({
      url: import.meta.env.VITE_APPSYNC_WSS_ENDPOINT,
      host: import.meta.env.VITE_APPSYNC_HOST,
      onConfigChange: () => {
        setConfigChanged(true);
      },
    }),
    [setConfigChanged],
  );

  useConfigSubscription(configSubscriptionOptions);

  useEffect(() => {
    sendAnalyticsEvent('widget_loaded');
  }, []);

  /**
   * Sets the active start date of the calendar to the quote start date
   */
  useEffect(() => {
    if (quoteStartDate && !calendarActiveStartTimeStamp) {
      setCalendarActiveStartTimeStamp(quoteStartDate);
    }
  }, [quoteStartDate, calendarActiveStartTimeStamp, setCalendarActiveStartTimeStamp]);

  return (
    <>
      {/* <LanguageSwitcher /> */}
      <Placeholder isInitialised={isInitialised} isUnderMaintenance={isUnderMaintenance} />
      <ModalNavigator />
    </>
  );
};
