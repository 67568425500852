import { ValidationMode, useForm } from 'react-hook-form';

import { GetQuoteForm } from 'freely-shared-types';
import { createNewGetQuoteForm } from 'freely-shared-utils';

/**
 * Initialises the get quote form with default values. If default values are provided and some fields are missing, missing fields will not be initialised.
 * If no default values are provided, then all fields will be initialised with empty values.
 * @param defaultFormValues
 */

type UseGetQuoteForm = {
  defaultFormValues?: GetQuoteForm;
  mode?: keyof ValidationMode;
  reValidateMode?: 'onBlur' | 'onChange' | 'onSubmit' | undefined;
};

export function useGetQuoteForm({ defaultFormValues, mode, reValidateMode }: UseGetQuoteForm) {
  const defaultValues = defaultFormValues ?? createNewGetQuoteForm();
  const methods = useForm({
    mode,
    reValidateMode,
    defaultValues,
  });

  return { methods };
}
