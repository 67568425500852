import React from 'react';

export interface ScrollWrapperProps {
  children: React.ReactNode;
}
//TODO: Dynamically set the offset
export const ScrollWrapper: React.FC<ScrollWrapperProps> = ({ children }) => {
  return (
    <div className={`lg:overflow-y-auto lg:max-h-[calc(100vh_-_390px)] 2xl:overflow-visible`}>
      {children}
    </div>
  );
};
