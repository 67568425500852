import { TripDestination } from 'freely-shared-types';

import { SlidesType } from '@components/swiper';
import { QuickSelectOption } from '@types';

/**
 * type guard for swiper component
 *
 * more details: https://www.typescriptlang.org/docs/handbook/advanced-types.html
 * @param data
 */
export const isDestination = (data: SlidesType): data is TripDestination => {
  return (data as TripDestination).longName !== undefined;
};

/**
 * type guard for swiper component
 *
 * more details: https://www.typescriptlang.org/docs/handbook/advanced-types.html
 * @param data
 */
export const isQuickSelectOption = (data: SlidesType): data is QuickSelectOption => {
  return (data as QuickSelectOption).label !== undefined;
};
