import { ModalConfig, ModalStore, createStore, modalStoreGenerator } from 'freely-shared-stores';

import { FreelyModalProps } from '@components/freelyModal/modal.component';
import { ModalProps } from '@components/modal';

export type _ModalConfig = ModalConfig<ModalProps | FreelyModalProps>;

export type _ModalStore = ModalStore<ModalProps | FreelyModalProps>;

export const useModalStore = createStore(
  modalStoreGenerator<ModalProps | FreelyModalProps>({
    modals: [],
    uniqueModals: ['RegionGridModal'],
  }),
  {
    name: 'useModalStore',
    shouldPersist: false,
  },
);
