import kebabCase from 'lodash/kebabCase';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import { twMerge } from 'tailwind-merge';

import { colors } from 'freely-shared-design';
import {
  getRegionSelector,
  regionDateUtils,
  useConfigStore,
  useRegionStore,
} from 'freely-shared-stores';
import { TravellersForm } from 'freely-shared-types';
import {
  dateToUTCFormat,
  primaryAgeValidation,
  utcToDateFormat,
  validateDate,
} from 'freely-shared-utils';

import { Svg } from '@assets/svg';
import { Input } from '@components/input';
import { SectionChildrenWrapper } from '@components/sectionChildrenWrapper';
import { Text } from '@components/text';
import { dobFormat } from '@constants/common';
import { i18n } from '@i18n/i18n';
import { useSectionStore } from '@store/section';
import { testProps } from '@utils/testProps';

import { TravellerList } from './travellerList.component';

export const EditTravellers = () => {
  const setIsValidatingFromGetQuote = useSectionStore(state => state.setSectionIsValidating);
  const isValidatingOnMount = useSectionStore(state => state?.travellers.isValidatingOnOpen);
  const dobPlaceholder = i18n.t('who.input.placeholder');
  const regionSpecificConfig = useConfigStore(state => state?.regionSpecificConfig);
  const region = useRegionStore(getRegionSelector);
  const primaryTravellerAge = regionSpecificConfig?.RULES.PRIMARY_TRAVELLER_AGE ?? {
    min: 18,
    max: 99,
  };
  const isResidencyCheckBoxEnabled = useConfigStore(
    state => !!state?.regionSpecificConfig?.RULES.RESIDENT_CHECKBOX,
  );

  const invalidDateError = i18n.t('validation.widget.invalidDate');
  const invalidPrimaryAgeError = i18n.t('validation.widget.invalidPrimaryAge', {
    min: primaryTravellerAge.min,
    max: primaryTravellerAge.max,
  });

  const {
    control,
    getValues,
    formState: { errors },
    trigger,
  } = useFormContext<TravellersForm>();
  const primaryTravellerAgeNumber = regionDateUtils().getAge(
    getValues('primaryTravellerDOB') ?? '',
  );

  useEffect(() => {
    const validateFromGetQuote = async () => {
      if (isValidatingOnMount) {
        await trigger('primaryTravellerDOB');
        isResidencyCheckBoxEnabled && (await trigger('primaryTravellerIsResident'));
        await trigger('secondaryTravellers');
      }
    };
    validateFromGetQuote();
  }, [isResidencyCheckBoxEnabled, isValidatingOnMount, trigger]);

  return (
    <SectionChildrenWrapper>
      <div className="pt-3 lg:pb-0 lg:p-4 rounded-xl">
        <form>
          <div className="pb-4">
            <div className="flex flex-row items-center justify-between">
              <div>
                <div className="flex flex-row items-center">
                  <Text variant="sub-heading-14/500">{i18n.t('who.input.title')}</Text>
                  {primaryTravellerAgeNumber ? (
                    <Text variant="sub-heading-14/400" className="ml-1 text-steal">
                      •{' '}
                      <span className={errors.primaryTravellerDOB && 'text-cherry-100'}>
                        {primaryTravellerAgeNumber} <span>yo</span>
                      </span>
                    </Text>
                  ) : null}
                </div>
                <Text variant="sub-heading-14/400" className="pt-1 text-steal">
                  {i18n.t('who.input.subTitle')}
                </Text>
              </div>

              <Controller
                control={control}
                rules={{
                  validate: {
                    validDate: v => validateDate(v ?? '', invalidDateError),
                    validateAge: v =>
                      primaryAgeValidation(
                        v ?? '',
                        {
                          min: primaryTravellerAge.min,
                          max: primaryTravellerAge.max,
                          country: region?.country,
                        },
                        invalidPrimaryAgeError,
                      ),
                  },
                }}
                name="primaryTravellerDOB"
                render={({ field }) => (
                  <div className="w-[9.8rem]">
                    <PatternFormat
                      {...testProps(`${kebabCase('dob input')}`)}
                      customInput={Input}
                      onChange={e => {
                        const value = e.target.value
                          ? dateToUTCFormat(e.target.value, region?.country)
                          : '';
                        field.onChange(value);
                        setIsValidatingFromGetQuote('travellers', false);
                      }}
                      inputMode="numeric"
                      onBlur={field.onBlur}
                      className={twMerge([
                        'h-[3rem] text-[1.1rem] placeholder:text-[1rem] pl-7 focus:shadow-none',
                      ])}
                      hasError={!!errors.primaryTravellerDOB}
                      placeholder={dobPlaceholder || ''}
                      value={utcToDateFormat(field.value, region?.country)}
                      format={dobFormat}
                      mask="_"
                      onKeyDown={(
                        e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
                      ) => {
                        e.key === 'Enter' && e.preventDefault();
                      }}
                    />
                  </div>
                )}
              />
            </div>
            {errors.primaryTravellerDOB && (
              <div className={twMerge('flex items-center h-9 mr-2')}>
                <Svg.Alert fill={colors.cherry[100]} width={18} />
                <Text
                  variant={'sub-heading-14/400'}
                  className="ml-[0.5rem] my-[1rem] text-cherry-100">
                  {errors.primaryTravellerDOB?.message}
                </Text>
              </div>
            )}
          </div>
        </form>
        <TravellerList />
      </div>
    </SectionChildrenWrapper>
  );
};
