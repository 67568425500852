import { DateTime, Duration } from 'luxon';

import { getRegionSpecificTripDurationRule } from 'freely-shared-stores';
import { Region } from 'freely-shared-types';
import {
  getDateDifferenceInDays,
  getRegionDateTime,
  parseISO,
  validateDate,
} from 'freely-shared-utils';

import { QuickSelectOption, TRIP_DATES_ERROR_TYPE, tripDatesErrorMessages } from '@types';

/**
 * Construct trip date range from the quick select option
 * @param option
 * @param startDateValue
 * @param endDateValue
 * @param minDate
 * @param region
 * @param maxTripDurationInDays
 */
export function constructDatesFromOptions({
  option,
  startDateValue,
  endDateValue,
  minDate,
  region,
  maxTripDurationInDays = 548,
}: {
  option: QuickSelectOption;
  startDateValue: string;
  endDateValue: string;
  minDate: DateTime;
  region?: Region;
  maxTripDurationInDays?: number | undefined;
}) {
  const { amount, type } = option;
  let startDate = '';
  let endDate = '';
  let error: { type: string; message: string } | null = null;
  let amountToAdd = amount;
  const isPlaceholder = !amount || type === 'title';

  const isEndDateOnlyEntered = endDateValue && !startDateValue;
  const isNoDatesEntered = !startDateValue && !endDateValue;
  const shouldAddDatesToMinimumDateAvailable = isNoDatesEntered || isEndDateOnlyEntered;
  const isQuickSelectOptionsDays = type === 'days';

  /**
   * quick select option is a placeholder
   * Just return the start and end date as is
   */
  if (isPlaceholder) {
    return { startDate: startDateValue, endDate: endDateValue };
  }

  if (shouldAddDatesToMinimumDateAvailable && isQuickSelectOptionsDays) {
    // When selecting the days to add from the minimum date, we need to subtract 1 day to the amount
    // as the min date is inclusive of duration
    amountToAdd = amount - 1;
  }

  /**
   * If the calendar input has both empty dates, invalid dates , or no start date we need to
   * add the quick select from the minimum date available
   */
  if (shouldAddDatesToMinimumDateAvailable) {
    const durationFromMinDate = Duration.fromObject({ [type]: amountToAdd });
    const isAmountSingleDay = amount === 1 && isQuickSelectOptionsDays;
    startDate = minDate.startOf('day').toISODate();
    endDate = minDate.startOf('day').plus(durationFromMinDate).toISODate();

    if (isAmountSingleDay) {
      // if amount is + 1 day only select the  minimum date available
      endDate = minDate.toISODate();
    }
    return { startDate, endDate };
  }

  const duration = Duration.fromObject({ [type]: amountToAdd });
  startDate = startDateValue;

  endDate = parseISO(endDateValue ?? startDateValue, region?.country)
    .startOf('day')
    .plus(duration)
    .toISODate();

  const isDurationExceedingMaxTripDuration =
    getDateDifferenceInDays(startDate, endDate, region?.country ?? 'AU') > maxTripDurationInDays;

  if (!endDateValue) {
    endDate = parseISO(startDateValue, region?.country).startOf('day').plus(duration).toISODate();
  }

  if (isDurationExceedingMaxTripDuration) {
    error = {
      type: TRIP_DATES_ERROR_TYPE.INVALID_TRIP_DURATION,
      message: 'Invalid date range',
    };
  }

  return { startDate, endDate, error };
}

/**
 * Filter quick select options based on the end date and the max available date
 * If the quick select option added to the end date will exceed the max available date, the option will be hidden.
 * @param option
 * @param endDate
 * @param minDateAvailable
 * @param region
 * @param tripStartDatePeriodInMonths
 * @param maxTripDurationInDays
 * @param startDate
 */
export function filterQuickSelectOptions({
  option,
  endDate,
  minDateAvailable,
  region,
  tripStartDatePeriodInMonths = 18,
  maxTripDurationInDays = 548,
  startDate,
}: {
  option: QuickSelectOption;
  startDate: string;
  endDate: string;
  minDateAvailable: DateTime;
  region?: Region;
  tripStartDatePeriodInMonths?: number;
  maxTripDurationInDays?: number;
}) {
  const { amount, type } = option;
  const maxDateAvailable = minDateAvailable.plus({ months: tripStartDatePeriodInMonths }); // This needs to be in config
  const shouldShowAllOptions =
    type === 'title' || !amount || !startDate || !endDate || region?.country === 'US';

  /**
   * User has not selected an end date so show all the pills
   * If option is type title or a placeholder, show it
   * US calendar will be handled by max calendar rules, so show all the pills
   */
  if (shouldShowAllOptions) {
    return true;
  }

  const durationFromEndDate = Duration.fromObject({ [type]: amount });
  const quickSelectAddedToExistingEndDate = parseISO(endDate, region?.country).plus(
    durationFromEndDate,
  );

  const isQuickSelectOptionLessThanMaxTripDays =
    getDateDifferenceInDays(startDate, quickSelectAddedToExistingEndDate, region?.country) <=
    maxTripDurationInDays;

  /**
   * If the quick select option added to the end date will exceed the max available date, the option will be hidden.
   */
  if (!isQuickSelectOptionLessThanMaxTripDays) {
    return false;
  }

  return quickSelectAddedToExistingEndDate.toMillis() <= maxDateAvailable.toMillis();
}

export function isTripDurationWithinLimit({
  startDate,
  endDate,
  region,
  maxTripDurationInDays,
}: {
  startDate: string;
  endDate: string;
  region?: Region;
  maxTripDurationInDays?: number;
}) {
  const startDateObj = parseISO(startDate, region?.country).startOf('day');
  const endDateObj = parseISO(endDate, region?.country).startOf('day');
  if (!maxTripDurationInDays) {
    // no limit specified so assume its valid
    return true;
  }

  return getDateDifferenceInDays(startDateObj, endDateObj, region?.country) < maxTripDurationInDays;
}

export function hasSelectedCalendarInputDatesExceededLimit({
  endDate,
  startDate,
  region,
  maxTripDurationInDays,
}: {
  endDate: string;
  startDate: string;
  region?: Region;
  maxTripDurationInDays?: number;
}) {
  return (
    !validateDate(endDate) &&
    !validateDate(startDate) &&
    !isTripDurationWithinLimit({
      startDate,
      endDate,
      region,
      maxTripDurationInDays,
    })
  );
}

//TODO: This function is also used in the RN 0.72.3 branch. It needs to be removed and used from shared.
/**
 * Returns true if the start date is within the limit
 * ie max trip duration is 548 days. This means that trip start date must be within 548 days of the min available date
 * @param startDate
 * @param minAvailableDate
 * @param maxTripDurationInDays
 * @param region
 */
export function isStartDateWithinLimit({
  startDate,
  minAvailableDate,
  maxDaysFromAvailableDate,
  region,
}: {
  startDate: string;
  minAvailableDate: DateTime;
  maxDaysFromAvailableDate?: number;
  region?: Region | undefined | null;
}) {
  if (!maxDaysFromAvailableDate) {
    // no limit specified so assume its valid
    return true;
  }

  const durationFromMinAvailableDate = Duration.fromObject({ days: maxDaysFromAvailableDate });

  const maxDurationAddedToMinDate = minAvailableDate
    .startOf('day')
    .plus(durationFromMinAvailableDate);

  const startDateToCompare = parseISO(startDate, region?.country ?? 'AU');

  return (
    startDateToCompare.startOf('day').toMillis() <
    maxDurationAddedToMinDate.startOf('day').toMillis()
  );
}

/**
 * Validates the start date input without the end date entered
 * @param startDate
 * @param region
 * @param minDateAvailable
 * @param maxDaysFromAvailableDate
 */
export function validateSingleStartDate({
  startDate,
  region,
  minDateAvailable,
  maxDaysFromAvailableDate,
}: {
  startDate: string;
  region: Region | undefined;
  maxDaysFromAvailableDate: number | undefined;
  minDateAvailable: DateTime;
}): { type: TRIP_DATES_ERROR_TYPE; message: string } | null {
  const isDateLessThanMinDate =
    parseISO(startDate, region?.country).startOf('day') < minDateAvailable.startOf('day');
  const isStartDateValueWithinLimit = isStartDateWithinLimit({
    startDate,
    region,
    maxDaysFromAvailableDate,
    minAvailableDate: minDateAvailable,
  });

  let errorType;
  let errorMessage;

  if (isDateLessThanMinDate) {
    errorType = TRIP_DATES_ERROR_TYPE.DATE_IN_PAST;
    errorMessage = 'Start date in the past';
  }

  if (!isStartDateValueWithinLimit) {
    errorType = TRIP_DATES_ERROR_TYPE.START_DATE_EXCEEDS_OFFSET;
    errorMessage = 'Start date is not within the allowed range.';
  }

  if (errorType && errorMessage) {
    return {
      type: errorType,
      message: errorMessage,
    };
  }

  return null;
}

type ValidateTripDatesInput = {
  endDate: string;
  startDate: string;
  region: Region | undefined;
  maxCalendarDays?: number;
  hasStartDateError?: boolean;
  minDateAvailable: DateTime;
};

/**
 * Validates the end date input
 * Is fired off on the onchange event
 * @param endDate
 * @param startDate
 * @param region
 * @param maxCalendarDays
 * @param hasStartDateError
 * @param minDateAvailable
 */
export function validateEndDateInput({
  endDate,
  startDate,
  region,
  maxCalendarDays,
  hasStartDateError,
  minDateAvailable,
}: ValidateTripDatesInput) {
  const currentDate = getRegionDateTime(region?.country);
  const endDateToDateObject = parseISO(endDate, region?.country);
  const isStartDateValidFormat = !validateDate(startDate);
  const isEndDateLessThanStartDate =
    endDateToDateObject.startOf('day') < parseISO(startDate, region?.country).startOf('day');
  const maxEndDateAvailable = currentDate.plus({
    days: maxCalendarDays,
  });
  const isDateLessThanMinDate =
    parseISO(endDate, region?.country).startOf('day') < minDateAvailable.startOf('day');
  const isSingleEndDateExceedingMaxDate =
    !startDate &&
    endDateToDateObject.startOf('day').toMillis() > maxEndDateAvailable.startOf('day').toMillis();

  /**
   * User has typed in a start date and end date that is less than the start date
   */
  if (isStartDateValidFormat && isEndDateLessThanStartDate) {
    return {
      type: TRIP_DATES_ERROR_TYPE.END_DATE_IN_PAST,
      message: 'End date in the past',
    };
  }

  /**
   * User has not typed a start date but end date exceeds max date
   */
  if (isSingleEndDateExceedingMaxDate) {
    return {
      type: TRIP_DATES_ERROR_TYPE.INVALID_TRIP_DURATION,
      message: 'Trip duration exceeds the maximum allowed duration.',
    };
  }

  /**
   * Start date already has an error
   * Could either be start date in the past or start date exceeds max date
   * So endDate will be incompatible with the start date
   */
  if (hasStartDateError) {
    return {
      type: TRIP_DATES_ERROR_TYPE.INVALID_TRIP_DURATION,
      message: 'Invalid date range',
    };
  }

  /**
   * End date is less than min date or in the past
   */
  if (isDateLessThanMinDate) {
    return {
      type: TRIP_DATES_ERROR_TYPE.DATE_IN_PAST,
      message: 'End date in the past',
    };
  }

  /**
   * User has entered start date and now the end date will exceed max trip duration
   */
  if (
    hasSelectedCalendarInputDatesExceededLimit({
      startDate: startDate ?? endDate ?? '',
      endDate,
      region,
      maxTripDurationInDays: getRegionSpecificTripDurationRule(),
    })
  ) {
    return {
      type: TRIP_DATES_ERROR_TYPE.INVALID_TRIP_DURATION,
      message: 'Trip duration exceeds the maximum allowed duration.',
    };
  }

  return null;
}

/**
 * Returns error messages ditto text ids based on the error type
 * @param errorType
 * @param maxTripDurationInMonths
 * @param startDateOffsetInMonths
 */
export function getCalendarErrorTextProps({
  errorType,
  maxTripDurationInMonths,
  startDateOffsetInMonths,
}: {
  errorType: Exclude<TRIP_DATES_ERROR_TYPE, TRIP_DATES_ERROR_TYPE.VALIDATE_DATES>;
  maxTripDurationInMonths: number;
  startDateOffsetInMonths: number;
}) {
  let months = 0;

  switch (errorType) {
    case TRIP_DATES_ERROR_TYPE.INVALID_TRIP_DURATION:
    case TRIP_DATES_ERROR_TYPE.START_DATE_EXCEEDS_OFFSET:
      months = maxTripDurationInMonths;
      break;
    case TRIP_DATES_ERROR_TYPE.DATE_IN_PAST:
      months = startDateOffsetInMonths;
      break;
  }

  return tripDatesErrorMessages(months)[errorType];
}

/**
 * Returns the active start date for the calendar
 * If the calendar is a double view and the end date is not in the same month as the start date
 * ie start date is 01/01/2020 and end date is 01/03/2020
 * the calendar will display feb and march months.
 * we need to have the active start date as the previous month
 * Otherwise the calendar will just show the end date month as a single view
 * @param startDate
 * @param endDate
 * @param isCalendarDoubleView
 * @param region
 */
export function getActiveCalendarStartDate({
  startDate,
  endDate,
  isCalendarDoubleView,
  region,
}: {
  startDate: string;
  endDate: string;
  isCalendarDoubleView: boolean;
  region: Region | undefined;
}) {
  const endDateAsDateTime = parseISO(endDate, region?.country ?? 'AU');
  const startDateAsDateTime = parseISO(startDate, region?.country ?? 'AU');
  const isEndDateInTheSameMonthAsStartDate = endDateAsDateTime.hasSame(
    startDateAsDateTime,
    'month',
  );
  let activeStartDate = endDateAsDateTime.toJSDate();
  if (isCalendarDoubleView && !isEndDateInTheSameMonthAsStartDate) {
    activeStartDate = endDateAsDateTime.toJSDate();
  }

  return activeStartDate;
}

type GetErrorTypeToDisplayArgs = {
  startDateErrorType?: TRIP_DATES_ERROR_TYPE;
  endDateErrorType?: TRIP_DATES_ERROR_TYPE;
  tripDatesErrorType?: TRIP_DATES_ERROR_TYPE;
  isTripDurationError?: boolean;
};

export function getErrorTypeToDisplay({
  startDateErrorType,
  endDateErrorType,
  isTripDurationError,
  tripDatesErrorType,
}: GetErrorTypeToDisplayArgs) {
  if (startDateErrorType) {
    return startDateErrorType;
  }

  if (tripDatesErrorType) {
    return tripDatesErrorType;
  }

  if (endDateErrorType) {
    return endDateErrorType;
  }

  if (isTripDurationError) {
    return TRIP_DATES_ERROR_TYPE.INVALID_TRIP_DURATION;
  }
  return null;
}

export function getErrorTextProps({
  startDateErrorType,
  tripDatesErrorType,
  endDateErrorType,
  maxTripDurationInMonths,
  startDateOffsetInMonths,
  isTripDurationError,
}: {
  maxTripDurationInMonths: number;
  startDateOffsetInMonths: number;
} & GetErrorTypeToDisplayArgs) {
  const errorType = getErrorTypeToDisplay({
    startDateErrorType,
    tripDatesErrorType,
    endDateErrorType,
    isTripDurationError,
  });

  if (!errorType) {
    return { headerTextProps: {}, descriptionTextProps: {} };
  }

  if (errorType === 'validateDates') {
    return {
      headerTextProps: { children: `Oops! You haven't chosen your trip dates.` },
      descriptionTextProps: {
        children: 'Please select trip dates below in order to get your quote.',
      },
    };
  }

  const { title, subtitle } = getCalendarErrorTextProps({
    errorType,
    maxTripDurationInMonths,
    startDateOffsetInMonths,
  });

  return { headerTextProps: { children: title }, descriptionTextProps: { children: subtitle } };
}
