import { useMemo } from 'react';

import { GetQuoteContainer, GetQuoteContainerProps } from '@components/getQuoteContainer';

import { Maintenance, MaintenanceProps } from '../../modals/maintenance/maintenance.component';
import { Timeout, TimeoutProps } from '../../modals/timeout/timeout.modal';

export const modalBodyComponents = {
  GetQuoteContainer,
  Maintenance,
  Timeout,
};

export type ComponentProps = {
  GetQuoteContainer: GetQuoteContainerProps;
  Maintenance: MaintenanceProps;
  Timeout: TimeoutProps;
};

export type ModalComponentName = keyof typeof modalBodyComponents;

export type ModalBodyProps<T extends ModalComponentName> = {
  type: T;
} & ComponentProps[T];

export function ModalBody<T extends ModalComponentName>(props: ModalBodyProps<T>) {
  const BodyComponent = useMemo(
    () => (modalBodyComponents?.[props?.type] as React.FC<ComponentProps[T]>) ?? null,
    [props?.type],
  );

  if (!BodyComponent) {
    return null;
  }

  return <BodyComponent {...(props as ComponentProps[T])} />;
}
