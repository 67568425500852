import {
  FontStyle,
  FontStyleVariants,
  FreelyTypographyBody,
  FreelyTypographyHeading,
} from '@types';

const defaultFont: FontStyle = {
  fontFamily: 'Golos Text',
};

const generateHeadingItem = (fontStyle: FontStyle): FontStyleVariants => ({
  semiBold: { ...defaultFont, ...fontStyle },
});

const generateDisplayFontStyleVariants = (fontStyle: FontStyle): FontStyleVariants => ({
  regular: {
    fontWeight: 400,
    letterSpacing: '0em',
    ...fontStyle,
    ...defaultFont,
  },
  medium: {
    ...defaultFont,
    fontWeight: 500,
    letterSpacing: '0em',
    ...fontStyle,
  },
  semiBold: {
    ...defaultFont,
    fontWeight: 600,
    letterSpacing: '0em',
    ...fontStyle,
  },
});

const headingItems: FreelyTypographyHeading = {
  'h0-80': generateHeadingItem({
    fontSize: 80,
    lineHeight: '88px',
    letterSpacing: '0em',
    fontWeight: 600,
  }),
  'h1-64': generateHeadingItem({
    fontSize: 64,
    lineHeight: '72px',
    letterSpacing: '0em',
    fontWeight: 600,
  }),
  'h2-52': generateHeadingItem({
    fontSize: 52,
    lineHeight: '60px',
    letterSpacing: '0em',
    fontWeight: 600,
  }),
  'h3-48': generateHeadingItem({
    fontSize: 48,
    lineHeight: '56px',
    letterSpacing: '0em',
    fontWeight: 600,
  }),
  'h4-40': generateHeadingItem({
    fontSize: 40,
    lineHeight: '48px',
    letterSpacing: '0em',
    fontWeight: 600,
  }),
  'h5-32': generateHeadingItem({
    fontSize: 32,
    lineHeight: '40px',
    letterSpacing: '0em',
    fontWeight: 600,
  }),
  'h5-34': generateHeadingItem({
    fontSize: 34,
    lineHeight: '40px',
    letterSpacing: '0em',
    fontWeight: 600,
  }),
  'h6-28': generateHeadingItem({
    fontSize: 28,
    lineHeight: '36px',
    letterSpacing: '0em',
    fontWeight: 600,
  }),
  'h7-26': generateHeadingItem({
    fontSize: 26,
    lineHeight: '32px',
    letterSpacing: '0em',
    fontWeight: 600,
  }),
};

const bodyItems: FreelyTypographyBody = {
  'title-24': generateDisplayFontStyleVariants({
    lineHeight: '28px',
    fontSize: 24,
  }),
  'title-28': generateDisplayFontStyleVariants({
    lineHeight: '34px',
    fontSize: 28,
  }),
  'sub-title-20': generateDisplayFontStyleVariants({
    lineHeight: '24px',
    fontSize: 20,
  }),
  'body-16': generateDisplayFontStyleVariants({
    lineHeight: '24px',
    fontSize: 16,
  }),
  'body-18': generateDisplayFontStyleVariants({
    lineHeight: '24px',
    fontSize: 18,
  }),
  'callout-16': generateDisplayFontStyleVariants({
    lineHeight: '20px',
    fontSize: 16,
  }),
  'sub-heading-14': generateDisplayFontStyleVariants({
    lineHeight: '20px',
    fontSize: 14,
  }),
  'footnote-12': generateDisplayFontStyleVariants({
    lineHeight: '16px',
    fontSize: 12,
  }),
  'caption-12': generateDisplayFontStyleVariants({ lineHeight: '20px', fontSize: 12 }),
  'reference-10': generateDisplayFontStyleVariants({
    lineHeight: '13px',
    fontSize: 10,
  }),
};

interface FreelyTypography extends FreelyTypographyHeading, FreelyTypographyBody {}

export const typography: FreelyTypography = {
  ...headingItems,
  ...bodyItems,
};
