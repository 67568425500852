import merge from 'lodash/merge';
import { DateTime, Duration } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import ReactCalendar from 'react-calendar';
import { twMerge } from 'tailwind-merge';

import { colors } from 'freely-shared-design';
import { getRegionSelector, regionDateUtils, useRegionStore } from 'freely-shared-stores';
import { CalendarData, MarkedDates, Region, RegionCode } from 'freely-shared-types';
import {
  getDateDifferenceInDays,
  getDurationOfDatesInDays,
  getHighlightedDates,
  getJSDate,
  getRegionDateTime,
  getRestrictedMarkDates,
  getUTCDate,
  getUTCDateFromJSDate,
  parseISO,
  validateDate,
} from 'freely-shared-utils';

import { Svg } from '@assets/svg';

export interface CalendarProps {
  onSelectDuration: (startDate: string, endDate: string) => void;
  currentDate?: string; // YYYY-MM-DD - initial visible date
  startDate?: string; // YYYY-MM-DD
  endDate?: string; // YYYY-MM-DD
  editMode?: boolean;
  minDate?: string | DateTime;
  maxDate?: string | DateTime;
  country: RegionCode;
  onValidate?: (data: CalendarData, startDate: string, endDate: string) => boolean;
  onValidateStartDate?: (dateString: string) => boolean;
  showDefaultDuration?: boolean;
  numberOfProtectionRestrictionDays?: number;
  highlightedDateRange?: { startDate: string; endDate: string };
  onRestrictionDayPressed?: () => void;
  mode?: 'DATE_RANGE' | 'DAY';
  showDoubleView?: boolean;
  isCalendarInputFocused?: boolean;
  activeStartDate?: Date;
  setActiveStartDate?: (date: Date) => void;
}

export const Calendar: React.FC<CalendarProps> = ({
  onSelectDuration,
  editMode,
  currentDate,
  startDate,
  endDate,
  minDate,
  maxDate,
  country,
  onValidate,
  showDefaultDuration,
  numberOfProtectionRestrictionDays = 0,
  highlightedDateRange,
  onValidateStartDate,
  onRestrictionDayPressed,
  mode = 'DATE_RANGE',
  showDoubleView = false,
  isCalendarInputFocused,
  activeStartDate,
  setActiveStartDate,
}) => {
  const region = useRegionStore(getRegionSelector);
  const [calendarActiveStartDate, setCalendarActiveStartDate] = useState(
    activeStartDate ?? new Date(),
  );
  const today = getRegionDateTime(region?.country);
  const todayDatetime = getRegionDateTime(region?.country);
  const restrictiveDays = useMemo(
    () =>
      getRestrictedMarkDates(
        numberOfProtectionRestrictionDays,
        country,
        'bg-cherry-10',
        'text-cherry-100',
      ),
    [country, numberOfProtectionRestrictionDays],
  );

  const pickSingleDate = useMemo(() => mode === 'DAY', [mode]);

  const highlightedDates = useMemo(() => {
    if (pickSingleDate && startDate) {
      return getHighlightedDates(startDate, startDate, region?.country, 'bg-mint-100');
    }
    if (highlightedDateRange?.startDate && highlightedDateRange.endDate) {
      return getHighlightedDates(
        highlightedDateRange.startDate,
        highlightedDateRange.endDate,
        region?.country,
        'bg-mint-10',
      );
    }
    return {};
  }, [
    highlightedDateRange?.endDate,
    highlightedDateRange?.startDate,
    region?.country,
    pickSingleDate,
    startDate,
  ]);

  const [duration, setDuration] = useState({
    markedDates: { ...restrictiveDays, ...highlightedDates } as MarkedDates,
    startDate: startDate || '',
    endDate: endDate || '',
    durationClosed: pickSingleDate,
  });

  const selectStartDate = (dateString: string) => {
    if (onValidateStartDate && onValidateStartDate(dateString) === false) {
      return;
    }

    const markedDates = pickSingleDate ? {} : ({ ...highlightedDates } as MarkedDates);
    markedDates[dateString] = {
      startingDay: true,
      color: 'bg-nusa-200',
      endingDay: pickSingleDate,
    };

    const startDateStr = dateString;
    const endDateStr = dateString;

    setDuration({
      markedDates: { ...restrictiveDays, ...markedDates },
      startDate: dateString,
      endDate: endDateStr,
      durationClosed: pickSingleDate,
    });

    onSelectDuration(startDateStr, endDateStr);
  };

  const selectEndDate = (dateString: string) => {
    let dateToIncrement = parseISO(duration.startDate, region?.country);
    const endDate = parseISO(dateString, region?.country);

    const markedDates = {
      [getUTCDate(dateToIncrement)]: {
        startingDay: true,
        color: 'bg-nusa-200',
      },
    } as MarkedDates;

    const oneDayDuration = Duration.fromObject({ days: 1 });

    while (dateToIncrement < endDate) {
      merge(markedDates, {
        [getUTCDate(dateToIncrement)]: { color: 'bg-nusa-200' },
      });
      dateToIncrement = dateToIncrement.plus(oneDayDuration);
    }

    merge(markedDates, {
      [getUTCDate(dateToIncrement)]: {
        endingDay: true,
        color: 'bg-nusa-200',
      },
    });

    setDuration(prevState => ({
      markedDates: { ...restrictiveDays, ...highlightedDates, ...markedDates },
      startDate: prevState.startDate,
      endDate: dateString,
      durationClosed: true,
    }));
    onSelectDuration(duration.startDate, dateString);
  };

  const handleDayPress = (date: Date, e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const dateString = getUTCDateFromJSDate(date, country);
    if (
      onRestrictionDayPressed &&
      Object.keys(restrictiveDays).includes(dateString) &&
      numberOfProtectionRestrictionDays > 0
    ) {
      onRestrictionDayPressed();
      return;
    }

    if (startDate && endDate) {
      const startDateDateObject = parseISO(startDate, region?.country).startOf('day');
      const endDateDateTime = parseISO(endDate, region?.country).startOf('day');
      const isStartInthePast =
        startDateDateObject.toMillis() < todayDatetime.startOf('day').toMillis();
      const isEndInthePast = endDateDateTime.toMillis() < todayDatetime.startOf('day').toMillis();

      if (isStartInthePast || isEndInthePast) {
        selectStartDate(dateString);
        return;
      }
    }

    if (
      duration.durationClosed &&
      !(editMode && startDate && regionDateUtils().isInPast(startDate))
    ) {
      selectStartDate(dateString);
      return;
    }

    if (pickSingleDate) {
      selectStartDate(dateString);
      return;
    }

    if (!duration.startDate) {
      selectStartDate(dateString);
    } else {
      const range = getDateDifferenceInDays(duration.startDate, dateString, region?.country);
      const durationInDays = getDurationOfDatesInDays(
        duration.startDate,
        dateString,
        region?.country,
      );

      if (range < 0) {
        const startDate = dateString;
        const endDate = duration.startDate;
        const highlightedDates = getHighlightedDates(
          startDate,
          endDate,
          region?.country,
          'bg-nusa-200',
        );
        setDuration(p => ({
          ...p,
          markedDates: { ...p.markedDates, ...highlightedDates },
          durationClosed: true,
          startDate: startDate,
          endDate: endDate,
        }));
        onSelectDuration(startDate, endDate);
        return;
      }
      if (
        typeof onValidate === 'function' &&
        onValidate({ durationInDays }, duration.startDate, duration.endDate) === false
      ) {
        return;
      }
      if (range >= 0) {
        selectEndDate(dateString);
      }
    }
  };

  useEffect(() => {
    if (isCalendarInputFocused) {
      return;
    }
    const hasSelectedDates = startDate || endDate;

    if (
      (editMode || showDefaultDuration || isCalendarInputFocused) &&
      hasSelectedDates &&
      !pickSingleDate
    ) {
      const _startDate = startDate ?? '';
      const _endDate = endDate ?? '';
      setDuration({
        markedDates: {
          ...restrictiveDays,
          ...highlightedDates,
          ...constructMarkedDates({ startDate: _startDate, endDate: _endDate, region }),
        },
        startDate: _startDate,
        endDate: _endDate,
        durationClosed: !!startDate && !!endDate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isCalendarInputFocused) {
      return;
    }

    const updatedStartDate = startDate ?? '';
    const updatedEndDate = endDate ?? '';

    const isStartDateValidDateOrEmpty = updatedStartDate === '' || !validateDate(updatedStartDate);
    const isEndDateValidDateOrEmpty = updatedEndDate === '' || !validateDate(updatedEndDate);

    if (isStartDateValidDateOrEmpty || isEndDateValidDateOrEmpty) {
      setDuration({
        markedDates: {
          ...restrictiveDays,
          ...highlightedDates,
          ...constructMarkedDates({ startDate: updatedStartDate, endDate: updatedEndDate, region }),
        },
        startDate: updatedStartDate,
        endDate: updatedEndDate,
        durationClosed: !validateDate(updatedEndDate) && !validateDate(updatedStartDate),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, isCalendarInputFocused]);

  let defaultActiveStartDate = getJSDate(getRegionDateTime(region?.country), region?.country);
  if (minDate) {
    defaultActiveStartDate = getJSDate(minDate, region?.country);
  }

  if (startDate) {
    defaultActiveStartDate = getJSDate(startDate, region?.country);
  }

  useEffect(() => {
    if (activeStartDate) {
      setCalendarActiveStartDate(activeStartDate);
    }
  }, [activeStartDate]);

  useEffect(() => {
    if (!showDoubleView && endDate) {
      setActiveStartDate?.(parseISO(endDate, region?.country).toJSDate());
    }
  }, [showDoubleView, endDate, setActiveStartDate, region?.country]);

  return (
    <ReactCalendar
      calendarType="US" // first day of the week to Saturday
      className="w-full"
      defaultActiveStartDate={
        currentDate ? getJSDate(currentDate, region?.country) : defaultActiveStartDate
      }
      minDate={minDate ? getJSDate(minDate, region?.country) : undefined}
      maxDate={maxDate ? getJSDate(maxDate, region?.country) : undefined}
      showNeighboringMonth={false}
      onClickDay={handleDayPress}
      tileClassName={({ date }) => {
        const baseStyle = 'text-lg p-2 font-text-regular mt-[0.500rem]';
        const isToday = getUTCDateFromJSDate(date, region?.country) === getUTCDate(today);
        const markedDate = duration.markedDates[getUTCDateFromJSDate(date, region?.country)];
        if (markedDate) {
          return twMerge(
            baseStyle,
            markedDate.color,
            markedDate.textColor,
            markedDate.startingDay && 'rounded-l-full',
            markedDate.endingDay && 'rounded-r-full',
          );
        }
        if (isToday) {
          return twMerge(baseStyle, 'bg-snow', 'rounded-full');
        }

        return twMerge(baseStyle);
      }}
      prevLabel={<Svg.ArrowLeft fill={colors.black} />}
      nextLabel={<Svg.ArrowRight fill={colors.black} />}
      prev2Label={null}
      next2Label={null}
      showDoubleView={showDoubleView}
      activeStartDate={calendarActiveStartDate}
      onActiveStartDateChange={data => {
        if (data.action !== 'onChange' && data.activeStartDate) {
          setCalendarActiveStartDate(data?.activeStartDate);
          setActiveStartDate?.(data?.activeStartDate);
        }
      }}
      onClickMonth={(_: Date, event) => {
        event.stopPropagation();
      }}
      onClickYear={(_: Date, event) => {
        event.stopPropagation();
      }}
      onClickDecade={(_: Date, event) => {
        event.stopPropagation();
      }}
    />
  );
};

function constructMarkedDates({
  startDate,
  endDate,
  region,
}: {
  startDate: string;
  endDate: string;
  region: Region | undefined;
}) {
  const markedDates = {} as MarkedDates;

  if (startDate === endDate) {
    markedDates[startDate] = { startingDay: true, endingDay: true, color: 'bg-nusa-200' };
    return markedDates;
  }

  markedDates[startDate] = { startingDay: true, color: 'bg-nusa-200' };
  const range = getDateDifferenceInDays(startDate, endDate, region?.country);

  for (let i = 1; i <= range; i++) {
    const dayDuration = Duration.fromObject({ days: i });
    const date = parseISO(startDate, region?.country).plus(dayDuration);
    const dayToIncrement = getUTCDate(date);
    if (i < range) {
      markedDates[dayToIncrement] = { color: 'bg-nusa-200' };
    } else {
      markedDates[dayToIncrement] = { endingDay: true, color: 'bg-nusa-200' };
    }
  }
  return markedDates;
}
