import { twMerge } from 'tailwind-merge';

import { colors } from 'freely-shared-design';

import { Svg } from '@assets/svg';
import { Text, TextProps } from '@components/text';

export type NotificationVariantTypes = 'base' | 'info' | 'error' | 'alert' | 'warning' | 'discount';
export interface NotificationProps {
  variant?: NotificationVariantTypes;
  headingProps: TextProps;
  descriptionProps?: TextProps;
  className?: string;
}
const NotificationVariants: Record<
  NotificationVariantTypes,
  { backgroundColor: string; Icon: React.FC<React.SVGProps<SVGElement>>; fill: string }
> = {
  base: {
    backgroundColor: 'bg-snow',
    Icon: Svg.InfoCircleBlue,
    fill: `${colors.nusa[200]}`,
  },
  info: {
    backgroundColor: 'bg-nusa-50',
    Icon: Svg.InfoCircleBlue,
    fill: `${colors.nusa[200]}`,
  },
  error: {
    backgroundColor: 'bg-cherry-10',
    Icon: Svg.CloseCircle,
    fill: `${colors.cherry[10]}`,
  },
  alert: {
    backgroundColor: 'bg-cherry-10',
    Icon: Svg.Alert,
    fill: `${colors.cherry[100]}`,
  },
  warning: {
    backgroundColor: 'bg-sunset-10',
    Icon: Svg.Alert,
    fill: `${colors.sunset[100]}`,
  },
  discount: {
    backgroundColor: 'bg-nusa-50',
    Icon: Svg.Redeem,
    fill: `${colors.nusa[200]}`,
  },
};
export const Notification = ({
  headingProps,
  descriptionProps,
  variant = 'base',
  className,
}: NotificationProps) => {
  const { Icon, fill, backgroundColor } = NotificationVariants[variant];
  return (
    <div className={twMerge('flex rounded-xl p-3', backgroundColor, className)}>
      <Icon className="h-4 w-4 mr-2 mt-0.5" fill={fill} />
      <div>
        <Text
          variant="callout-16/medium"
          className="mb-2 text-left text-fuji-800"
          {...headingProps}
        />
        {descriptionProps && (
          <Text
            className="text-left text-fuji-800"
            variant="sub-heading-14/regular"
            {...descriptionProps}
          />
        )}
      </div>
    </div>
  );
};
