import { openApiInstances } from 'freely-shared-open-api';
import {
  CreateStore,
  applyAutoDiscount,
  createStore,
  shouldApplyGroupDiscount,
  shouldRemoveGroupDiscount,
} from 'freely-shared-stores';
import { CreateQuoteRequest, CreateQuoteResponse, GetQuoteForm } from 'freely-shared-types';
import { createNewGetQuoteForm } from 'freely-shared-utils';

import { useFeatureFlagsStore } from '@store/featureFlags';
import { growsurfApi } from '@utils/growsurf.ts';
import { constructQuoteRequest } from '@utils/quote.ts';

type AxiosCreateQuoteResponse = {
  data: CreateQuoteResponse;
};

export interface QuoteState {
  quote: CreateQuoteRequest | undefined;
  pending: boolean;
  error: Error | undefined;
}

export interface QuoteActions {
  syncQuoteWithForm: (getQuoteForm: GetQuoteForm) => void;
  createQuote: (getQuoteForm?: GetQuoteForm) => Promise<AxiosCreateQuoteResponse>;
  clearQuote: () => void;
}

export type QuoteStore = QuoteState & QuoteActions;
const initialState: QuoteState = {
  quote: undefined,
  pending: false,
  error: undefined,
};

export const quoteStore: CreateStore<QuoteStore> = (set, getState) => ({
  ...initialState,
  syncQuoteWithForm: getQuoteForm => {
    set(state => {
      state.quote = { ...state.quote, ...constructQuoteRequest(getQuoteForm) };
    });
  },
  createQuote: async getQuoteForm => {
    const referralData = await growsurfApi.getReferralData();
    const referralParticipantId = growsurfApi.getReferrerId();
    const growsurfCampaignId = await growsurfApi.getCampaignId();

    const isReferralEnabled = !!useFeatureFlagsStore.getState().featureFlags?.referral?.isEnabled;

    set(state => {
      if (getQuoteForm) {
        state.quote = {
          ...constructQuoteRequest(getQuoteForm),
        };
        if (isReferralEnabled) {
          state.quote.referralParticipantId = referralParticipantId;
          state.quote.referralCampaignId = growsurfCampaignId;
        }
      }
      const autoDiscountCode = useFeatureFlagsStore.getState().getAutoDiscount({
        secondaryTravellers: state?.quote?.secondaryTravellers ?? [],
        appliedDiscountCode: state?.quote?.promotionCode?.discountCode ?? '',
        destinations: state?.quote?.destinations ?? [],
        referralData,
      });

      if (autoDiscountCode === null || shouldRemoveGroupDiscount(autoDiscountCode)) {
        delete state.quote?.promotionCode;
      } else if (shouldApplyGroupDiscount(autoDiscountCode) && state.quote) {
        state.quote = applyAutoDiscount(autoDiscountCode, state.quote);
      }
    });

    const quoteData = getState().quote;
    return (await openApiInstances.Quote.post('/quotes', quoteData)) as AxiosCreateQuoteResponse;
  },
  clearQuote: () => {
    set(state => {
      state.quote = constructQuoteRequest(createNewGetQuoteForm());
      state.pending = false;
      state.error = undefined;
    });
  },
});

export const useQuoteStore = createStore(quoteStore, {
  name: 'quoteStore',
  persistType: 'localStorage',
});
