import kebabCase from 'lodash/kebabCase';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

import { colors } from 'freely-shared-design';
import { getRegionSelector, useRegionStore } from 'freely-shared-stores';
import { TripDatesForm } from 'freely-shared-types';
import { utcToDateFormat } from 'freely-shared-utils';

import { Svg } from '@assets/svg';
import { Text } from '@components/text';
import { i18n } from '@i18n/i18n';
import { useQuoteStore } from '@store/quote';
import { testProps } from '@utils/testProps';

export interface SelectTravelDatesSectionProps extends React.HTMLAttributes<HTMLDivElement> {
  onFocus: () => void;
  isFocused: boolean;
}
export const SelectTravelDatesSection = ({ isFocused, onFocus }: SelectTravelDatesSectionProps) => {
  const region = useRegionStore(getRegionSelector);
  const { watch } = useFormContext<TripDatesForm>();
  const { t } = useTranslation();
  const startDate = useQuoteStore(state => state.quote?.startDate ?? '');
  const endDate = useQuoteStore(state => state.quote?.endDate ?? '');

  const tripDates = watch('tripDates');
  const hasDatesSelected = startDate && endDate !== '';

  const endDateValue = utcToDateFormat(tripDates.endDate, region?.country);
  const startDateValue = utcToDateFormat(tripDates.startDate, region?.country);

  const renderIcon = (
    <div className={twMerge('items-center justify-center lg:px-2', isFocused && 'hidden lg:flex')}>
      <Svg.CalendarOutline height={28} width={28} fill={colors.black} />
    </div>
  );

  const renderMobileAndTabletTitle = (
    <div className={twMerge('hidden', isFocused && 'flex  lg:hidden')}>
      <div className="mr-2 mb-2">
        <Svg.CalendarOutline height={25} width={25} fill={colors.black} />
      </div>
      <Text variant="body-16/medium" className={twMerge('lg:hidden mt-1 ml-2')}>
        {t('when.md.subtitle')}
      </Text>
    </div>
  );

  return (
    <div
      className={twMerge(
        'flex items-center cursor-pointer lg:cursor-pointer',
        isFocused && 'cursor-auto',
      )}>
      {renderIcon}
      <div onClick={onFocus} className={twMerge('w-full')}>
        <div
          onClick={onFocus}
          className={twMerge('flex flex-row lg:hidden', isFocused && 'hidden')}>
          <Text variant="callout-16/medium" className={twMerge('ml-2 w-1/4')}>
            When?
          </Text>
          <div className={twMerge('w-3/4')}>
            <Text
              variant="sub-heading-14/regular"
              className={twMerge('text-right text-steal', hasDatesSelected && 'text-charcoal')}>
              {hasDatesSelected
                ? `${utcToDateFormat(startDate)} to ${utcToDateFormat(endDate)}`
                : 'Add dates'}
            </Text>
          </div>
        </div>
        {renderMobileAndTabletTitle}
        <div className={twMerge('space-x-7 mt-2 hidden lg:flex', isFocused && 'flex')}>
          <div className="flex-1 flex-col border-r">
            <Text variant="sub-heading-14/500" className="ml-2">
              {t('when.calendar.departureLabel')}
            </Text>
            <TravelDateValue date={startDateValue} />
          </div>
          <div className="flex-1 flex-col">
            <Text variant="sub-heading-14/500" className="ml-2">
              {i18n.t('when.calendar.returnLabel')}
            </Text>
            <TravelDateValue date={endDateValue} />
          </div>
        </div>
      </div>
    </div>
  );
};

function TravelDateValue({ date }: { date?: string }) {
  return (
    <div {...testProps(`${kebabCase('Add date')}`)} className="py-2 ml-2 mt-1">
      <Text className={twMerge(!date && 'text-steal')} variant="callout-16/regular">
        {date ?? 'Add date'}
      </Text>
    </div>
  );
}
