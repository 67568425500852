import { Controller, useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { colors } from 'freely-shared-design';
import { TravellersForm } from 'freely-shared-types';
import { validateResidencyCheckBox } from 'freely-shared-utils';

import { Svg } from '@assets/svg';
import { SectionChildrenWrapper } from '@components/sectionChildrenWrapper';
import { i18n } from '@i18n/i18n';
import { useSectionStore } from '@store/section';

import { Checkbox } from '../checkbox';
import { Text } from '../text';

export const ResidentStatus = () => {
  const {
    setValue,
    watch,
    control,
    trigger,
    formState: { errors },
  } = useFormContext<TravellersForm>();
  const formPrimaryTravellerIsResident = watch('primaryTravellerIsResident');
  const formSecondaryTravellers = watch('secondaryTravellers') ?? [];
  const hasError = !!errors?.primaryTravellerIsResident;
  const setIsValidatingFromGetQuote = useSectionStore(state => state.setSectionIsValidating);

  const renderError =
    hasError && errors?.primaryTravellerIsResident?.message ? (
      <div className={twMerge('flex items-center h-9 mt-2')}>
        <Svg.Alert fill={colors.cherry[100]} width={18} />
        <Text variant="sub-heading-14/400" className="ml-[0.5rem] text-cherry-100">
          Required: Please check the box to confirm
        </Text>
      </div>
    ) : null;

  return (
    <SectionChildrenWrapper
      className={twMerge([
        'py-4 lg:px-4 w-full rounded-[1rem]',
        formSecondaryTravellers.length > 2 &&
          'lg:shadow-[-1px_-6px_9px_-4px_rgba(196,185,185,0.25)]',
      ])}>
      <div className="flex w-full flex-row py-4 bg-snow rounded-[1rem] items-center">
        <Controller
          rules={{
            validate: {
              validateResidency: v =>
                validateResidencyCheckBox({
                  formPrimaryTravellerIsResident: v,
                  formSecondaryTravellers: formSecondaryTravellers,
                }),
            },
          }}
          render={({ field: { onChange } }) => {
            return (
              <Checkbox
                hasError={hasError}
                onClick={() => {
                  onChange(!formPrimaryTravellerIsResident);
                  formSecondaryTravellers.forEach((_, index) => {
                    const isResidentInputId = `secondaryTravellers.${index}.isResident` as any;
                    setValue(isResidentInputId, !formPrimaryTravellerIsResident);
                    setIsValidatingFromGetQuote('travellers', false);
                  });
                  trigger();
                }}
                isChecked={formPrimaryTravellerIsResident}
              />
            );
          }}
          name="primaryTravellerIsResident"
          control={control}
        />
        <Text variant="sub-heading-14/400" className="px-2">
          {i18n.t('who.checkbox.title')}
        </Text>
      </div>
      {renderError}
    </SectionChildrenWrapper>
  );
};
