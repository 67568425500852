import { getQuoteDestinations } from 'freely-shared-open-api';
import { getDestinationsByCategory, logToSentry } from 'freely-shared-utils';

import { getRegionState } from '../region/region.util';
import { DestinationsStore } from './destinations.store';
import { getSortedDestinations } from './destinations.utils';

const TAG = 'shared/stores/destinations.api';
export const fetchTripDestinations = async (get: () => DestinationsStore) => {
  const { setDestinations, setPending, setError } = get();
  const region = getRegionState();
  if (!region) {
    return;
  }
  try {
    setPending(true);
    const { data: destinations } = await getQuoteDestinations();
    setDestinations(destinations?.destinations);
    return destinations;
  } catch (error) {
    logToSentry(error as Error, { tag: TAG });
    setError(error as Error);
    return;
  } finally {
    setPending(false);
  }
};

/**
 * Todo: refactor setWidgetDestinations to setDestinations in widget
 *
 * @param get
 */
export const fetchQuoteDestinations = async (get: () => DestinationsStore) => {
  const { setPending, setError, setDestinations, setTrendingDestinations } = get();
  try {
    setPending(true);
    const { data: destinations } = await getQuoteDestinations();
    const { trendingDestinations } = getDestinationsByCategory(destinations?.destinations ?? []);
    const sortedDestinations = getSortedDestinations(destinations?.destinations ?? []);
    setTrendingDestinations(trendingDestinations);
    setDestinations(sortedDestinations);
    return destinations?.destinations;
  } catch (error) {
    logToSentry(error as Error, { tag: TAG });
    setError(error as Error);
    return;
  } finally {
    setPending(false);
  }
};
