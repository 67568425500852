import kebabCase from 'lodash/kebabCase';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { GetQuoteForm } from 'freely-shared-types';

import { Button } from '@components/button';
import { Header } from '@components/getQuoteHeader/getQuoteHeader.component';
import { OutageMessage } from '@components/outageMessage';
import { Text } from '@components/text';
import { useGetAQuote } from '@hooks/useGetAQuote.tsx';
import { useSyncQuoteStoreWithForm } from '@hooks/useSyncQuoteStoreWithForm.ts';
import { i18n } from '@i18n/i18n';
import { Destinations } from '@sections/destinations';
import { TravelDates } from '@sections/travelDates';
import { Travellers } from '@sections/travellers';
import { useGlobalStore } from '@store/global';
import { useSectionStore } from '@store/section';
import { testProps } from '@utils/testProps';

export type GetQuoteContainerProps = {
  children?: ReactNode;
};

export const GetQuoteContainer = () => {
  const syncQuoteStore = useSyncQuoteStoreWithForm();
  const configChanged = useGlobalStore(state => state.configChanged);
  const destinationPortalRef = useRef<HTMLDivElement>(null);
  const travelDatesPortalRef = useRef<HTMLDivElement>(null);
  const travellerPortalRef = useRef<HTMLDivElement>(null);
  const { handleSubmit, formState } = useFormContext<GetQuoteForm>();
  const closeAllSections = useSectionStore(state => state.closeAllSections);
  useEffect(() => {
    syncQuoteStore();
  }, [syncQuoteStore]);

  if (configChanged) {
    closeAllSections();
  }
  const { onSubmit, onError } = useGetAQuote();

  const [messageIndex, setMessageIndex] = useState(0);

  const messages = i18n.t('global.loadingMessages', {
    returnObjects: true,
  }) as string[];

  useEffect(() => {
    if (formState.isSubmitting && formState.isValid) {
      const interval = setInterval(() => {
        setMessageIndex(index => (index + 1) % messages.length);
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [formState, formState.isSubmitting, formState.isValid, messages.length]);

  return (
    <div>
      <Header />
      <div
        className={twMerge(
          'bg-white flex-col lg:w-full ml-auto flex items-center relative max-w-screen-lg 2xl:max-w-screen-2xl mx-auto mb-24 lg:mb-0',
        )}>
        <div className="w-full lg:mb-16 px-4 lg:px-0">
          {configChanged && <OutageMessage />}
          <div
            className={twMerge(
              'w-full relative lg:flex lg:flex-row lg:space-x-6',
              configChanged &&
                'after:content-[""] after:w-full after:absolute after:h-full after:opacity-60 after:bg-white after:top-0',
            )}>
            <Destinations destinationPortalRef={destinationPortalRef} />
            <TravelDates travelDatesPortalRef={travelDatesPortalRef} />
            <Travellers travellerPortalRef={travellerPortalRef} />
          </div>
          <div ref={destinationPortalRef}></div>
          <div ref={travelDatesPortalRef}></div>
          <div ref={travellerPortalRef}></div>

          {formState.isSubmitting && formState.isValid && (
            <Text
              className={twMerge(
                'relative lg:absolute lg:left-[50%] lg:-translate-x-1/2 lg:bottom-10 lg:translate-y-1/2 my-4 lg:my-2 text-center',
              )}
              variant="callout-16/500">
              {messages[messageIndex]}
            </Text>
          )}
        </div>

        <Button
          {...testProps(`${kebabCase('Get a Quote')}`)}
          isStoppingPropagation
          onClick={async () => {
            syncQuoteStore();
            await handleSubmit(onSubmit, onError)();
          }}
          variant="fuji"
          fontVariant="callout-16/regular"
          withFixedWith
          disabled={configChanged}
          className={twMerge(
            'relative lg:absolute lg:left-[50%] lg:-translate-x-1/2 lg:bottom-0 lg:translate-y-1/2 mb-6 lg:mb-0',
            configChanged && 'bg-snow [&>p]:text-steal [&>p]:opacity-60',
          )}>
          {i18n.t('global.container.actions.getQuote')}
        </Button>
      </div>
    </div>
  );
};
