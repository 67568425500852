import { useEffect, useState } from 'react';

import { openApiInstances } from 'freely-shared-open-api';
import { getMaintenanceStatus, useConfigStore, useRegionStore } from 'freely-shared-stores';
import { logToSentry } from 'freely-shared-utils';

import { useFeatureFlagsStore } from '@store/featureFlags';
import { getAnonymousId, sendAnalyticsEvent } from '@utils/analytics';
import { getUserIpAddress } from '@utils/getUserIpAddress';

export const useWidgetInit = () => {
  const getConfig = useConfigStore(state => state?.getConfig);
  const isUnderMaintenance = useConfigStore(getMaintenanceStatus);
  const [isInitialised, setIsInitialised] = useState(false);
  const setRegion = useRegionStore(state => state?.setRegion);
  const urlRegion = window.location.pathname;
  const initFeatureFlags = useFeatureFlagsStore(state => state.initFeatureFlags);

  useEffect(() => {
    (async () => {
      const config = await getConfig();
      const QuoteApiConfig = config?.web?.OPEN_APIS?.endpoints?.find(it => it.name === 'Quote');
      const isUS = urlRegion?.includes('/us');

      if (isUS) {
        setRegion({ country: 'US' });
        if (QuoteApiConfig) {
          openApiInstances.Quote.defaults.baseURL = QuoteApiConfig.endpoint + '/us';
          openApiInstances.Quote.defaults.headers.common['x-api-key'] =
            import.meta.env.VITE_US_OPEN_API_KEY;
        }
      } else {
        setRegion({ country: 'AU' });
        if (QuoteApiConfig) {
          openApiInstances.Quote.defaults.baseURL = QuoteApiConfig.endpoint + '/au';
          openApiInstances.Quote.defaults.headers.common['x-api-key'] =
            import.meta.env.VITE_AU_OPEN_API_KEY;
        }
      }
      openApiInstances.Quote.defaults.headers.common['x-platform'] = 'qw';
      openApiInstances.Quote.defaults.headers.common.platform = 'qw';
      const anonymousId = await getAnonymousId();
      if (anonymousId) {
        const featureFlagResponse = await initFeatureFlags({
          platform: 'qw',
          region: isUS ? 'US' : 'AU',
          anonymousId: anonymousId,
          apiKey: import.meta.env.VITE_POSTHOG_API_KEY,
          apiURL: import.meta.env.VITE_POSTHOG_API_HOST,
        }).catch(e => logToSentry(e, { tag: 'initFeatureFlags' }));
        sendAnalyticsEvent('Received Feature Flag', {
          flagName: 'auto-discount',
          variationValue: featureFlagResponse?.['auto-discount']?.value,
        });
      }
      getUserIpAddress();
      setIsInitialised(true);
    })();
  }, [getConfig, initFeatureFlags, setRegion, urlRegion]);

  return { isInitialised, isUnderMaintenance };
};
