import {
  getRegionSelector,
  minDateOffsetDaysSelector,
  useConfigStore,
  useRegionStore,
} from 'freely-shared-stores';
import { getRegionDateTime } from 'freely-shared-utils';

export function useGetMinAvailableCalendarDate() {
  const region = useRegionStore(getRegionSelector);
  const currentDate = getRegionDateTime(region?.country);
  const minDateOffsetDays = useConfigStore(minDateOffsetDaysSelector);
  return currentDate.plus({ days: minDateOffsetDays });
}
