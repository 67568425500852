import { getRegionSelector, useRegionStore } from 'freely-shared-stores';
import { getRegionDateTime, parseISO } from 'freely-shared-utils';

export function useHasDatesExpired() {
  const region = useRegionStore(getRegionSelector);
  const currentDate = getRegionDateTime(region?.country).startOf('day');

  /**
   * Checks if the date has expired. It is region specific
   * @param isoDateString
   */
  function checkHasDateExpired(isoDateString: string) {
    const date = parseISO(isoDateString, region?.country).startOf('day');
    return date.toMillis() < currentDate.toMillis();
  }

  /**
   * Returns the starting quick select dates, if the dates have expired, then the quick select dates will be empty
   * @param startDate
   * @param endDate
   */
  function getStartingQuickSelectDates({
    startDate,
    endDate,
  }: {
    startDate?: string;
    endDate?: string;
  }) {
    const quickSelectStartDate = !startDate || checkHasDateExpired(startDate) ? '' : startDate;
    const quickSelectEndDate = !endDate || checkHasDateExpired(endDate) ? '' : endDate;

    return [quickSelectStartDate, quickSelectEndDate];
  }

  return {
    checkHasDateExpired,
    getStartingQuickSelectDates,
  };
}
