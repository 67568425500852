import { CreateStore, createStore } from 'freely-shared-stores';

export interface GlobalState {
  configChanged: boolean;
  shouldDisplayHeaderShadow?: boolean;
}

export interface GlobalActions {
  setConfigChanged: (val: boolean) => void;
  setShouldDisplayHeaderShadow: (val: boolean) => void;
}

export type GlobalStore = GlobalState & GlobalActions;

const initialState: GlobalState = {
  configChanged: false,
};

export const globalStore: CreateStore<GlobalStore> = set => ({
  ...initialState,
  setConfigChanged: (val: boolean) => {
    set(state => {
      state.configChanged = val;
    });
  },
  setShouldDisplayHeaderShadow: val => {
    set(state => {
      state.shouldDisplayHeaderShadow = val;
    });
  },
});

export const useGlobalStore = createStore(globalStore, {
  name: 'globalStore',
});
