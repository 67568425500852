import kebabCase from 'lodash/kebabCase';
import { useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { colors } from 'freely-shared-design';
import { TravellersForm } from 'freely-shared-types';

import { Svg } from '@assets/svg';
import { Text } from '@components/text';
import { i18n } from '@i18n/i18n';
import { testProps } from '@utils/testProps';
import { getNumberOfValidTravellers } from '@utils/travellers.ts';

export interface TravellerSectionProps extends React.HTMLAttributes<HTMLDivElement> {
  onFocus: () => void;
  isFocused: boolean;
}
export const TravellerSection = ({ isFocused, onFocus }: TravellerSectionProps) => {
  const { watch } = useFormContext<TravellersForm>();
  const primaryTraveller = watch('primaryTravellerDOB') ?? '';
  const travellersNo = getNumberOfValidTravellers(primaryTraveller, watch('secondaryTravellers'));

  const renderIcon = (
    <div
      {...testProps(`${kebabCase('add travelers')}`)}
      className={twMerge('items-center flex justify-center lg:px-2')}>
      <Svg.ProfileOutline height={30} width={30} fill={colors.black} />
    </div>
  );

  const renderMobileAndTabletTitle = (
    <div className={twMerge('hidden lg:block', isFocused && 'block')}>
      <Text variant="body-16/medium" className={twMerge('lg:hidden')}>
        {i18n.t('who.title')}
      </Text>

      <div className="hidden lg:block mb-2">
        <Text variant="sub-heading-14/medium" className={twMerge(`lg:block`)}>
          {i18n.t('who.lg.title')}
        </Text>
      </div>
      <div className="hidden md:block lg:hidden">
        <Text variant="body-16/medium" className={twMerge(`hidden`)}>
          {i18n.t('who.title')}
        </Text>
      </div>
      <div className="sm:block md:hidden">
        <Text variant="body-16/medium" className={twMerge(`hidden`)}>
          {i18n.t('who.title')}
        </Text>
      </div>
      {travellersNo ? (
        <Text
          variant="callout-16/400"
          className={twMerge('w-full text-charcoal hidden lg:block')}
          onClick={onFocus}>
          {i18n.t('who.additionTravellers.subTitle', { count: travellersNo })}
        </Text>
      ) : (
        <Text
          variant="callout-16/400"
          className={twMerge('w-full text-steal hidden lg:block')}
          onClick={onFocus}>
          {i18n.t('who.subTitle')}
        </Text>
      )}
    </div>
  );

  return (
    <div
      className={twMerge(
        'flex items-center cursor-pointer lg:cursor-pointer',
        isFocused && 'cursor-auto',
      )}
      onClick={onFocus}>
      {renderIcon}
      <div className={twMerge('w-full ml-2')}>
        <div className={twMerge('flex flex-row lg:hidden', isFocused && 'hidden')}>
          <Text
            variant={{
              sm: 'body-16/medium',
              md: 'body-16/medium',
              lg: 'sub-heading-14/medium',
            }}
            className="w-1/4">
            {i18n.t('who.title')}
          </Text>
          {travellersNo ? (
            <Text
              variant="sub-heading-14/400"
              className={twMerge('w-full text-charcoal text-right')}
              onClick={onFocus}>
              {i18n.t('who.additionTravellers.subTitle', { count: travellersNo })}
            </Text>
          ) : (
            <Text
              variant="sub-heading-14/400"
              className={twMerge('w-full text-steal text-right')}
              onClick={onFocus}>
              {i18n.t('who.subTitle')}
            </Text>
          )}
        </div>
        {renderMobileAndTabletTitle}
      </div>
    </div>
  );
};
