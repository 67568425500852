import React from 'react';
import { createRoot } from 'react-dom/client';

import { Debug } from '@components/debug';
import { GetQuoteProvider } from '@components/getQuoteProvider';
import '@i18n/i18n';
import { initialiseQuoteFormWithStore } from '@utils/quote.ts';

import { App } from './App';
import './styles/index.css';

const root = document.getElementById('freely-widget') as HTMLElement;

(async () => {
  const RootContent = (
    <React.StrictMode>
      <GetQuoteProvider
        mode="all"
        reValidateMode="onChange"
        defaultFormValues={initialiseQuoteFormWithStore()}>
        <App />
        {import.meta.env.MODE !== 'prod' && <Debug />}
      </GetQuoteProvider>
    </React.StrictMode>
  );

  createRoot(root).render(<React.StrictMode>{RootContent}</React.StrictMode>);
})();
