import { useModalStore } from '@store/modal';

export function useTravellerInfoModal() {
  const openModal = useModalStore(state => state.openModal);
  return {
    openDependantInfoModal() {
      return openModal('FreelyModal', {
        titleTextProps: { children: 'What is an Accompanied Child?', variant: 'title-28/600' },
        bodyTextProps: {
          children:
            'An accompanied child means your child or grandchild who is identified on the Certificate of Insurance and travelling with you on the journey, provided they are not in full-time employment, they are financially dependent on you and they are under the age of 21 years.',
          variant: 'callout-16/400',
        },
        actions: [{ children: 'Ok, I got it!', variant: 'fuji' }],
      });
    },
  };
}
