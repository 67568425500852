import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import { validationAU, validationUS } from 'freely-shared-i18n-source';
import { logToSentry } from 'freely-shared-utils';

import globalAU from './locales/en-AU/global/global.json';
import whenAU from './locales/en-AU/when/when.json';
import whereAU from './locales/en-AU/where/where.json';
import whoAU from './locales/en-AU/who/who.json';
import globalUS from './locales/en-US/global/global.json';

export const i18n = i18next.createInstance();

i18n.use(initReactI18next).init({
  // lng: 'en', // if you're using a language detector, do not define the lng option
  debug: process.env.NODE_ENV === 'development',
  resources: {
    'en-AU': {
      translation: {
        global: globalAU,
        where: whereAU,
        who: whoAU,
        when: whenAU,
        validation: validationAU,
      },
    },
    'en-US': {
      translation: { global: globalUS, validation: validationUS },
    },
  },
  // if you see an error like: "Argument of type 'DefaultTFuncReturn' is not assignable to parameter of type xyz"
  // set returnNull to false (and also in the i18next.d.ts options)
  // returnNull: false,
  lng: 'en-AU', // Set your default language
  fallbackLng: 'en-AU', // Specify the fallback language
  missingKeyHandler(lngs, _, key) {
    logToSentry(new Error(`i18next:: ${lngs} Missing translation for ${key}`));
  },
  saveMissing: true,
});
