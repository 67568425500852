import Add from './add.svg';
import Alert from './alert.svg';
import ArrowLeft from './arrow-left.svg';
import ArrowRight from './arrow-right.svg';
import Base from './base.svg';
import CalendarOutline from './calendar-outline.svg';
import Calendar from './calendar.svg';
import CheckGreen from './check-green.svg';
import ChevronRight from './chevron-right.svg';
import CloseCircleLight from './close-circle-light.svg';
import CloseCircle from './close-circle.svg';
import FaceFrown from './face-frown.svg';
import InfoCircleBlue from './info-circle-blue.svg';
import InfoCircle from './info-circle.svg';
import LocationPinOutline from './location-pin-outline.svg';
import LocationPin from './location.svg';
import ProfileOutline from './profile-outline.svg';
import Profile from './profile.svg';
import Redeem from './redeem.svg';
import Refresh from './refresh.svg';
import { StepperSVGIcon } from './stepperSVG';
import Tools from './tools.svg';

export const Svg = {
  Add,
  Alert,
  ArrowLeft,
  ArrowRight,
  Base,
  Calendar,
  CalendarOutline,
  CheckGreen,
  ChevronRight,
  CloseCircle,
  CloseCircleLight,
  FaceFrown,
  InfoCircle,
  InfoCircleBlue,
  LocationPinOutline,
  LocationPin,
  ProfileOutline,
  Profile,
  Redeem,
  Refresh,
  StepperSVGIcon,
  Tools,
};
