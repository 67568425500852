import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { twMerge } from 'tailwind-merge';

import { Portal } from '@restart/ui';

export interface PortalWrapperProps {
  children: any;
  portalRef: React.RefObject<HTMLDivElement>;
  isFocused?: boolean;
}
export const PortalWrapper: React.FC<PortalWrapperProps> = ({ children, portalRef, isFocused }) => {
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  return isDesktop ? (
    <Portal container={portalRef}>
      <div className={twMerge(['hidden', isFocused && 'block'])}>{children}</div>
    </Portal>
  ) : (
    <div
      className={twMerge([
        'transition-all duration-500 ease-in',
        isFocused && 'lg:max-h-screen lg:overflow-auto',
      ])}>
      <div className={twMerge(['hidden', isFocused && 'block'])}>{children}</div>
    </div>
  );
};
