import { useCallback, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { useConfigStore } from 'freely-shared-stores';
import { DestinationsForm, FormOnChange, TripDestination } from 'freely-shared-types';

import { useSectionStore } from '@store/section';
import { sendAnalyticsEvent } from '@utils/analytics';

export const useSelectDestination = () => {
  const setSectionIsValidating = useSectionStore(state => state.setSectionIsValidating);
  const { watch } = useFormContext<DestinationsForm>();
  const formTripDestinations = watch('destinations');
  const regionSpecificConfig = useConfigStore(state => state?.regionSpecificConfig);
  const maxDestinations = regionSpecificConfig?.RULES.MAX_DESTINATIONS ?? 10;
  const hasReachedMaxDestinations = formTripDestinations.length >= maxDestinations;
  const [searchValue, setSearchValue] = useState('');
  const destinationRef = useRef<HTMLInputElement>(null);

  function sendToAnalytics(destination: TripDestination, isAdded: boolean) {
    const eventName = isAdded ? 'Destination Added' : 'Destination Removed';
    sendAnalyticsEvent(eventName, { destination: destination.longName });
  }

  const handleToggleDestination = useCallback(
    (onFormChange: FormOnChange) => (destination: TripDestination) => {
      const isDestinationSelected = formTripDestinations.find(
        formDestination => formDestination.countryCode == destination.countryCode,
      );

      setSearchValue('');
      destinationRef.current?.value && (destinationRef.current.value = '');

      if (hasReachedMaxDestinations && !isDestinationSelected) {
        return;
      }

      //if the formTripDestination is empty or new destination does not match, add it
      setSectionIsValidating('destinations', false);
      if (!formTripDestinations || !isDestinationSelected) {
        onFormChange([...formTripDestinations, destination]);
        sendToAnalytics(destination, true);
      } else {
        // remove from user searched destinations
        onFormChange(
          formTripDestinations.filter(dest => dest.countryCode !== destination.countryCode),
        );
        sendToAnalytics(destination, false);
      }
    },
    [formTripDestinations, hasReachedMaxDestinations],
  );

  return {
    handleToggleDestination,
    hasReachedMaxDestinations,
    formTripDestinations,
    setSearchValue,
    searchValue,
    destinationRef,
  };
};
