import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import 'freely-shared-hooks';
import { TripDatesForm } from 'freely-shared-types';

import { PortalWrapper } from '@components/portalWrapper';
import { ScrollWrapper } from '@components/scrollWrapper';
import { SectionContainer } from '@components/sectionContainer';
import { SelectTravelDates } from '@components/selectTravelDates/selectTravelDates.component.tsx';
import { SelectTravelDatesSection } from '@components/selectTravelDates/selectTravelDatesSection.component';
import { DATES_EXPIRED_MESSAGE } from '@constants/common.ts';
import { useHandleCalendar } from '@hooks/useHandleCalendar.ts';
import { useHasDatesExpired } from '@hooks/useHasDatesExpired.ts';

export interface TravelDatesProps {
  travelDatesPortalRef: React.RefObject<HTMLDivElement>;
}

export const TravelDates: React.FC<TravelDatesProps> = ({ travelDatesPortalRef }) => {
  const {
    setIsCalendarInputFocused,
    isCalendarInputFocused,
    activeStartDate,
    handleQuickSelect,
    handleSelectDateDuration,
    selectedQuickSelectIndex,
    validateCalendarTripDuration,
    validateCalendarStartDate,
    isTripDurationError,
    setActiveStartDate,
  } = useHandleCalendar();
  const { checkHasDateExpired } = useHasDatesExpired();
  const { control } = useFormContext<TripDatesForm>();

  return (
    <SectionContainer sectionType="travelDates">
      {({ isFocused, onFocus }) => (
        <Controller
          rules={{
            validate: {
              validateDates: value => {
                if (!value.startDate) {
                  return 'start date is required';
                }

                if (!value.endDate) {
                  return 'end date required';
                }

                if (checkHasDateExpired(value.startDate) || checkHasDateExpired(value.endDate)) {
                  return DATES_EXPIRED_MESSAGE;
                }
              },
            },
          }}
          render={({ field: { onChange, value } }) => {
            return (
              <>
                <SelectTravelDatesSection isFocused={isFocused} onFocus={onFocus} />
                <PortalWrapper portalRef={travelDatesPortalRef} isFocused={isFocused}>
                  <ScrollWrapper>
                    <div
                      className={twMerge([
                        'lg:border z-[100] lg:border-rain lg:relative lg:shadow-lg rounded-2xl bg-white lg:w-[70%] lg:inset-x-[15%] mb-4',
                      ])}>
                      <div
                        className={twMerge(
                          'pt-4 mt-6 border-t lg:px-4 lg:py-4 lg:border-t-0 lg:mt-0',
                        )}>
                        <SelectTravelDates
                          isTripDurationError={isTripDurationError}
                          validateCalendarStartDate={validateCalendarStartDate}
                          selectedQuickSelectIndex={selectedQuickSelectIndex}
                          onSelectDuration={handleSelectDateDuration(onChange)}
                          onQuickSelectChange={handleQuickSelect(onChange, value)}
                          isCalendarInputFocused={isCalendarInputFocused}
                          setIsCalendarInputFocused={setIsCalendarInputFocused}
                          activeStartDate={activeStartDate}
                          validateCalendarTripDuration={validateCalendarTripDuration}
                          setActiveStartDate={setActiveStartDate}
                        />
                      </div>
                    </div>
                  </ScrollWrapper>
                </PortalWrapper>
              </>
            );
          }}
          name="tripDates"
          control={control}
        />
      )}
    </SectionContainer>
  );
};
