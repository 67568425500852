import { Notification } from '@components/notification';
import { useGetTravelDatesErrorTextProps } from '@hooks/useGetTravelDatesErrorTextProps.ts';

export function SelectTravelDatesErrorNotification({
  isTripDurationError,
}: {
  isTripDurationError: boolean;
}) {
  const { shouldIgnoreErrors, headerTextProps, descriptionTextProps, hasErrors } =
    useGetTravelDatesErrorTextProps({ isTripDurationError });
  /**
   * If the errors are empty dates, we don't want to show the error notification
   */
  if (shouldIgnoreErrors) {
    return null;
  }

  if (hasErrors) {
    return (
      <Notification
        headingProps={headerTextProps}
        descriptionProps={descriptionTextProps}
        variant="alert"
        className="mb-4"
      />
    );
  }
  return null;
}
