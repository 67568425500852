import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { useConfigStore } from 'freely-shared-stores';

import { Svg } from '@assets/svg';
import { Text } from '@components/text';
import { i18n } from '@i18n/i18n';

export type TimeoutProps = {
  children?: ReactNode;
};

export const Timeout = () => {
  const customerServiceNumber = useConfigStore(
    state => state.regionSpecificConfig?.CUSTOMER_SERVICE_OVERSEAS_NUMBER,
  );
  const customerServiceNumberLink = customerServiceNumber?.replace(/\s+/g, '');

  return (
    <div
      className={twMerge(
        'bg-nusa-200 flex h-full w-full flex-col items-center justify-center space-y-7 px-4 text-center',
      )}>
      <Svg.FaceFrown />
      <Text className={twMerge('text-fuji-800')} variant="h5-32">
        {i18n.t('global.modal.timeout.title')}
      </Text>
      <div className={twMerge('px-2')}>
        <Text className={twMerge('text-fuji-800')} variant="callout-16/regular" withMarkDown>
          {i18n.t('global.modal.timeout.description', {
            customerServiceNumber,
            customerServiceNumberLink,
          })}
        </Text>
      </div>
    </div>
  );
};
