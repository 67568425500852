import { twMerge } from 'tailwind-merge';

import { colors } from 'freely-shared-design';

import { Svg } from '@assets/svg';
import { Text } from '@components/text';
import { i18n } from '@i18n/i18n';
import { useGlobalStore } from '@store/global';
import { useModalStore } from '@store/modal';
import { sectionKeys, useSectionStore } from '@store/section/section.store.ts';

export const Header = () => {
  const closeModal = useModalStore(state => state.closeModal);
  const isSectionedOpened = useSectionStore(state => {
    return sectionKeys.some(key => state[key].isOpen);
  });
  const closeAllSections = useSectionStore(state => state.closeAllSections);
  const shouldDisplayShadow = useGlobalStore(state => state.shouldDisplayHeaderShadow);
  const onBackClick = () => {
    return isSectionedOpened ? closeAllSections() : closeModal();
  };

  return (
    <div
      className={twMerge(
        'sticky w-full flex flex-row justify-between top-0 lg:py-8 py-4 items-center bg-white z-20 px-4 lg:px-0',
        shouldDisplayShadow ? 'shadow-lg' : '',
      )}>
      <Svg.ArrowLeft
        width={16}
        height={16}
        fill={colors.black}
        onClick={onBackClick}
        className="lg:hidden"
      />
      <div className="flex-auto">
        <Text
          variant={{
            lg: 'h4-40',
            md: 'title-24/semiBold',
            sm: 'title-24/semiBold',
          }}
          className=" text-center">
          {i18n.t('global.container.title')}
        </Text>
      </div>
      <Svg.CloseCircle
        width={22}
        height={22}
        fill={colors.black}
        onClick={closeModal}
        className="cursor-pointer lg:mr-6"
      />
    </div>
  );
};
