import { useFormContext } from 'react-hook-form';

import { getRegionSpecificTripDurationRule, useConfigStore } from 'freely-shared-stores';
import { TripDatesForm } from 'freely-shared-types';
import { getDurationInMonths } from 'freely-shared-utils';

import { useSectionStore } from '@store/section';
import { TRIP_DATES_ERROR_TYPE } from '@types';
import { getErrorTextProps } from '@utils/calendar.ts';

export function useGetTravelDatesErrorTextProps({
  isTripDurationError,
}: {
  isTripDurationError: boolean;
}) {
  const {
    formState: { errors },
  } = useFormContext<TripDatesForm>();
  const isValidatingFromGetQuoteButton = useSectionStore(
    state => state.travelDates.isValidatingOnOpen,
  );
  const tripDatesErrorType = errors?.tripDates?.type as TRIP_DATES_ERROR_TYPE.INVALID_TRIP_DURATION;
  const isEmptyErrorState =
    errors?.tripDates?.type === TRIP_DATES_ERROR_TYPE.VALIDATE_DATES &&
    isValidatingFromGetQuoteButton;
  const hasErrors =
    isEmptyErrorState ||
    !!errors?.tripDates?.startDate ||
    !!errors?.tripDates?.endDate ||
    isTripDurationError ||
    tripDatesErrorType === TRIP_DATES_ERROR_TYPE.INVALID_TRIP_DURATION;
  const maxTripDays = getRegionSpecificTripDurationRule();
  const maxTripDurationInMonths = getDurationInMonths({ days: maxTripDays });

  const startDateOffset = useConfigStore(
    state => state?.regionSpecificConfig?.RULES?.MAX_START_DATE_OFFSET,
  );
  const startDateOffsetInMonths = getDurationInMonths({ days: startDateOffset });
  const startDateErrorType = errors?.tripDates?.startDate?.type as TRIP_DATES_ERROR_TYPE;
  const endDateErrorType = errors?.tripDates?.endDate?.type as TRIP_DATES_ERROR_TYPE;

  const errorTextProps = getErrorTextProps({
    startDateErrorType,
    startDateOffsetInMonths,
    tripDatesErrorType,
    endDateErrorType,
    maxTripDurationInMonths,
    isTripDurationError,
  });

  return { shouldIgnoreErrors: false, hasErrors, ...errorTextProps };
}
