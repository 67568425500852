import kebabCase from 'lodash/kebabCase';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

import { colors } from 'freely-shared-design';
import { TripDestination } from 'freely-shared-types';

import { Svg } from '@assets/svg';
import { Input } from '@components/input';
import { Text } from '@components/text';
import { i18n } from '@i18n/i18n';
import { testProps } from '@utils/testProps';

export interface DestinationsSectionProps extends React.HTMLAttributes<HTMLDivElement> {
  onFocus: () => void;
  isFocused: boolean;
  selectedDestinations: TripDestination[];
  searchValue: string;
  hasReachedMaxDestinations: boolean;
}
export const DestinationsSection = forwardRef<HTMLInputElement, DestinationsSectionProps>(
  (
    {
      isFocused,
      onFocus,
      onChange,
      selectedDestinations,
      searchValue,
      hasReachedMaxDestinations,
    }: DestinationsSectionProps,
    ref,
  ) => {
    const renderIcon = (
      <div className={twMerge('justify-center lg:px-2', isFocused && 'lg:flex')}>
        <Svg.LocationPinOutline height={28} width={28} fill={colors.black} />
      </div>
    );

    const inputValues = !isFocused
      ? selectedDestinations.map(destination => destination.countryName).join(' • ')
      : searchValue;

    const hasDestinationsSelected = selectedDestinations.length > 0;
    const { t } = useTranslation();

    const getPlaceholderText = (
      hasReachedMaxDestinations: boolean,
      hasDestinationsSelected: boolean,
    ) => {
      if (hasReachedMaxDestinations) {
        return t('where.input.placeholder.maxAdded');
      }
      if (hasDestinationsSelected) {
        return t('where.input.placeholder.destinationAdded');
      }
      return t('where.input.placeholder.default');
    };
    const placeholderText =
      getPlaceholderText(hasReachedMaxDestinations, hasDestinationsSelected) ?? '';

    return (
      <div
        onClick={onFocus}
        className={twMerge(
          'flex cursor-pointer lg:cursor-auto items-center lg:items-center',
          isFocused && 'cursor-auto items-start',
          hasReachedMaxDestinations &&
            'relative before:content-[""] before:w-full before:h-full before:absolute before:z-30 before:bg-transparent',
        )}>
        {renderIcon}
        <div className={twMerge('w-full overflow-hidden')}>
          <div className={twMerge('flex items-center flex-row lg:hidden', isFocused && 'hidden')}>
            <div className={twMerge('flex-none')}>
              <Text variant="body-16/medium" className={twMerge('ml-2')}>
                {i18n.t('where.lg.title')}
              </Text>
            </div>
            <div className={twMerge('text-right flex-auto ml-4 flex-wrap')}>
              {hasDestinationsSelected ? (
                selectedDestinations.map((destination, index) => (
                  <Text
                    key={destination.longName}
                    variant="sub-heading-14/regular"
                    className={twMerge('mr-1 inline')}>
                    {index !== 0 && ' • '}
                    {destination.countryName}
                  </Text>
                ))
              ) : (
                <Text
                  variant="sub-heading-14/regular"
                  className={twMerge('text-right w-full text-steal')}>
                  Add destinations
                </Text>
              )}
            </div>
          </div>
          <div className="hidden lg:block">
            <Text
              variant="sub-heading-14/medium"
              className={twMerge(`mt-2 ml-2 lg:block`, isFocused && 'lg:block')}>
              {i18n.t('where.input.title')}
            </Text>
          </div>
          <div className="hidden md:block lg:hidden">
            <Text variant="body-16/medium" className={twMerge(`ml-2 hidden`, isFocused && 'block')}>
              {i18n.t('where.input.title')}
            </Text>
          </div>
          <div className="sm:block md:hidden">
            <Text variant="body-16/medium" className={twMerge(`ml-2 hidden`, isFocused && 'block')}>
              {i18n.t('where.input.title')}
            </Text>
          </div>
          <Input
            {...testProps(`${kebabCase('Search for your destinations')}`)}
            ref={ref}
            value={inputValues}
            isDisabled={hasReachedMaxDestinations}
            placeholder={placeholderText}
            className={twMerge([
              'w-full bg-transparent text-ellipsis text-charcoal hidden lg:block placeholder:text-base text-base p-2 -mt-1',
              isFocused && 'bg-white block focus:placeholder:text-flint',
              'focus:ring-0 focus:border-transparent focus:shadow-none focus:outline-none',
            ])}
            onFocus={onFocus}
            onChange={onChange}
          />
        </div>
      </div>
    );
  },
);

DestinationsSection.displayName = 'DestinationsSection';
