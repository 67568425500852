import { i18n } from '@i18n/i18n';

export type QuickSelectOption = {
  amount: number | null;
  type: 'days' | 'months' | 'years' | 'exact dates' | 'title';
  label: 'day' | 'days' | 'month' | 'months' | 'year' | 'years' | 'Exact Dates' | 'Quick Select';
  index: number;
};
export type ButtonSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

/**
 * Enum to  map ditto's error messages
 */
export enum TRIP_DATES_ERROR_TYPE {
  INVALID_TRIP_DURATION = 'INVALID_TRIP_DURATION',
  DATE_IN_PAST = 'DATE_IN_PAST',
  START_DATE_EXCEEDS_OFFSET = 'START_DATE_EXCEEDS_OFFSET',
  END_DATE_IN_PAST = 'END_DATE_IN_PAST',
  VALIDATE_DATES = 'validateDates',
}

export const tripDatesErrorMessages: (
  months: number,
) => Record<
  Exclude<TRIP_DATES_ERROR_TYPE, TRIP_DATES_ERROR_TYPE.VALIDATE_DATES>,
  { title: string; subtitle: string }
> = months => ({
  [TRIP_DATES_ERROR_TYPE.INVALID_TRIP_DURATION]: {
    title: i18n.t('when.errors.invalidTripDuration.title'),
    subtitle: i18n.t('when.errors.invalidTripDuration.description', { months }),
  },
  [TRIP_DATES_ERROR_TYPE.DATE_IN_PAST]: {
    title: i18n.t('when.errors.invalidTripDuration.title'),
    subtitle: i18n.t('when.errors.invalidTripDuration.description', { months }),
  },
  [TRIP_DATES_ERROR_TYPE.START_DATE_EXCEEDS_OFFSET]: {
    title: i18n.t('when.errors.invalidStartDateOffset.title'),
    subtitle: i18n.t('when.errors.invalidStartDateOffset.description'),
  },
  ['END_DATE_IN_PAST']: {
    title: i18n.t('when.errors.invalidDate.title'),
    subtitle: i18n.t('when.errors.invalidDate.description'),
  },
});

export type SectionType = 'destinations' | 'travelDates' | 'travellers';
