import kebabCase from 'lodash/kebabCase';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PatternFormat } from 'react-number-format';
import { twMerge } from 'tailwind-merge';

import {
  getRegionSelector,
  regionDateUtils,
  useConfigStore,
  useRegionStore,
} from 'freely-shared-stores';
import { TravellersForm } from 'freely-shared-types';
import {
  dateToUTCFormat,
  secondaryAgeValidation,
  shouldRevalidateDependencyFlag,
  utcToDateFormat,
  validateDate,
} from 'freely-shared-utils';

import { DependencyCheckBox } from '@components/editTravellers/dependencyCheckBox.component.tsx';
import { Input } from '@components/input';
import { Stepper } from '@components/stepper';
import { dobFormat } from '@constants/common';
import { i18n } from '@i18n/i18n';
import { sendAnalyticsEvent } from '@utils/analytics.ts';
import { testProps } from '@utils/testProps';
import { createNewSecondaryTraveller } from '@utils/travellers';

import { Text } from '../text';

export const TravellerList = () => {
  const { t } = useTranslation();
  const dobPlaceholder = t('who.input.placeholder');
  const region = useRegionStore(getRegionSelector);
  const regionSpecificConfig = useConfigStore(state => state?.regionSpecificConfig);
  const maxSecondaryTravellers = regionSpecificConfig?.RULES.MAX_SECONDARY_TRAVELLERS ?? 9;
  const secondaryTravellerDependencyMaxAge =
    regionSpecificConfig?.RULES.SECONDARY_TRAVELLER_AGE?.mid?.max ?? 20;
  const secondaryTravellerDependencyMinAge =
    regionSpecificConfig?.RULES.SECONDARY_TRAVELLER_AGE?.mid?.min ?? 18;
  const secondaryTravellerAge = regionSpecificConfig?.RULES.SECONDARY_TRAVELLER_AGE ?? {
    min: 0,
    mid: { min: 18, max: 20 },
    max: 99,
  };
  const isDependantCheckboxEnabled = regionSpecificConfig?.RULES.RESIDENT_CHECKBOX ?? false;

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<TravellersForm>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'secondaryTravellers',
  });

  const travelListLength = watch('secondaryTravellers')?.length ?? 0;

  const addSecondaryTraveller = async () => {
    const formPrimaryTravellerIsResident = watch('primaryTravellerIsResident');
    const newTraveller = createNewSecondaryTraveller({
      isResident: formPrimaryTravellerIsResident,
    });
    sendAnalyticsEvent('Trip Traveller Added', {
      isDependant: newTraveller.isDependant,
    });
    append(newTraveller);
  };

  const handleRemove = () => {
    const secondaryTravellers = watch('secondaryTravellers');
    const travellerToBeRemoved = secondaryTravellers?.[secondaryTravellers.length - 1];
    remove(travelListLength - 1);
    sendAnalyticsEvent('Trip Traveller Removed', {
      isDependant: travellerToBeRemoved?.isDependant,
    });
  };

  const invalidDateError = t('validation.widget.invalidDate');
  const invalidSecondaryAgeError = t('validation.widget.invalidSecondaryAge', {
    min: secondaryTravellerAge.min,
    max: secondaryTravellerAge.max,
  });

  return (
    <div className={twMerge(`border-t pt-4`)}>
      <div className={twMerge('flex  items-center justify-between')}>
        <div>
          <Text variant="sub-heading-14/500">{i18n.t('who.stepper.title')}</Text>
        </div>
        <Stepper
          currentNumber={travelListLength}
          maxNumber={maxSecondaryTravellers}
          onAddPress={addSecondaryTraveller}
          onRemovePress={handleRemove}
        />
      </div>
      <div>
        {fields.map((field, index) => {
          const dobInputId = `secondaryTravellers.${index}.dob` as never;
          const travellerErrors = errors.secondaryTravellers?.[index];
          const dobError = travellerErrors?.['dob'];
          const age = regionDateUtils().getAge(watch(`secondaryTravellers.${index}.dob`) ?? '');
          const currentFieldValue = watch(`secondaryTravellers.${index}.dob` as never);
          const hasEmptyState =
            typeof currentFieldValue === 'string' &&
            (currentFieldValue === '--' || currentFieldValue === '');

          return (
            <div key={field.id}>
              <div className="border rounded-[1rem] py-3 my-4">
                <div className="flex flex-row items-center justify-between pr-3 bor">
                  <div>
                    <div className="flex flex-row items-center px-3">
                      <Text variant="sub-heading-14/500">Traveller {index + 1}</Text>
                      {!dobError && !hasEmptyState ? (
                        <Text variant="sub-heading-14/400" className="ml-1 text-steal">
                          •{' '}
                          <span className={dobError && 'text-cherry-100'}>
                            {age} <span>yo</span>
                          </span>
                        </Text>
                      ) : null}
                    </div>
                    <Text variant="sub-heading-14/400" className="px-3 pt-1 text-steal">
                      {i18n.t('who.input.subTitle')}
                    </Text>
                  </div>
                  <Controller
                    rules={{
                      validate: {
                        validDate: v => validateDate(v ?? '', invalidDateError),
                        validateAge: v =>
                          secondaryAgeValidation(
                            v ?? '',
                            {
                              min: secondaryTravellerAge.min,
                              max: secondaryTravellerAge.max,
                              country: region?.country,
                            },
                            invalidSecondaryAgeError,
                          ),
                      },
                    }}
                    control={control}
                    render={({ field }) => (
                      <div className="w-[9.8rem]">
                        <PatternFormat
                          {...testProps(`${kebabCase('dob input secondary traveller')}`)}
                          customInput={Input}
                          hasError={!!travellerErrors}
                          className="h-[3rem] text-[1.1rem] placeholder:text-[1rem] pl-7 focus:shadow-none"
                          onChange={e => {
                            const formattedDate = dateToUTCFormat(e.target.value, region?.country);
                            field.onChange(dateToUTCFormat(e.target.value, region?.country));
                            if (
                              shouldRevalidateDependencyFlag(
                                formattedDate,
                                isDependantCheckboxEnabled,
                              )
                            ) {
                              const age = regionDateUtils().getAge(formattedDate);
                              const isYoungAdult =
                                age >= secondaryTravellerDependencyMinAge &&
                                age <= secondaryTravellerDependencyMaxAge;
                              const isChild = age < secondaryTravellerDependencyMinAge;
                              const isAdult = age > secondaryTravellerDependencyMaxAge;
                              if (isYoungAdult) {
                                setValue(`secondaryTravellers.${index}.isDependant`, false);
                                return;
                              }

                              if (isAdult) {
                                setValue(`secondaryTravellers.${index}.isDependant`, false);
                                return;
                              }
                              if (isChild) {
                                setValue(`secondaryTravellers.${index}.isDependant`, false);
                                return;
                              }
                            }
                          }}
                          value={utcToDateFormat(field.value, region?.country)}
                          inputMode="numeric"
                          placeholder={dobPlaceholder ?? ''}
                          format={dobFormat}
                          mask="_"
                          autoFocus={index === 0}
                        />
                      </div>
                    )}
                    name={dobInputId}
                  />
                </div>
                <DependencyCheckBox index={index} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
