import ENV_VARS_CONSTANTS from 'freely-shared-env-vars/envVars.json';
import { GraphQLErrorParams } from 'freely-shared-types';

import * as Sentry from '@sentry/react';
import { SeverityLevel } from '@sentry/react';

import { GraphQLError } from '../graphqlError/graphqlError';

const TAG = 'shared/utils/sentry';

const isProd = process.env.MODE?.toLowerCase().startsWith('prod');

export const initSentry = (args: {
  env: 'dev' | 'staging' | 'prod' | 'dev3';
  dsn: string;
  anonymousId?: string;
}) => {
  Sentry.init({
    dsn: args.dsn,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Integrations.Breadcrumbs()],
    environment: args.env,
    tracesSampleRate: 1.0,
    tracePropagationTargets: [/^https:\/\/rest\.gofreely\.xyz/],
    beforeSend(event) {
      return checkSentryEnabled() ? event : null;
    },
  });

  Sentry.setTags({
    anonymousId: args?.anonymousId,
    STAGE: args.env,
    version: ENV_VARS_CONSTANTS.APP_VERSION?.[args?.env],
  });
};

/**
 * Log error to sentry. By default the severity will be set to error.
 * @param error
 * @param severity - default 'error'
 * @returns
 */
export const logToSentry = (error: Error, args?: { severity?: SeverityLevel; tag?: string }) => {
  if (
    !checkSentryEnabled() ||
    (typeof error === 'string' && error === 'The user is not authenticated')
  ) {
    console.log('analytics.ts - logToSentry() - error :', error);
    return;
  }

  Sentry.withScope(function (scope) {
    if (args?.severity) {
      scope.setLevel(args?.severity);
    }

    if (args?.tag) {
      scope.setTag('custom_tag', args.tag);
    }

    if (error instanceof GraphQLError) {
      Sentry.captureException(error, error.getScope(scope));
      return;
    }

    Sentry.captureException(error, scope);
  });
};

export const sendDebugMessageToSentry = (message: string) => {
  Sentry.captureMessage(message, { level: 'debug' });
};

export const logGraphQLError = (params: GraphQLErrorParams) => {
  const { name, schema, input, appSyncError } = params;
  logToSentry(new GraphQLError(name, schema, input, appSyncError, null), {
    tag: TAG,
  });
};

export const checkSentryEnabled = () => {
  if (isProd) {
    return true;
  }

  try {
    // eslint-disable-next-line
    // @ts-ignore
    const isSentryEnabled = localStorage.getItem('IS_SENTRY_ENABLED');

    return isSentryEnabled === 'true';
  } catch (err) {
    // do nothing
  }

  return false;
};

export const toggleSentryEnabled = () => {
  try {
    const isEnabled = checkSentryEnabled();
    // eslint-disable-next-line
    // @ts-ignore
    localStorage.setItem('IS_SENTRY_ENABLED', isEnabled ? 'false' : 'true');
  } catch (err) {
    // do nothing
  }
};
