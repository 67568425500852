import { Duration } from 'luxon';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';
import { twMerge } from 'tailwind-merge';

import { DEFAULT_MAX_CALENDAR_DAYS } from 'freely-shared-constants';
import {
  getRegionSelector,
  maxTripCalendarDaysSelector,
  minDateOffsetDaysSelector,
  protectionRestrictionDaysSelector,
  useConfigStore,
  useRegionStore,
} from 'freely-shared-stores';
import { CalendarData, TripDatesForm, TripDestination } from 'freely-shared-types';
import { getRegionDateTime } from 'freely-shared-utils';

import { Calendar } from '@components/calendar';
import { SectionChildrenWrapper } from '@components/sectionChildrenWrapper';
import { SelectTravelDatesErrorNotification } from '@components/selectTravelDates/selectTravelDatesErrorNotification.component.tsx';
import { Swiper } from '@components/swiper';
import { Text } from '@components/text';
import { quickSelectOptions } from '@constants/common.ts';
import { i18n } from '@i18n/i18n';
import { useSectionStore } from '@store/section';
import { QuickSelectOption } from '@types';

type Props = {
  activeStartDate: Date;
  isCalendarInputFocused?: boolean;
  setIsCalendarInputFocused: (isInputFocused: boolean) => void;
  onSelectDuration: (startDate: string, endDate: string) => void;
  onQuickSelectChange?: (option: QuickSelectOption) => void;
  selectedQuickSelectIndex: number;
  validateCalendarTripDuration: ({ durationInDays }: CalendarData) => boolean;
  validateCalendarStartDate: (startDate: string) => boolean;
  isTripDurationError: boolean;
  setActiveStartDate?: (date: Date) => void;
};

export function SelectTravelDates({
  isCalendarInputFocused,
  activeStartDate,
  onSelectDuration,
  onQuickSelectChange,
  selectedQuickSelectIndex,
  validateCalendarTripDuration,
  validateCalendarStartDate,
  isTripDurationError,
  setActiveStartDate,
}: Props) {
  const isValidatingOnMount = useSectionStore(state => state?.travelDates.isValidatingOnOpen);
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const region = useRegionStore(getRegionSelector);
  const currentDate = getRegionDateTime(region?.country);
  const protectionRestrictionDays = useConfigStore(protectionRestrictionDaysSelector);
  const minDateOffsetDays = useConfigStore(minDateOffsetDaysSelector);
  const maxTripCalendarDays = useConfigStore(maxTripCalendarDaysSelector);

  const { watch, trigger } = useFormContext<TripDatesForm>();
  const tripDates = watch('tripDates');

  const maxTripCalendarDaysDuration = Duration.fromObject({
    days: maxTripCalendarDays ?? DEFAULT_MAX_CALENDAR_DAYS,
  });
  // this is inclusive of the start date
  const twoDayDuration = Duration.fromObject({ days: 2 });

  useEffect(() => {
    const validateFromGetQuote = async () => {
      if (isValidatingOnMount) {
        await trigger('tripDates');
      }
    };
    validateFromGetQuote();
  }, [isValidatingOnMount, trigger]);

  return (
    <SectionChildrenWrapper>
      <SelectTravelDatesErrorNotification isTripDurationError={isTripDurationError} />
      <TimezoneLabel />
      <>
        <div className="flex">
          <Calendar
            setActiveStartDate={setActiveStartDate}
            onValidateStartDate={validateCalendarStartDate}
            isCalendarInputFocused={isCalendarInputFocused}
            editMode
            onSelectDuration={onSelectDuration}
            country={region?.country || 'AU'}
            minDate={currentDate.plus({ days: minDateOffsetDays })}
            startDate={tripDates.startDate}
            endDate={tripDates.endDate}
            showDoubleView={isDesktop}
            activeStartDate={activeStartDate}
            numberOfProtectionRestrictionDays={protectionRestrictionDays}
            onValidate={validateCalendarTripDuration}
            maxDate={currentDate.plus(maxTripCalendarDaysDuration).minus(twoDayDuration)}
          />
        </div>
        <div className={twMerge('flex flex-row items-center')}>
          <Text
            variant="sub-heading-14/regular"
            className={twMerge('basis-28 mt-4 lg:mt-2 hidden lg:block')}>
            Quick Select
          </Text>
          <div className="mt-4 lg:mt-2 overflow-hidden">
            <Swiper
              selectedIndex={selectedQuickSelectIndex}
              onClick={onQuickSelectChange as (data: TripDestination | QuickSelectOption) => void}
              buttonProps={{ size: 'sm', className: 'w-[8rem]', shouldShowLoader: false }}
              swiperConfig={{
                slidesPerView: 'auto',
              }}
              slides={quickSelectOptions}
            />
          </div>
        </div>
      </>
    </SectionChildrenWrapper>
  );
}

function TimezoneLabel() {
  return (
    <Text
      variant="sub-heading-14/medium"
      className={
        'text-center mb-5 bg-nusa-50 max-w-[55%] lg:max-w-[27%] mx-auto rounded-lg uppercase'
      }>
      {i18n.t('when.calendar.title')}
    </Text>
  );
}
