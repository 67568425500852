import { cva } from 'class-variance-authority';
import { ChangeEventHandler, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { FontVariant, ResponsiveFontVariant, Text, TextProps } from '../text';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  isDisabled?: boolean;
  svgIcon?: React.ReactNode;
  hasError?: boolean;
  errorMessage?: string;
  errorMessageTextVariant?: FontVariant | ResponsiveFontVariant;
  onErrorIconClick?: () => void;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  inputWrapperProps?: React.HTMLAttributes<HTMLDivElement>;
  labelProps?: TextProps;
  svgIconRight?: React.ReactNode;
  rightLabelProps?: TextProps;
  svgRightIconClassName?: string;
  outline?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
  testPropID?: string;
  variant?: 'xs' | 'sm' | 'md' | 'lg';
}

export const inputVariant = cva(
  'font-text-regular placeholder:text-steal rounded-xl w-full  border-[1px] border-transparent py-3 focus:outline-0 focus:border-transparent focus:ring-1 focus:border-nusa-200 focus:ring-nusa-200 focus:bg-white focus:placeholder:text-flint focus:outline-none',
  {
    variants: {
      variant: {
        xs: 'placeholder:text-md text-md p-2',
        sm: 'p-3.5 placeholder:text-lg text-lg',
        md: 'p-5 placeholder:text-xl text-xl',
        lg: 'p-6 placeholder:text-2xl text-2xl',
      },
    },
  },
);

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      svgIcon,
      hasError,
      isDisabled,
      errorMessage,
      errorMessageTextVariant = 'sub-heading-14/400',
      containerProps,
      labelProps,
      outline,
      rightLabelProps,
      inputWrapperProps,
      variant = 'sm',
      className,
      ...rest
    },
    ref,
  ) => {
    const commonProps = {
      ...rest,
      className: twMerge(
        inputVariant({ variant }),
        !isDisabled &&
          hasError &&
          ' text-cherry-100 border-cherry-100 border-2 focus:ring-0 focus:border-cherry-100',
        svgIcon && 'px-10',
        'bg-snow/60',
        outline && '!bg-white border-flint/40 ',
        outline &&
          hasError &&
          '!border-cherry-100 bg-transparent focus:border-cherry-100 mb-0 focus:ring-0',
        className,
      ),
      disabled: isDisabled,
    };

    return (
      <div {...containerProps}>
        <div className="mb-1 flex items-center justify-between">
          {labelProps && (
            <Text
              {...labelProps}
              variant={labelProps?.variant ?? 'body-16/regular'}
              className={twMerge('mb-1 inline-block', labelProps?.className)}
            />
          )}
          {rightLabelProps && (
            <Text
              {...rightLabelProps}
              variant={rightLabelProps?.variant ?? 'body-16/regular'}
              className={twMerge('mb-1 inline-block', rightLabelProps?.className)}
            />
          )}
        </div>

        <div
          {...inputWrapperProps}
          className={twMerge('relative block', inputWrapperProps?.className)}>
          {svgIcon && (
            <span className="absolute top-[47%] left-2 flex h-0 items-center  px-2   [&>svg]:h-4 [&>svg]:w-4">
              {svgIcon}
            </span>
          )}
          <input {...commonProps} ref={ref} />
        </div>
        {hasError && (
          <Text variant={errorMessageTextVariant} className={twMerge('text-cherry-100 mt-0.5')}>
            {errorMessage}
          </Text>
        )}
      </div>
    );
  },
);

Input.displayName = 'Input';
