type ValidationUS = {
  maxLimit: string;
  widget: {
    invalidDate: string;
    invalidPrimaryAge: string;
  };
};

export const validationUS: ValidationUS = {
  maxLimit: 'Max Travelers reach. (US)',
  widget: {
    invalidDate: 'Please enter a valid date of birth in USA Format',
    invalidPrimaryAge: 'Please enter an age between {{min}} and {{max}} USA.',
  },
};
