import { z } from 'zod';

import { PHOTO_NOTE_MAX_CHARACTERS, PHOTO_NOTE_MIN_CHARACTERS } from 'freely-shared-constants';
import { CLAIM_EVIDENCE_TITLE } from 'freely-shared-types';

// Constants for validation messages
const emojiErrorMessage = 'Please remove any emojis from this field.';

const evidenceNameRegex = /^(?!^\.)[a-zA-Z0-9_’'.,\s-@#%&()+[\]]+$/;
const emojiRegex = new RegExp(
  /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/,
);
const evidenceNameMessage = 'Please enter a valid evidence name.';
const evidenceNameMinLengthMessage = `Name must be ${PHOTO_NOTE_MIN_CHARACTERS} characters or more.`;
const evidenceNameMaxLengthMessage = `Name must be ${PHOTO_NOTE_MAX_CHARACTERS} characters or less.`;

const requiredFieldMessage = 'This field is required.';
const requiredCityMessage = 'Please enter the city where the event occurred.';
const validAmountMessage = 'Please enter a valid amount.';
const validCityMessage = 'Please enter a valid city.';
const validAccountNameMessage = 'Please enter a valid account name.';
const validNameMessage = (customMessage?: string) => customMessage ?? `Please enter a valid name.`;
const validBSBMessage = 'Please enter a valid BSB number.';
const validBSBNumberLength = 'BSB must be a 6 digit number.';
const validAccountNumberMessage = 'Please enter a valid account number.';
const validAccountNumberLength = 'Account number must be a 6 to 12 digit number.';
const minLengthMessage = (min: number) => `A minimum length of ${min} characters is required.`;
const requiredEmailMessage = 'Please enter a valid email address';

// Reusable validation functions
export const nonEmptyString = z.string().refine(value => value.trim().length > 0, {
  message: requiredFieldMessage,
});

export const noEmojis = z.string().refine(value => !emojiRegex.test(value), {
  message: emojiErrorMessage,
});

export const validAmount = z
  .string()
  .nonempty({ message: validAmountMessage })
  .regex(/^(?!0+$)[+]?[0-9,]+(?:\.[0-9]{1,2})?$/, { message: validAmountMessage });

export const validCity = z
  .string()
  .nonempty({ message: requiredCityMessage })
  .refine(value => value.trim().length >= 2, {
    message: minLengthMessage(2),
  })
  .refine(
    value =>
      /^[^0-9©®\u2000-\u3300\uD83C\uD000-\uDFFF\uD83D\uD000-\uDFFF\uD83E\uD000-\uDFFF]*$/.test(
        value,
      ),
    {
      message: validCityMessage,
    },
  );

export const validName = (customMessage?: string) =>
  z
    .string({ required_error: validNameMessage(customMessage) })
    .min(1, { message: validNameMessage(customMessage) })
    .refine(value => value.trim().length >= 1, {
      message: minLengthMessage(1),
    })
    .refine(value => noEmojis.safeParse(value).success, {
      message: emojiErrorMessage,
    })
    .refine(value => /^(?!.*--)[a-zA-Z_'’\s-]+$/.test(value), {
      message: validNameMessage(customMessage),
    });

export const validAccountName = z
  .string()
  .refine(value => value.trim().length >= 1, {
    message: minLengthMessage(1),
  })
  .refine(value => noEmojis.safeParse(value).success, {
    message: emojiErrorMessage,
  })
  .refine(value => /^(?!.*--)[a-zA-Z_'’\s-]+$/.test(value), {
    message: validAccountNameMessage,
  });

export const validBSB = z
  .string()
  .refine(value => value.trim().length === 6, {
    message: validBSBNumberLength,
  })
  .refine(value => /^\d+$/.test(value), {
    message: validBSBMessage,
  });

export const validAccountNumber = z
  .string()
  .refine(value => value.trim().length >= 6 && value.trim().length <= 12, {
    message: validAccountNumberLength,
  })
  .refine(value => /^\d+$/.test(value), {
    message: validAccountNumberMessage,
  });

// Schema definitions
const fileSchema = z.object({
  filePath: z.string(),
  fileSize: z.number().optional(),
  note: z.string().optional(),
  error: z.string().optional(),
});

const claimEvidenceSchema = z.object({
  title: z.nativeEnum(CLAIM_EVIDENCE_TITLE),
  files: z.array(fileSchema),
});

export const claimDeclartionSchema = z.object({
  fullName: validName(),
});

export const cancellationSurveySchema = z.object({
  otherDescription: nonEmptyString,
});

// These form names need to exactly match our claims API body
export const bankAccountDetailSchema = z.object({
  accountName: validAccountName,
  bsb: validBSB,
  accountNumber: validAccountNumber,
});

export const totalCostSchema = z.object({
  amount: validAmount,
});

export const whatHappenedSchema = z.object({
  description: nonEmptyString,
});

export const whereHappenedSchema = z.object({
  city: validCity,
});

export const whatHappenedGotDamagedSchema = z.object({
  title: nonEmptyString,
  data: nonEmptyString,
});

export const whatHappenedGotDelayedSchema = z.object({
  luggageDetails: nonEmptyString,
  luggageDescription: nonEmptyString,
});

export const whatHappenedGotLostOrStolenSchema = z.object({
  lostOrStolenDetails: nonEmptyString,
  lostOrStolenDescription: nonEmptyString,
});

export const whatHappenedGotMoneySchema = z.object({
  moneyAmount: validAmount,
  damagedOrLostMoneyDescription: nonEmptyString,
});

export const whatHappenedPhoneSchema = z.object({
  phoneDetails: nonEmptyString,
  phoneDescription: nonEmptyString,
});

export const proceedWithoutEvidenceSchema = z.object({
  noEvidenceDescription: nonEmptyString,
});

export const previewEvidenceSchema = z.object({
  note: nonEmptyString
    .refine(value => value.length >= PHOTO_NOTE_MIN_CHARACTERS, {
      message: evidenceNameMinLengthMessage,
    })
    .refine(value => value.length <= PHOTO_NOTE_MAX_CHARACTERS, {
      message: evidenceNameMaxLengthMessage,
    })
    .refine(value => evidenceNameRegex.test(value), {
      message: evidenceNameMessage,
    }),
});
const descriptionSchema = z.object({
  data: nonEmptyString,
  subTitle: nonEmptyString,
  title: nonEmptyString,
});

const validateEvidences = (data: {
  status: string;
  amount: number;
  description: string | { data: string; subTitle: string; title: string };
  noEvidenceDescription: string | null;
  bankAccountDetail: { accountName: string; bsb: string; accountNumber: string };
  claimId: string;
  claimTypes: string[];
  currentScreen: string;
  tripId: string;
  tripTitle: string;
  tripDuration: string;
  tripExcess: number;
  userId: string;
  incidentDate: string;
  incidentPlace: { city: string; country: string };
  evidences:
    | {
        title: CLAIM_EVIDENCE_TITLE;
        files: {
          note?: string | null;
          error?: string | null;
          filePath: string;
          fileSize?: number | null;
        }[];
      }[]
    | null;
}) => {
  if (data?.noEvidenceDescription !== null) {
    return true;
  }

  // If noEvidenceDescription is null, then evidences cannot be fully null or evidence files cannot be null
  if (data?.evidences === null || data?.evidences?.length === 0) {
    return false;
  }
  if (data?.evidences?.some(evidence => evidence?.files?.length === 0)) {
    return false;
  }
  return true;
};

export const evidencesSchema = z.array(claimEvidenceSchema).nullable();

export const mandatoryEvidenceSchema = z
  .object({
    amount: z.number(),
    bankAccountDetail: bankAccountDetailSchema,
    claimId: nonEmptyString,
    claimTypes: z.array(z.string()),
    currentScreen: nonEmptyString,
    tripId: nonEmptyString,
    tripTitle: nonEmptyString,
    tripDuration: nonEmptyString,
    tripExcess: z.number(),
    userId: nonEmptyString,
    status: nonEmptyString,
    incidentDate: nonEmptyString,
    incidentPlace: z.object({
      city: nonEmptyString,
      country: nonEmptyString,
    }),
    description: z.union([nonEmptyString, descriptionSchema]),
    noEvidenceDescription: z.string().nonempty().nullable(),
    evidences: evidencesSchema.nullable(),
  })
  .passthrough()
  .refine(data => validateEvidences(data), {
    message: 'evidences must be valid',
  });

export const validEmail = z
  .string({ required_error: requiredEmailMessage })
  .email({ message: requiredEmailMessage });

export const abandonedCartSchema = (
  firstNameValidationMessage: string,
  lastNameValidationMessage: string,
) =>
  z
    .object({
      firstName: validName(firstNameValidationMessage),
      lastName: validName(lastNameValidationMessage),
      email: validEmail,
    })
    .required();

export const emailAssessmentSchema = z
  .object({
    name: validName('Required: Please enter your first name and last name'),
    email: z
      .string({ required_error: 'Required: Please enter a valid email address' })
      .email(requiredEmailMessage),
  })
  .required();
