import { SecondaryTraveller } from 'freely-shared-types';
import { createSortKeyByUUID, validateDate } from 'freely-shared-utils';

export const createNewSecondaryTraveller = ({
  sortKey = createSortKeyByUUID(),
  firstName = '',
  lastName = '',
  dob = '',
  isSelected = true,
  isResident = false,
  isDependant = false,
}): SecondaryTraveller => ({
  sortKey,
  firstName,
  lastName,
  dob,
  isSelected,
  isResident,
  isDependant,
});

export function getNumberOfValidTravellers(
  primaryTravellerDOB: string | undefined,
  travellers?: SecondaryTraveller[],
) {
  const primaryTravellerCount = !validateDate(primaryTravellerDOB) ? 1 : 0;
  const numberOfSecondaryTravellers =
    travellers?.filter(it => {
      if (!it?.dob) {
        return false;
      }
      return !validateDate(it.dob);
    }).length ?? 0;

  return primaryTravellerCount + numberOfSecondaryTravellers;
}
